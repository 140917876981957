import React from 'react';
import {IProgressBarProps} from "./progressBar.int";
import {BarContainer, BarProgress, ProgressBarContainer, ProgressText} from "./progressBar.styles";

const ProgressBar = (props: IProgressBarProps) => (
	<ProgressBarContainer>
		<BarContainer>
			<BarProgress width={props.progress}/>
		</BarContainer>
		<ProgressText>{props.progress}%</ProgressText>
	</ProgressBarContainer>
);

export default ProgressBar;

