import React, {useEffect, useRef} from 'react';
import styled, {css, keyframes} from 'styled-components';
import {colors} from '../../../../styles/colors/colors';

interface ModalProps {
	onClose: () => void;
	children: React.ReactNode;
	show: boolean;
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ModalOverlay = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  animation: ${(props) => props.show && css`${fadeIn}`} 0.3s ease-in-out;
  z-index: 100;
`;

export const OverlayBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.gradient.purple};
  opacity: 0.8;
`;

export const ModalContainer = styled.div`
  position: relative;
  animation: ${fadeIn} 0.3s ease-in-out;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const CloseButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
`;

const Modal = ({onClose, children, show}: ModalProps) => {
	const modalRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				onClose();
			}
		};

		document.addEventListener('mousedown', handleOutsideClick);

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [onClose]);

	const handleClose = () => {
		onClose();
	};

	return (
		<ModalOverlay show={show}>
			<OverlayBackground/>

			<ModalContainer ref={modalRef}>
				<CloseButtonContainer>
					<CloseButton onClick={handleClose}>Close</CloseButton>
				</CloseButtonContainer>
				{children}
			</ModalContainer>
		</ModalOverlay>
	);
};

export default Modal;
