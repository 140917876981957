import styled from "styled-components";
import {media} from "../../styles/media/media";

export const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 820px;
  width: 100%;
  padding: 0 40px;

  ${media.max.mobile} {
    padding: 0 24px;
  }
`;