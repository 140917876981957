import ClockIcon from "../assets/svg/clock";
import {ChapterCard_Component, Content, Image, Summary, TimeContainer, TimeText, Title} from "./chapterCard.styles";
import {IChapterCardProps} from "./chapterCard.int";
import CheckIcon from "../assets/svg/check";
import React from "react";
import styled, {css} from "styled-components";

const ChapterCard = (props: IChapterCardProps) => {

	const onClickHandler = () => {
		props.onClick();
		const currentPath = window.location.pathname;
		window.location.href = `${currentPath}/capitulos/${props.urlName}`;
	}

	return (
		<ChapterCard_Component
			highlight={props.highlight}
			highlightMobile={props.highlightMobile}
			onClick={onClickHandler}>

			<CheckImageContainer>
				<CheckContainer checked={props.isCompleted}>
					<CheckIcon checked={props.isCompleted}/>
				</CheckContainer>
				<Image src={props.imageSrc} alt={'chapter_image_icon'}/>
			</CheckImageContainer>

			<Content>
				<Title>{props.title}</Title>
				<Summary>{props.summary}</Summary>
				<TimeContainer>
					<ClockIcon/>
					<TimeText>{props.time} min</TimeText>
				</TimeContainer>
			</Content>
		</ChapterCard_Component>
	)
}

export default ChapterCard;

export const CheckImageContainer = styled.div`
  position: relative;
`;

export const CheckContainer = styled.div<{ checked: boolean }>`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;

  ${p => !p.checked && css`
    display: none;
  `}
`;


