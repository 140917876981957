import {getMaxWidthMedia, getMinWidthMedia} from "../functions/functions";

export const BP_MIN_XLG = 1281;
export const BP_MAX_LG = BP_MIN_XLG - 1;
export const BP_MIN_LG = 745;
export const BP_MAX_MD = BP_MIN_LG - 1;
export const BP_MIN_MD = 500;
export const BP_MAX_SM = BP_MIN_MD - 1;

const minWidth = {
	tablet: getMinWidthMedia(BP_MIN_MD),
	desktop: getMinWidthMedia(BP_MIN_LG),
	largeDesktop: getMinWidthMedia(BP_MIN_XLG)
};

const maxWidth = {
	mobile: getMaxWidthMedia(BP_MAX_SM),
	tablet: getMaxWidthMedia(BP_MAX_MD),
	desktop: getMaxWidthMedia(BP_MAX_LG)
};

export const media = {
	min: minWidth,
	max: maxWidth
}
