import React, {createContext, useEffect, useState} from 'react';
import {Circle, CircleContainer, Overlay} from "./loader.style";
import {ILoaderContext, LoaderProps} from "./loader.int";
import {apiEventEmitter} from "../../api/eventEmitter";

export const LoaderContext = createContext<ILoaderContext | undefined>(undefined);

export const LoaderProvider = (props: LoaderProps) => {
	const [disableLoader, setDisableLoader] = useState(false);
	const [show, setShow] = useState(false);
	const [shouldDisplay, setShouldDisplay] = useState(show);
	const startLoading = () => setShow(true);
	const stopLoading = () => setShow(false);

	useEffect(() => {
		if (!disableLoader) {
			let timer: NodeJS.Timeout;
			if (show) {
				setShouldDisplay(true);
			} else {
				timer = setTimeout(() => {
					setShouldDisplay(false);
				}, 500);
			}
			return () => {
				if (timer) clearTimeout(timer);
			};
		}
	}, [show]);

	useEffect(() => {
		apiEventEmitter.on('request', startLoading);
		apiEventEmitter.on('response', stopLoading);

		return () => {
			apiEventEmitter.off('request', startLoading);
			apiEventEmitter.off('response', stopLoading);
		};
	}, []);

	useEffect(() => {
		if (shouldDisplay) {
			document.body.style.overflowY = 'hidden'; // prevent scrolling
		} else {
			document.body.style.overflowY = 'auto';   // allow scrolling
		}
		return () => {
			document.body.style.overflowY = 'auto';   // reset on component unmount
		};
	}, [shouldDisplay]);

	const disableLoaderHandler = () => {
		setDisableLoader(true);
	}

	const enableLoaderHandler = () => {
		setDisableLoader(false);
	}

	return (
		<LoaderContext.Provider value={{shouldDisplay, startLoading, stopLoading, disableLoaderHandler, enableLoaderHandler}}>
			<>
				<Overlay show={shouldDisplay}>
					<CircleContainer>
						<Circle delay={0}/>
						<Circle delay={0.2}/>
						<Circle delay={0.4}/>
						<Circle delay={0.6}/>
					</CircleContainer>
				</Overlay>
				{props.children}
			</>
		</LoaderContext.Provider>
	);
};
