import React from 'react';
import {ButtonStyle} from "./button.styles";
import {IButtonProps} from "./button.ints";

const Button = ({appearance = 'primary', size = 'small', ...props}: IButtonProps) => {

	const onClickHandler = () => {
		if (props.onClick) {
			props.onClick();
		}
	};

	return (
		<ButtonStyle
			color={props.color}
			disabled={props.disabled}
			appearance={appearance}
			size={size}
			onClick={onClickHandler}>
			{props.children}
		</ButtonStyle>
	)
}

export default Button;
