import styled from "styled-components";
import {colors} from "../../styles/colors/colors";

export const BreadcrumbContainer = styled.div`
  padding: 10px 40px;
  background-color: ${colors.lightGray};
  display: flex;
  justify-content: left;
  align-items: center;
`;

export const BreadcrumbOption = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: ${colors.text};
  text-decoration: unset;
  
  &:hover, &:focus {
	 text-decoration: underline;
  }
`;

export const BreadcrumbArrow = styled.img`
  height: 12px;
  width: auto;
  margin: 0 16px;
`;
