import React, {useContext} from 'react';
import {GlobalStyles} from "./styles/global/global";
import {BrowserRouter, Navigate, Route, Routes, useLocation} from "react-router-dom";
import {AuthContext, AuthProvider} from "./context/auth/AuthContext";
import ManualPage from "./screens/manual/manualPage";
import AutodiagnosisPage from "./screens/autodiagnosis/autodiagnosisPage";
import ChapterPage from "./screens/chapter/chapterPage";
import RenownCompromisePage from "./screens/renownCompromise/renownCompromisePage";
import FinalExamPage from "./screens/finalExam/finalExamPage";
import CompromiseLetterPage from "./screens/compromiseLetter/compromiseLetterPage";
import ComplianceMapPage from "./screens/complianceMap/complianceMapPage";
import GreetingsPage from "./screens/greetings/greetingsPage";
import TermsAndConditions from "./screens/termsAndConditions/termsAndConditions";
import {AvatarMediaContextProvider} from "./context/globalContainer/avatarMediaContext";
import {IAuthContext} from "./context/auth/AuthContext.int";
import {LoaderContext, LoaderProvider} from "./componentsBackup/loader/loader";
import Header from "./componentsBackup/header/header";
import styled from "styled-components";
import Footer from "./componentsBackup/footer/footer";
import {colors} from "./styles/colors/colors";
import {ILoaderContext} from "./componentsBackup/loader/loader.int";
import MobileFixedOptions from "./componentsBackup/mobileDashboardOptions/mobileDashboardOptions";
import Privacy from "./screens/privacy/privacy";

const App = () => {
	return (
		<BrowserRouter>
			<LoaderProvider>
				<AuthProvider>
					<AfterLoginContainer/>
				</AuthProvider>
			</LoaderProvider>
		</BrowserRouter>
	);
}

export default App;


interface IHeaderFooter {
	children?: React.ReactNode;
}

const HeaderFooterContainer = ({children}: IHeaderFooter) => {
	const {shouldDisplay} = useContext(LoaderContext) as ILoaderContext;
	const location = useLocation();

	const shouldDisplayMobileOptions = [
		"^/manuales/[^/]+/autodiagnostico$",
		"^/manuales/[^/]+/reconocimiento-y-compromiso$",
		"^/manuales/[^/]+$"
	].some(pattern => new RegExp(pattern).test(location.pathname));


	return (
		<GeneralContainer>
			<Header/>
			<InnerContainer isVisible={!shouldDisplay}>
				{children}
			</InnerContainer>
			<Footer/>
			{shouldDisplayMobileOptions && <MobileFixedOptions/>}
		</GeneralContainer>
	)
}

const GeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 1440px;
  margin: 0 auto;
`;

interface InnerContainerProps {
	isVisible: boolean;
}

const InnerContainer = styled.div<InnerContainerProps>`
  flex-grow: 1;
  background-color: ${colors.white};
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
  transition: ${({isVisible}) => (isVisible ? 'opacity 0.3s ease-in-out' : 'none')};
  display: flex;
  flex-direction: column;
`;

const AfterLoginContainer = () => {
	const {userName} = useContext(AuthContext) as IAuthContext;

	return (
		<AvatarMediaContextProvider>

			{userName && (
				<>
					<GlobalStyles/>

					<HeaderFooterContainer>
						<Routes>
							<Route path="/manuales/:manualUrlName" element={<ManualPage/>}/>
							<Route path="/manuales/:manualUrlName/autodiagnostico" element={<AutodiagnosisPage/>}/>
							<Route path="/manuales/:manualUrlName/capitulos/:chapterUrlName" element={<ChapterPage/>}/>
							<Route path="/manuales/:manualUrlName/carta-compromiso" element={<CompromiseLetterPage/>}/>
							<Route path="/manuales/:manualUrlName/reconocimiento-y-compromiso"
									 element={<RenownCompromisePage/>}/>
							<Route path="/manuales/:manualUrlName/reconocimiento-y-compromiso/mapa-de-cumplimiento"
									 element={<ComplianceMapPage/>}/>
							<Route path="/manuales/:manualUrlName/examen-final" element={<FinalExamPage/>}/>
							<Route path="/agradecimientos" element={<GreetingsPage/>}/>
							<Route path="/aviso-de-privacidad" element={<Privacy/>}/>
							<Route path="/terminos-y-condiciones" element={<TermsAndConditions/>}/>

							<Route path="*" element={<Navigate to="/manuales/manual-didactico-de-anticorrupcion" replace/>}/>
						</Routes>
					</HeaderFooterContainer>
				</>
			)}


		</AvatarMediaContextProvider>
	)
}



