import {useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {BP_MAX_MD, BP_MAX_SM, BP_MIN_MD, media} from "../../../../styles/media/media";
import {colors} from "../../../../styles/colors/colors";
import {IManualIntroductionProps, ManualIntroImageProps} from "../../../../api/manual/dashboard/manualDashboard.int";
import logo_src from "../../../../assets/files/logo/logo_white.png";
import galica_image from "../../../../assets/files/galica.png";
import decanini_image from "../../../../assets/files/decanini.png";

import {
	AppleseedLogo__Image,
	BouncingContainer,
	Decanini,
	Galica,
	InvolvedAuthor,
	ManualIntroduction__Content,
	ManualIntroduction__ContentContainer,
	ManualIntroduction__EndingPhrase,
	ManualIntroduction__Headline,
	ManualIntroduction__ImageButton,
	ManualIntroduction__Section,
	ManualIntroduction__Subheading
} from "./introduction.styles";
import Button from "../../../../components/inputs/button/button";
import MouseIcon from "../../../../componentsBackup/assets/svg/mouseIcon";
import TwoArrowsDownSVG from "../../../../componentsBackup/assets/svg/arrowsDownIcon";
import {AvatarMediaContext} from "../../../../context/globalContainer/avatarMediaContext";
import AudioPlayer from "../../../../componentsBackup/audioPlayer/audioPlayer";

const ManualIntroImage = styled.img`
  width: 100%;
  margin-bottom: 42px;
  border-radius: 20px;

  ${media.max.tablet} {
    margin-bottom: 22px;
  }

  ${media.max.mobile} {
    margin-bottom: 16px;
    border-radius: 10px;
  }
`;

export const Introduction = (props: IManualIntroductionProps) => {
	const contentContainerRef = useRef<HTMLDivElement>(null);
	const context = useContext(AvatarMediaContext);

	if (!context) {
		throw new Error("GlobalContainerContext is not defined!");
	}

	const handleScrollOnClick = () => {
		if (contentContainerRef.current) {
			contentContainerRef.current.scrollIntoView({behavior: "smooth"});
		}
	};

	const handleNext = async () => {
		props.setIsIntroductionCompleted(true);
		props.setShowIntroduction(false);
		window.scroll(0, 0);
		props.completeIntroductionHandler();
		context.resetMedia();
	};

	return (
		<ManualIntroduction__Section>

			<AudioPlayer
				autoPlay={!props.isIntroductionCompleted}
				src={"https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/files/avatar/audios/mensaje-introductorio.mp3"}/>

			<ManualIntroduction__Content>
				<AppleseedLogo__Image src={logo_src} alt={"appleseed_logo"}/>

				<ManualIntroduction__Headline>{props.headline}</ManualIntroduction__Headline>

				<ManualIntroduction__Subheading>{props.subheading}</ManualIntroduction__Subheading>

				<ManualIntroduction__ImageButton onClick={handleScrollOnClick}>
					<MouseIcon/>
					<BouncingContainer>
						<TwoArrowsDownSVG/>
					</BouncingContainer>
				</ManualIntroduction__ImageButton>

				<ManualIntroduction__EndingPhrase>
					Un agradecimiento especial a DLA Piper y Lisa Dewey, Socia Pro Bono de DLA Piper. El apoyo de DLA Piper
					ayudó a hacer posible la creación de esta herramienta. El legado de Lisa Dewey quedará plasmado por
					siempre en Appleseed.
				</ManualIntroduction__EndingPhrase>

				<InvolvedAuthor>Autores involucrados:</InvolvedAuthor>

				<LogosContainer>
					<Galica src={galica_image} alt={"galica_image"}/>
					<Decanini src={decanini_image} alt={"decanini_image"}/>
				</LogosContainer>

				<ManualIntroduction__ContentContainer ref={contentContainerRef}>
					<ManualIntroImageComponent
						desktopUrl={props.image.desktopUrl}
						tabletUrl={props.image.tabletUrl}
						mobileUrl={props.image.mobileUrl}/>
					<Button size={"large"} onClick={handleNext}>
						Comienza aquí
					</Button>
				</ManualIntroduction__ContentContainer>
			</ManualIntroduction__Content>
		</ManualIntroduction__Section>
	);
};

const ManualIntroImageComponent = (props: ManualIntroImageProps) => {
	const [imageSrc, setImageSrc] = useState(props.desktopUrl);

	useEffect(() => {
		const handleResize = () => {
			const width = window.innerWidth;

			if (width <= BP_MAX_SM) {
				setImageSrc(props.mobileUrl);
			} else if (width >= BP_MIN_MD && width <= BP_MAX_MD) {
				setImageSrc(props.tabletUrl);
			} else {
				setImageSrc(props.desktopUrl);
			}
		};

		handleResize();
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return <ManualIntroImage src={imageSrc} alt="Responsive image"/>;
};


const GridContainer = styled.div`
  display: flex;
  min-height: calc(100vh - 184px);
`;

const MenuContainer = styled.div`
  width: 302px;
  background: ${colors.white};
  flex-direction: column;

  ${media.max.tablet} {
    width: 238px;
  }

  ${media.max.mobile} {
    display: none;
  }
`;

const ContentContainer = styled.div`
  background: ${colors.gradient.default};
  flex-direction: column;
  width: calc(100vw - 302px);
  padding: 28px 40px 28px 24px;

  ${media.max.mobile} {
    width: 100%;
    padding: 16px 18px 18px 40px;
  }
`;

const LogosContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 24px;
  margin-top: 18px;
  margin-bottom: 60px;

  ${media.max.mobile} {
    margin-bottom: 40px;
  }
`;
