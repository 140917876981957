import API from '../../api';
import {TAutodiagnosisResponse} from "./autodiagnosis.int";
import {IQuestionResponse} from "../../../screens/autodiagnosis/autodiagnosisPage.ints";
import {TAxiosResponseData} from "../../axios.int";
import {IFinalExam} from "../../../screens/finalExam/finalExamPage.ints";

export const APIManualFinalExam = () => {
	const path = `sl/manuals`;

	const getQuestions = async (manualUrlName: string): Promise<IFinalExam | undefined> => {
		const url = `${path}/${manualUrlName}/final-exam`;

		try {
			const {data: response}: TAutodiagnosisResponse = await API.get(url);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}

			return response.data;
		} catch (err) {
			return Promise.reject(err);
		}
	};

	const submitFinalExam = async (manualUrlName: string, body: IQuestionResponse[]) => {
		const url = `${path}/${manualUrlName}/complete-final-exam`;

		try {
			const {data: response}: TAxiosResponseData = await API.post(url, body);

			if (!response.success) {
				console.error('Failed to submit autodiagnosis. Please try again later.');
			}
		} catch (err) {
			console.error('An error occurred while submitting autodiagnosis:', err);
		}
	};

	return {
		getQuestions,
		submitFinalExam
	};
};
