import API from '../../api';
import {AxiosResponse} from "axios";
import {IAnalyticsChapterModule, IManualChapterDTO, TManualChapterResponse} from "./manualChapter.int";

export const APIManualChapter = () => {
	const path = `sl/manuals`;

	const getManualChapter = async (manualUrlName: string, chapterUrlName: string): Promise<IManualChapterDTO | undefined> => {
		const url = `${path}/${manualUrlName}/chapters/${chapterUrlName}`;

		try {
			const {data: response}: TManualChapterResponse = await API.get(url);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}

			return response.data;
		} catch (err) {
			return Promise.reject(err);
		}
	};

	const completeChapter = async (manualUrlName: string, chapterUrlName: string) => {
		const url = `${path}/${manualUrlName}/chapters/${chapterUrlName}/complete`;

		try {
			const {data: response}: AxiosResponse = await API.get(url);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}
		} catch (err) {
			return Promise.reject(err);
		}
	}

	const completeChapterModule = async (moduleId: number, body: IAnalyticsChapterModule) => {
		const url = `${path}/chapters/modules/${moduleId}`;

		try {
			const {data: response}: AxiosResponse = await API.post(url, body);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}
		} catch (err) {
			return Promise.reject(err);
		}
	}

	return {
		getManualChapter,
		completeChapter,
		completeChapterModule
	};
};
