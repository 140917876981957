import styled, {css} from "styled-components";
import {colors} from "../../styles/colors/colors";
import {ITriangle, TDirection, TJustify} from "./tooltip.int";
import {media} from "../../styles/media/media";


export const TooltipImage = styled.img`
  border-radius: 50%;
  width: 89px;
  height: 89px;

  ${media.max.mobile} {
    margin-bottom: 16px;
    align-self: center;
  }
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentContainer = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;

  ${media.max.mobile} {
    margin-left: 0;
  }
`;

export const TooltipContentTitle = styled.p`
  font-weight: 600;
  font-size: 16.5px;
  line-height: 20px;
  color: ${colors.text};
  margin-bottom: 4px;

  ${media.max.mobile} {
    text-align: center;
  }
`;

export const TooltipContentDescription = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${colors.text};
`;

export const TooltipContainer2 = styled.div<{ stretch?: boolean }>`
  background-color: ${colors.white};
  padding: 20px 24px;
  border-radius: 6px;
  color: ${colors.black};
  position: relative;

  display: flex;
  flex-direction: row;

  width: ${p => p.stretch ? '100%' : '450px'};

  ${media.max.mobile} {
    flex-direction: column;
    justify-content: center;
  }
`;

export const FirstTextSection = styled.div`
  position: relative;
  padding-right: 60px;
  margin-bottom: 20px;

  ${media.max.mobile} {
    padding-right: 0;
    text-align: center;
  }
`;

export const ButtonsSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.a`
  font-weight: 500;
  font-size: 14.167px;
  line-height: 25px;
  color: ${colors.purple};

  &:hover, &:focus {
    text-decoration: underline;
  }
`;

export const CloseButton = styled.img`
  width: 28px;
  height: auto;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 24px;

  ${media.max.mobile} {
    top: 20px;
    right: 24px;
  }
`;

export const Triangle = styled.div<ITriangle>`
  border-style: solid;
  position: absolute;
  border-width: 11.5px;

  ${p => p.direction === 'right' && css`
    border-color: transparent ${colors.white} transparent transparent;
    right: 100%;

    ${p.justify === 'start' && css`
      top: 12px;
    `};

    ${p.justify === 'end' && css`
      bottom: 12px;
    `};
  `}

  ${p => p.direction === 'bottom' && css`
    border-color: transparent transparent ${colors.white} transparent;
    bottom: 100%;

    ${p.justify === 'start' && css`
      left: 12px;
    `};

    ${p.justify === 'end' && css`
      right: 12px;
    `};
  `}

  ${p => p.direction === 'top' && css`
    border-color: ${colors.white} transparent transparent transparent;
    top: 100%;

    ${p.justify === 'start' && css`
      left: 12px;
    `};

    ${p.justify === 'end' && css`
      right: 12px;
    `};
  `}


  ${p => p.direction === 'left' && css`
    border-color: transparent transparent transparent ${colors.white};
    left: 100%;

    ${p.justify === 'start' && css`
      top: 12px;
    `};

    ${p.justify === 'end' && css`
      bottom: 12px;
    `};
  `}
`;

export const TooltipContentContainer = styled.div<{
	direction: TDirection;
	justify: TJustify;
	show: boolean;
	hideOnMobile?: boolean;
}>`
  position: absolute;
  z-index: 200;
  transition: opacity 0.3s;
  display: ${p => (p.show ? 'block' : 'none')};

  ${p => p.direction === 'right' && css`
    margin-left: 26px;
    left: 100%;

    ${p.justify === 'start' && css`
      top: 0;
    `}

    ${p.justify === 'end' && css`
      bottom: 0;
    `}
  `}

  ${p => {
    if (p.direction === 'right') {
      return css`
      `;
    } else if (p.direction === 'bottom') {
      return css`
        top: 100%;
        left: 0;
        margin-top: 26px;
      `;
    } else if (p.direction === 'left') {
      return css`
        top: 0;
        right: 100%;
        margin-right: 26px;
      `;
    } else {
      return css`
        bottom: 100%;
        left: 0;
        margin-bottom: 26px;
      `;
    }
  }}

  ${p => p.hideOnMobile && css`
    ${media.max.mobile} {
      display: none;
      z-index: unset;
    }
  `}
`;

export const TooltipContainer = styled.div<{
	direction: 'top' | 'right' | 'bottom' | 'left';
}>`
  position: relative;
  display: inline-block;

  &:hover ${TooltipContentContainer} {
    visibility: visible;
    opacity: 1;
  }
`;