import React from "react";
import {BreadcrumbArrow, BreadcrumbContainer, BreadcrumbOption} from "./breadcrumb.styles";
import leftArrowImage from '../../assets/files/arrowLeft.png';

export interface IBreadcrumbProps {
	tabs: ITab[];
}

export interface ITab {
	name: string;
	href: string;
}

const Breadcrumb = ({tabs = []}: IBreadcrumbProps) => {
	return (
		<BreadcrumbContainer>
			<BreadcrumbOption href={'/manuales/manual-didactico-de-anticorrupcion'}>Menú Principal</BreadcrumbOption>

			{tabs.map((tab, index) => (  // Change forEach to map
				<React.Fragment key={index}>  {/* Add a unique key */}
					<BreadcrumbArrow src={leftArrowImage} alt={'left_arrow_image'}/>
					<BreadcrumbOption href={tab.href}>{tab.name}</BreadcrumbOption> {/* Change props to tab */}
				</React.Fragment>
			))}
		</BreadcrumbContainer>
	)
}

export default Breadcrumb;
