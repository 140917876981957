import styled, {css, keyframes} from "styled-components";
import {Body_Style, H3_Style} from "../../styles/font/styles/font.styles";
import {colors} from "../../styles/colors/colors";
import backgroundImage from '../../assets/files/backgroundShape.png';
import {media} from "../../styles/media/media";
import {getRem} from "../../styles/functions/functions";

const moveGradient = keyframes`
  0%, 100% {
    background-size: 100% 100%;
    background-position: 0% 0%; // Start at Top-left
  }
  12.5% {
    background-size: 125% 112.5%;
    background-position: 25% 0%; // Between Top-left and Top-center
  }
  25% {
    background-size: 150% 125%;
    background-position: 50% 0%; // Move to Top-center
  }
  37.5% {
    background-size: 175% 137.5%;
    background-position: 75% 25%; // Between Top-center and Right-center
  }
  50% {
    background-size: 200% 150%;
    background-position: 100% 50%; // Move to Right-center
  }
  62.5% {
    background-size: 175% 137.5%;
    background-position: 75% 75%; // Between Right-center and Bottom-center
  }
  75% {
    background-size: 150% 125%;
    background-position: 50% 100%; // Move to Bottom-center
  }
  87.5% {
    background-size: 125% 112.5%;
    background-position: 25% 100%; // Between Bottom-center and Top-left
  }
`;

export const AutodiagnosisContainer = styled.div<{ showQuestions?: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;

  background: linear-gradient(325deg, #B17584 0%, #76698B 44.03%, #354C6C 74.24%, #003C57 100%);
  background-size: 100% 100%;
  animation: ${moveGradient} 5s ease-in-out infinite; // Using ease-in-out for smoother transition

  display: ${p => p.showQuestions ? 'block' : 'none'};
`;

export const AutodiagnosisContainer2 = styled.div<{ show: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  display: ${p => p.show ? 'block' : 'none'};
`;

export const QuestionItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px auto;
  width: 100%;
`;

export const QuestionChapterName = styled(H3_Style)`
  font-weight: 600;
  margin-bottom: 24px;
  color: white;
`;

export const QuestionContainer = styled.div`
  padding: 24px;
  background-color: ${colors.white};
  border: 1px solid ${colors.gray};
  border-radius: 5px;
  width: 100%;
`;

export const QuestionText = styled(Body_Style)<{ bold: boolean | undefined }>`
  margin-bottom: 16px;
  font-size: ${getRem(15)};
  line-height: ${getRem(24)};
  
  ${p => p.bold && css`
    font-weight: 600;
  `}
`;

export const AnswerContainer = styled.div<{
	isSelected: boolean;
	isCorrect: boolean;
	highlightAnswer: boolean;
	allowSelect: boolean;
}>`
  padding: 24px 32px;
  border: 1px solid ${colors.gray};

  ${p => !p.highlightAnswer && css`
    cursor: pointer;

    &:hover, &:focus {
      border-color: ${colors.purple};
      background-color: ${colors.lightPurple};
    }
  `}

  ${p => p.highlightAnswer && p.allowSelect && css`
    cursor: pointer;

    &:hover, &:focus {
      border-color: ${colors.purple};
      background-color: ${colors.lightPurple};
    }
  `}

  ${p => p.isSelected && css`
    border-color: ${colors.purple};
    background-color: ${colors.lightPurple};
  `}

  ${p => (p.highlightAnswer && p.isSelected) && css`
    border-color: ${p.isCorrect ? colors.success : colors.error};
    background-color: ${p.isCorrect ? colors.lightSuccess : colors.lightError};

    &:hover, &:focus {
      border-color: ${p.isCorrect ? colors.success : colors.error};
      background-color: ${p.isCorrect ? colors.lightSuccess : colors.lightError};
    }
  `}
`;

export const AnswerContainerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const AnswerText = styled(Body_Style)`

`;

export const AnswerBullet = styled.div<{
	isSelected: boolean;
	isCorrect: boolean;
	highlightAnswer: boolean;
	isCheckbox: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: ${p => p.isCheckbox ? '5px' : '50%'};
  margin-right: 16px;

  color: ${colors.text};
  border: 1px solid ${colors.text};

  ${p => p.isSelected && css`
    color: ${colors.white};
    background: ${colors.gradient.purple};
    border: 1px solid ${colors.purple};
  `}

  ${p => (p.highlightAnswer && p.isSelected) && css`
    background: ${p.isCorrect ? colors.success : colors.error};
    border: 1px solid ${p.isCorrect ? colors.success : colors.error};
  `}
`;

export const AnswerBulletValue = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  color: inherit;
`;

export const SliderContainer = styled.div<{ show: boolean }>`
  width: 100%;
  position: relative;
  overflow-x: hidden;
  display: ${p => p.show ? 'block' : 'none'};
`;

export const Slide = styled.div<{ offset: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  transform: ${({offset}) => `translateX(${offset * 100}%)`};
  transition: transform 0.2s ease-in-out;

  /* Agregar estas líneas */
  max-height: ${({offset}) => offset === 0 ? 'auto' : '0'};
  overflow: hidden;
`;


export const Button = styled.button`
  font-size: 1.5em;
  padding: 10px 20px;
  margin: 10px;
`;

export interface IButtonType {
	type?: 'primary' | 'secondary';
}

export const AutodiagnosisButton = styled.button`
  height: auto;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  color: white;
  font-size: getRem(16);
  box-shadow: none;
  transform: scale(1);
  width: fit-content;

  position: relative;
  z-index: 1;
  background: ${colors.gradient.purple};

  cursor: pointer;


  padding: 16px 74px;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.gradient.darkPurple};
    opacity: 0;
    transition: opacity 0.4s;
    z-index: -1;
    border-radius: 100px;
    border: none;
  }

  :hover:before {
    opacity: 1;
  }
`;

export const AutodiagnosisSecondaryButton = styled.button`
  height: auto;
  border-radius: 100px;
  border: 1px solid ${colors.purple};
  color: ${colors.purple};
  font-size: getRem(16);
  box-shadow: none;
  transform: scale(1);
  width: fit-content;
  position: relative;
  z-index: 1;
  background: transparent;
  cursor: pointer;
  padding: 16px 74px;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.gradient.darkPurple};
    opacity: 0;
    transition: opacity 0.4s;
    z-index: -1;
    border-radius: 100px;
    border: none;
  }

  :hover:before {
    opacity: 1;
  }

  &:hover {
    color: ${colors.white};
  }

  margin-right: 24px;
`;

export const ButtonsContainer = styled.div<{ show: boolean }>`
  justify-content: center;
  padding-bottom: 140px;
  background: transparent;
  gap: 24px;
  display: ${p => p.show ? 'flex' : 'none'};

  ${media.max.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;
