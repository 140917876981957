import {IManualDashboardProps, ManualDashboardDTO, TManualDashboardResponse} from './manualDashboard.int';

import API from '../../api';
import {AxiosResponse} from "axios";

export const APIManual = () => {
	const path = `sl/manuals`;

	const getDashboard = async (manualName: string): Promise<ManualDashboardDTO | undefined> => {
		const url = `${path}/${manualName}/dashboard`;

		try {
			const {data: response}: TManualDashboardResponse = await API.get(url);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}

			return response.data;
		} catch (err) {
			return Promise.reject(err);
		}
	};

	const completeIntroduction = async (manualName: string) => {
		const url = `${path}/${manualName}/completeIntroduction`;

		try {
			const {data: response}: AxiosResponse = await API.get(url);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}
		} catch (err) {
			return Promise.reject(err);
		}
	}

	const completeTutorial = async (manualName: string) => {
		const url = `${path}/${manualName}/completeTutorial`;

		try {
			const {data: response}: AxiosResponse = await API.get(url);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}
		} catch (err) {
			return Promise.reject(err);
		}
	}

	const increaseChapterScreenLoadCount = async (manualName: string, chapterName: string) => {
		const url = `${path}/${manualName}/chapters/${chapterName}/increaseScreenLoadCount`;

		try {
			const {data: response}: AxiosResponse = await API.get(url);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}
		} catch (err) {
			return Promise.reject(err);
		}
	}

	return {
		getDashboard,
		completeIntroduction,
		completeTutorial,
		increaseChapterScreenLoadCount
	};
};
