import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Breadcrumb, {ITab} from "../../componentsBackup/breadcrumb/breadcrumb";
import AutodiagnosisQuestion from "../../componentsBackup/question/question";
import Button from "../../components/inputs/button/button";
import {
	AutodiagnosisContainer,
	ButtonsContainer,
	Slide,
	SliderContainer,
} from "../../componentsBackup/question/question.styles";
import {QuestionsContainer} from "./finalExamPage.styles";
import {IFinalExamQuestion, IQuestionResponse} from "./finalExamPage.ints";
import FinalExamCompletedPage from "../temporal/finalExamCompleted/finalExamCompleted";
import {APIManualFinalExam} from "../../api/manual/finalExam/autodiagnosis";
import styled from "styled-components";
import {H3_Style} from "../../styles/font/styles/font.styles";
import {AvatarMediaContext} from "../../context/globalContainer/avatarMediaContext";
import {IAvatarMediaContext} from "../../context/globalContainer/globalContainerContext.int";

const FinalExamPage = () => {
	const QUESTIONS_PER_PAGE = 2;

	const {manualUrlName} = useParams<{ manualUrlName?: string }>();
	const [userResponses, setUserResponses] = useState<IQuestionResponse[]>([]);
	const [slideIndex, setSlideIndex] = useState(0);
	const [slides, setSlides] = useState<IFinalExamQuestion[][]>([]);
	const [isSlideAnswered, setIsSlideAnswered] = useState<boolean>(false);
	const [isLastIndexSelected, setIsLastIndexSelected] = useState<boolean>(false);
	const [showButtons, setShowButtons] = useState<boolean>(true);
	const {getQuestions, submitFinalExam} = APIManualFinalExam();
	const [completedSuccessfully, setCompletedSuccessfully] = useState(false);

	const [playAvatar, setPlayAvatar] = useState<boolean>(false);
	const [playAvatarFinished, setPlayAvatarFinished] = useState<boolean>(false);

	const [questions, setQuestions] = useState<IFinalExamQuestion[]>([]);
	const [slidesNumber, setSlidesNumber] = useState(0);
	const context = useContext(AvatarMediaContext) as IAvatarMediaContext;

	const handleAnswerSelected = (questionId: number, answerId: number) => {
		setUserResponses((prev) => {
			const existingQuestionIndex = prev.findIndex((q) => q.id === questionId);
			if (existingQuestionIndex > -1) {
				prev[existingQuestionIndex].answers = [answerId];
				return [...prev];
			} else {
				return [...prev, {id: questionId, answers: [answerId]}];
			}
		});
	};

	const correctAnswersNumber = userResponses.reduce((count, response) => {
		const question = questions.find((q) => q.id === response.id);
		if (question) {
			const correctAnswers = question.answers.filter((answer) => answer.isCorrect);
			const selectedCorrectAnswers = correctAnswers.filter((answer) =>
				response.answers.includes(answer.id)
			);
			count += selectedCorrectAnswers.length;
		}
		return count;
	}, 0);

	const handleNext = () => {
		setSlideIndex(slideIndex + 1);
		window.scrollTo({top: 0});
	};

	const handlePrev = () => {
		setSlideIndex((slideIndex - 1 + slidesNumber) % slidesNumber);
		window.scrollTo({top: 0});
	};

	const handleFinish = () => {
		setSlideIndex(slides.length);
		window.scrollTo({top: 0});
		if (manualUrlName) {
			submitFinalExam(manualUrlName, userResponses);
			setHighlightAnswers(false);
		}
	};

	const fetchQuestions = async () => {
		if (!manualUrlName) return;
		const finalExam = await getQuestions(manualUrlName);

		if (finalExam) {
			setCompletedSuccessfully(finalExam.completedSuccessfully);
			setSlidesNumber(finalExam.questions.length / QUESTIONS_PER_PAGE)
			setQuestions(finalExam.questions);
			setPlayAvatar(finalExam.playExamAvatar);
			setPlayAvatarFinished(finalExam.playExamCompletedAvatar);

			const questionsByChapterId: { [key: number]: IFinalExamQuestion[] } = {};

			for (const question of finalExam.questions) {
				if (question.chapter.id in questionsByChapterId) {
					questionsByChapterId[question.chapter.id].push(question);
				} else {
					questionsByChapterId[question.chapter.id] = [question];
				}
			}

			const newSlides: IFinalExamQuestion[][] = Object.values(
				questionsByChapterId
			);

			setSlides(newSlides);


			if (finalExam.completedSuccessfully) {
				const userResponsesWithCorrectAnswers: IQuestionResponse[] = finalExam.questions.map(
					(question) => {
						const correctAnswers = question.answers.filter((answer) => answer.isCorrect);
						const correctAnswerIds = correctAnswers.map((answer) => answer.id);
						return {id: question.id, answers: correctAnswerIds};
					}
				);
				setUserResponses(userResponsesWithCorrectAnswers);
				setHighlightAnswers(true);
			}
		}
	};

	useEffect(() => {
		fetchQuestions();
		context.hideMediaControllerHandler();
	}, []);

	useEffect(() => {
		const selectedSlide = slides[slideIndex];

		if (selectedSlide) {
			const slideAnswered = selectedSlide.every((question) =>
				userResponses.find((response) => response.id === question.id)
			);
			setIsSlideAnswered(slideAnswered);
			const isLastIndex = slideIndex === slidesNumber - 1;
			setIsLastIndexSelected(isLastIndex);
		}
	}, [slideIndex, userResponses]);

	useEffect(() => {
		const showButtons = slides.length > 0 && slideIndex <= slides.length - 1;
		setShowButtons(showButtons);
	}, [slides, slideIndex]);

	const repeatAutodiagnosisHandler = () => {
		setShowResults(false);
		window.scrollTo({top: 0});
	}

	const repeatExamHandler = () => {
		setSlideIndex(0);
	}

	const [showQuestions, setShowQuestions] = useState(true);
	const [showResults, setShowResults] = useState(false);
	const [highlightAnswers, setHighlightAnswers] = useState(false);

	useEffect(() => {
		setShowQuestions(slideIndex < slides.length);
		setShowResults(slides.length > 0 && slideIndex === slides.length);
	}, [slideIndex, slides]);

	const highlightAnswersHandler = () => {
		setHighlightAnswers(true);
		window.scrollTo({top: 0});
	}

	const tabs: ITab[] = [
		{
			name: "Reconocimiento y compromiso",
			href: `/manuales/${manualUrlName}/reconocimiento-y-compromiso`
		},
		{
			name: "Examen final",
			href: `#`
		},
	]

	return (
		<>
			<Breadcrumb tabs={tabs}/>

			<AutodiagnosisContainer showQuestions={showQuestions}>
				<SliderContainer show={!showResults}>
					{slides.map((slideQuestions, index) => {
						const questionChapterName = `${index + 1}. ${slideQuestions[0].chapter.name}`;
						return (
							<Slide offset={index - slideIndex} key={index}>
								<QuestionsContainer>
									<SlideTitle>{questionChapterName}</SlideTitle>
									{slideQuestions.map((item, questionIndex) => {
										const questionNumber = index * QUESTIONS_PER_PAGE + questionIndex + 1;

										function getObjectById(id: number) {
											const foundObject = userResponses.find((question) => question.id === id);
											return foundObject || null;
										}

										let selectedAnswers: number[] = [];

										if (completedSuccessfully) {
											const question = getObjectById(item.id);
											if (question !== null) {
												selectedAnswers = question.answers;
											}
										}

										return (
											<AutodiagnosisQuestion
												{...item}
												selectedAnswers={selectedAnswers}
												title={""}
												boldText={true}
												text={`${questionNumber}. ${item.text}`}
												key={questionIndex}
												slideTitle={''}
												onAnswerSelected={(answerId) => handleAnswerSelected(item.id, answerId)}
												allowSelect={true}
												highlight={highlightAnswers}
											/>
										);
									})}
								</QuestionsContainer>
							</Slide>
						);
					})}
				</SliderContainer>

				<ButtonsContainer show={showButtons && !showResults}>
					{slideIndex > 0 && (
						<Button
							disabled={slideIndex === 0}
							appearance={"secondary"}
							size={"large"}
							onClick={handlePrev}
						>
							Anterior
						</Button>
					)}

					<Button
						disabled={!isSlideAnswered}
						size={"large"}
						onClick={isLastIndexSelected ? handleFinish : handleNext}
					>
						{isLastIndexSelected ? "Finalizar" : "Siguiente"}
					</Button>
				</ButtonsContainer>
			</AutodiagnosisContainer>

			{showResults && (
				<FinalExamCompletedPage
					playAvatar={playAvatar}
					playAvatarFinished={playAvatarFinished}
					setHighlightAnswers={highlightAnswersHandler}
					showResults={showResults}
					repeatExamHandler={repeatExamHandler}
					correctAnswersNumber={correctAnswersNumber}
					questionsLength={questions.length}
				/>
			)}
		</>
	);
};

export default FinalExamPage;

export const SlideTitle = styled(H3_Style)`
  font-weight: 600;
  margin-top: 24px;
  color: white;
`;
