import styled, {css} from "styled-components";
import {colors} from "../../styles/colors/colors";
import {ITriangle, TDirection, TJustify} from "./tooltipNoContainer.int";


export const TooltipImage = styled.img`
  border-radius: 50%;
  width: 89px;
  height: 89px;
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContentContainer = styled.div`
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`;

export const TooltipContentTitle = styled.p`
  font-weight: 600;
  font-size: 16.5px;
  line-height: 20px;
  color: ${colors.text};
  margin-bottom: 4px;
`;

export const TooltipContentDescription = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 20px;
  color: ${colors.text};
`;

export const TooltipContainer2 = styled.div`
  background-color: ${colors.white};
  padding: 20px 24px;
  border-radius: 6px;
  color: ${colors.black};

  display: flex;
  flex-direction: row;
`;

export const FirstTextSection = styled.div`
  position: relative;
  padding-right: 60px;
  margin-bottom: 20px;
`;

export const ButtonsSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.a`
  font-weight: 500;
  font-size: 14.167px;
  line-height: 25px;
  color: ${colors.purple};

  &:hover, &:focus {
    text-decoration: underline;
  }
`;

export const CloseButton = styled.img`
  width: 28px;
  height: auto;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
`;

export const Triangle = styled.div<ITriangle>`
  border-style: solid;
  position: absolute;
  border-width: 11.5px;

  ${p => p.direction === 'right' && css`
    border-color: transparent ${colors.white} transparent transparent;
    right: 100%;

    ${p.justify === 'start' && css`
      top: 12px;
    `};

    ${p.justify === 'end' && css`
      bottom: 12px;
    `};
  `}

  ${p => p.direction === 'bottom' && css`
    border-color: transparent transparent ${colors.white} transparent;
    bottom: 100%;

    ${p.justify === 'start' && css`
      left: 12px;
    `};

    ${p.justify === 'end' && css`
      right: 12px;
    `};
  `}

  ${p => p.direction === 'top' && css`
    border-color: ${colors.white} transparent transparent transparent;
    top: 100%;

    ${p.justify === 'start' && css`
      left: 28px;
    `};

    ${p.justify === 'center' && css`
      left: 50%;
      transform: translateX(-50%);
    `};

    ${p.justify === 'end' && css`
      right: 28px;
    `};
  `}


  ${p => p.direction === 'left' && css`
    border-color: transparent transparent transparent ${colors.white};
    left: 100%;

    ${p.justify === 'start' && css`
      top: 12px;
    `};

    ${p.justify === 'end' && css`
      bottom: 12px;
    `};
  `}
`;

export const TooltipContentContainer = styled.div<{
	direction: TDirection;
	justify: TJustify;
	show: boolean;
	hideOnMobile?: boolean;
}>`
  position: relative;
  transition: opacity 0.3s;
  display: ${p => (p.show ? 'block' : 'none')};
  padding: 0 16px;
  width: 100%;
  margin-bottom: 26px;
`;

export const TooltipContainer = styled.div<{
	direction: 'top' | 'right' | 'bottom' | 'left';
}>`
  position: relative;
  display: inline-block;
  width: 100%;

  &:hover ${TooltipContentContainer} {
    visibility: visible;
    opacity: 1;
  }
`;