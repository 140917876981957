import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import Breadcrumb, {ITab} from "../../componentsBackup/breadcrumb/breadcrumb";
import AutodiagnosisQuestion from "../../componentsBackup/question/question";
import Button from "../../components/inputs/button/button";
import {
	AutodiagnosisContainer,
	ButtonsContainer,
	Slide,
	SliderContainer,
} from "../../componentsBackup/question/question.styles";
import {QuestionsContainer} from "./autodiagnosisPage.styles";
import {IAutodiagnosisQuestion, IChapterCardMini} from "../../api/manual/autodiagnosis/autodiagnosis.int";
import {APIManualAutodiagnosis} from "../../api/manual/autodiagnosis/autodiagnosis";
import {IQuestionResponse} from "./autodiagnosisPage.ints";
import AutodiagnosisCompletedPage from "../temporal/autodiagnosisCompleted/autodiagnosisCompleted";
import {AvatarMediaContext} from "../../context/globalContainer/avatarMediaContext";
import {IAvatarMediaContext} from "../../context/globalContainer/globalContainerContext.int";

const AutodiagnosisPage = () => {
	const QUESTIONS_PER_PAGE = 2;

	const {manualUrlName} = useParams<{ manualUrlName?: string }>();
	const [questions, setQuestions] = useState<IAutodiagnosisQuestion[]>([]);
	const [userResponses, setUserResponses] = useState<IQuestionResponse[]>([]);
	const [slideIndex, setSlideIndex] = useState(0);
	const slidesNumber = Math.ceil(questions.length / QUESTIONS_PER_PAGE);
	const [slides, setSlides] = useState<IAutodiagnosisQuestion[][]>([]);
	const [isSlideAnswered, setIsSlideAnswered] = useState<boolean>(false);
	const [isLastIndexSelected, setIsLastIndexSelected] = useState<boolean>(false);
	const [showResults, setShowResults] = useState<boolean>(false);

	const [showButtons, setShowButtons] = useState<boolean>(true);

	const {getQuestions, submitAutodiagnosis} = APIManualAutodiagnosis();

	const [chapters, setChapters] = useState<IChapterCardMini[]>([]);
	const context = useContext(AvatarMediaContext) as IAvatarMediaContext;

	const [autodiagnosisCompleted, setAutodiagnosisCompleted] = useState<boolean>(false);

	const [playAvatarAudio, setPlayAvatarAudio] = useState<boolean>(false);

	const handleAnswerSelected = (questionId: number, answerId: number) => {
		setUserResponses((prev) => {
			const existingQuestionIndex = prev.findIndex((q) => q.id === questionId);
			if (existingQuestionIndex > -1) {
				prev[existingQuestionIndex].answers = [answerId];
				return [...prev];
			} else {
				return [...prev, {id: questionId, answers: [answerId]}];
			}
		});
	};

	const handleNext = () => {
		setSlideIndex((slideIndex + 1) % slidesNumber);
		window.scrollTo({top: 0});
	};

	const handlePrev = () => {
		setSlideIndex((slideIndex - 1 + slidesNumber) % slidesNumber);
		window.scrollTo({top: 0});
	};

	const handleFinish = () => {
		sendAutodiagnosis();
		window.scrollTo({top: 0});
	};

	const fetchQuestions = async () => {
		if (!manualUrlName) return;
		const autodiagnosis = await getQuestions(manualUrlName);

		if (autodiagnosis) {
			const questions = autodiagnosis.questions;
			setPlayAvatarAudio(autodiagnosis.playAutodiagnosisAvatar);
			setQuestions(questions);

			const newSlides: IAutodiagnosisQuestion[][] = [];

			for (let i = 0; i < questions.length; i += QUESTIONS_PER_PAGE) {
				newSlides.push(questions.slice(i, i + QUESTIONS_PER_PAGE));
			}

			setSlides(newSlides);
		}
	};

	const sendAutodiagnosis = async () => {
		if (!manualUrlName) return;
		try {
			await submitAutodiagnosis(manualUrlName, userResponses);

			const chapters = extractChapters(questions, userResponses);
			setChapters(chapters);
			setShowResults(true);
			setAutodiagnosisCompleted(true);
		} catch (error) {
			setSlideIndex(0);
		}
	};

	useEffect(() => {
		fetchQuestions();
		context.hideMediaControllerHandler();
	}, []);

	const extractChapters = (questions: IAutodiagnosisQuestion[], userResponses: IQuestionResponse[]): IChapterCardMini[] => {
		return questions.map((question) => {
			const chapter = question.chapter;
			const chapterUserResponses = userResponses.filter((response) => response.id === question.id);
			const chapterAnswers = chapterUserResponses.flatMap((response) => response.answers);
			const allAnswersCorrect = chapterAnswers.every((answerId) =>
				question.answers.find((answer) => answer.id === answerId && answer.isCorrect)
			);
			const anyAnswerIncorrect = chapterAnswers.some((answerId) =>
				!question.answers.find((answer) => answer.id === answerId && answer.isCorrect)
			);
			return {
				...chapter,
				isChecked: allAnswersCorrect,
				isError: anyAnswerIncorrect
			};
		});
	};

	useEffect(() => {
		const selectedSlide = slides[slideIndex];

		if (selectedSlide) {
			const slideAnswered = selectedSlide.every((question) =>
				userResponses.find((response) => response.id === question.id)
			);
			setIsSlideAnswered(slideAnswered);
			const isLastIndex = slideIndex === slidesNumber - 1;
			setIsLastIndexSelected(isLastIndex);
		}
	}, [slideIndex, userResponses]);

	useEffect(() => {
		const showButtons =
			slides.length > 0 && slideIndex <= slides.length - 1;
		setShowButtons(showButtons);
	}, [slides, slideIndex]);

	const repeatAutodiagnosisHandler = () => {
		setShowResults(false);
		window.scrollTo({top: 0});
	}

	const tabs: ITab[] = [
		{
			name: "Autodiagnóstico",
			href: `#`
		}
	]

	const resetSlide = () => {
		setSlideIndex(0);
	}

	return (
		<>
			<Breadcrumb tabs={tabs}/>

			<AutodiagnosisContainer showQuestions={true}>
				<SliderContainer show={!showResults}>
					{slides.map((slideQuestions, index) => {
						return (
							<Slide offset={index - slideIndex} key={index}>
								<QuestionsContainer>
									{slideQuestions.map((item, index) => {
										const questionChapterName = `${index + 1 + slideIndex * 2}. ${
											item.title
										}`;
										return (
											<AutodiagnosisQuestion
												{...item}
												allowSelect={false}
												title={item.title}
												key={index}
												slideTitle={questionChapterName}
												onAnswerSelected={(answerId) =>
													handleAnswerSelected(item.id, answerId)
												}
												highlight={autodiagnosisCompleted}
											/>
										);
									})}
								</QuestionsContainer>
							</Slide>
						);
					})}
				</SliderContainer>

				<ButtonsContainer show={showButtons && !showResults}>
					{slideIndex > 0 && (
						<Button
							disabled={slideIndex === 0}
							appearance={"secondary"}
							size={"large"}
							onClick={handlePrev}
						>
							Anterior
						</Button>
					)}

					<Button
						disabled={!isSlideAnswered}
						size={"large"}
						onClick={isLastIndexSelected ? handleFinish : handleNext}
					>
						{isLastIndexSelected ? "Finalizar" : "Siguiente"}
					</Button>
				</ButtonsContainer>

				<AutodiagnosisCompletedPage
					resetSlide={resetSlide}
					playAvatarAudio={playAvatarAudio}
					show={showResults}
					chapters={chapters}
					repeatAutodiagnosis={repeatAutodiagnosisHandler}
				/>
			</AutodiagnosisContainer>
		</>
	);
};

export default AutodiagnosisPage;
