import React from 'react';
import {ButtonStyle} from "./button.styles";
import {IButtonProps} from "./button.ints";

const ButtonAnchor = ({appearance = 'primary', size = 'small', ...props}: IButtonProps) => {

	return (
		<ButtonStyle
			color={props.color}
			disabled={props.disabled}
			appearance={appearance}
			size={size}
			as={props.as}
			to={props.to}>
			{props.children}
		</ButtonStyle>
	)
}

export default ButtonAnchor;
