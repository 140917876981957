import React, {useRef, useState} from 'react';
import {TDirection, TJustify} from "./tooltipNoContainer.int";
import {TooltipContentContainer, Triangle} from "./tooltipNoContainer.styles";

interface TooltipProps extends React.PropsWithChildren<{}> {
	direction?: TDirection;
	show: boolean;
	content: React.ReactNode;
	justify: TJustify;
	hideOnMobile?: boolean;
}

const getRootSize = () => {
	const rootElement = document.getElementById('root');
	let width = 0;
	let height = 0;

	if (rootElement) {
		width = rootElement.clientWidth;
		height = rootElement.clientHeight;
	}

	return {width, height}
}

const TooltipNoContainer = ({
										 direction: initialDirection = 'bottom',
										 children,
										 ...props
									 }: TooltipProps) => {
	const [direction, setDirection] = useState<TDirection>(initialDirection);
	const tooltipRef = useRef<HTMLDivElement | null>(null);

	return (
		<TooltipContentContainer
			hideOnMobile={props.hideOnMobile}
			justify={props.justify}
			direction={direction}
			show={props.show}
			ref={tooltipRef}>
			<Triangle
				justify={props.justify}
				direction={direction}/>
			{props.content}
		</TooltipContentContainer>
	);
};

export default TooltipNoContainer;
