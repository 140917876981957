import React, {useState} from "react";
import DefaultTemplate from "../templates/default/defaultTemplate";
import Breadcrumb, {ITab} from "../../componentsBackup/breadcrumb/breadcrumb";
import styled, {keyframes} from "styled-components";
import backgroundImage from "../../assets/files/backgroundShape.png";
import {Body_Style, H3_Style, H4_Style} from "../../styles/font/styles/font.styles";
import {colors} from "../../styles/colors/colors";
import CheckIcon from "../../componentsBackup/assets/svg/check";
import Button from "../../components/inputs/button/button";
import {useParams} from "react-router-dom";
import {APIManualRecognition} from "../../api/manual/userManualStatus/userManualStatus";

const CompromiseLetterPage = () => {

	const {manualUrlName} = useParams<{ manualUrlName?: string }>();
	const {completeCompromiseLetter} = APIManualRecognition();

	const tabs: ITab[] = [
		{
			name: "Reconocimiento y compromiso",
			href: `#`
		}
	];

	const pointTexts: string[] = [
		"Cumplir las leyes, reglas, disposiciones, normas, lineamientos, criterios, políticas y sanas prácticas de los estándares altos de conducta.",
		"Actuar con base en el código de ética, basado en los principios de justicia, objetividad y oportunidad de mi organización.",
		"Buscar que mi organización se ciña a los principios de integridad e impulsar que mi organización adopte la carta de principios para las organizaciones de la sociedad civil.",
		"Respetar en todo momento la adopción y aplicación interna de un código de ética y conducta adecuado.",
		"Respectar la aplicación de las mejores prácticas de gobierno y supervisión continua y eficaz basado en el manual anticorrupción dentro de mi organización.",
		"Apoyar a mi organización en el desarrollo de programas de asesoramiento y capacitación constante para reforzar los conocimientos adquiridos.",
		"Gestionar que mi organización adopte medios adecuados para el reporte y atención de denuncias, así como fomentar el desarrollo de programas eficientes para la protección de denunciantes.",
		"Reportar cualquier actuación que vaya en contra del código de ética, las leyes, reglas, disposiciones, normas, lineamientos, criterios, políticas y sanas prácticas de los estándares altos de conducta.",
		"Buscar que mi organización difunda y haga accesible las políticas, el programa de cumplimiento de la organización y la línea de denuncia.",
		"Actuar siempre evitando cualquier conflicto de interés y considerando los más altos estándares de integridad.",
		"Apoyar a que mi organización documente las relaciones con terceros y confirme que éstos actúan con los estándares anticorrupción establecidos en mi organización.",
		"Invitar a otros miembros de mi organización y/o de otras organizaciones a que conozcan el Manual Anticorrupción para Organizaciones de la Sociedad Civil de Appleseed."
	];

	const [checkedPoints, setCheckedPoints] = useState<boolean[]>(pointTexts.map(() => false));

	const togglePoint = (index: number) => {
		const newCheckedPoints = [...checkedPoints];
		newCheckedPoints[index] = !newCheckedPoints[index];
		setCheckedPoints(newCheckedPoints);
	};

	const redirectToFinalExam = async () => {
		if (manualUrlName) {
			await completeCompromiseLetter(manualUrlName);
			window.location.href = `/manuales/${manualUrlName}/reconocimiento-y-compromiso`;
		}
	};

	const allPointsSelected = checkedPoints.every((isChecked) => isChecked);

	return (
		<>
			<Breadcrumb tabs={tabs}/>

			<CompromiseLetterContainer>

				<ContentContainer>
					<Title>Carta compromiso para miembros de las organizaciones de la sociedad civil</Title>
					<SubText>Los principios éticos se basan en valores universales. Me comprometo a cumplir con
						los valores de Buena fe, Honestidad, Imparcialidad, Integridad, Transparencia y Responsabilidad
						plasmados en el Manual de Mejores Prácticas de Transparencia y Anticorrupción para Organizaciones de
						la Sociedad Civil de Appleseed y a respetar los siguientes:</SubText>
					<FundamentalPrinciples>Principios fundamentales</FundamentalPrinciples>

					<PointsContainer>
						{pointTexts.map((point, index) => {
							return (
								<PointContainer key={index} onClick={() => togglePoint(index)}>
									<PointText>
										{`${index + 1}. ${point}`}
									</PointText>
									{checkedPoints[index] ? <CheckIcon checked={true}/> : <UncheckedCircle/>}
								</PointContainer>
							);
						})}
					</PointsContainer>

					<ButtonContainer>
						{/* Renderizar el botón permanente si todos los puntos están seleccionados */}
						{allPointsSelected ? (
							<Button size={'large'} disabled={false} onClick={redirectToFinalExam}>
								Aceptar y continuar
							</Button>
						) : (
							// Renderizar el botón temporal si no todos los puntos están seleccionados
							<ButtonTemporal>
								<ButtonText>Aceptar y continuar</ButtonText>
							</ButtonTemporal>
						)}
					</ButtonContainer>

				</ContentContainer>

			</CompromiseLetterContainer>
		</>
	);
};

export default CompromiseLetterPage;

export const ButtonContainer = styled.div`
  padding-bottom: 40px;
`;

export const ButtonTemporal = styled.div`
  border-radius: 100px;
  background-color: ${colors.text2};
  padding: 19px 16px;
  display: flex;
  justify-content: center;
  max-width: 250px;
  margin: auto;
`;

export const ButtonText = styled.p`
  color: white;
  font-size: 16px;
  font-weight: 600;
`;

export const PointsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 40px;
`;

export const ContentContainer = styled.div`
  text-align: center;
  width: 100%;
  padding: 0 34px;
  max-width: 720px;
  margin: auto;
  padding-top: 40px;
`;

export const Title = styled(H3_Style)`
  max-width: 522px;
  margin: auto auto 16px auto;
  font-weight: 600;
  color: white;
`;

export const SubText = styled(Body_Style)`
  margin-bottom: 24px;
  text-align: justify;
  color: white;
`;

export const FundamentalPrinciples = styled(H4_Style)`
  margin-bottom: 24px;
  color: white;
`;

const moveGradient = keyframes`
  0%, 100% {
    background-size: 100% 100%;
    background-position: 0% 0%; // Start at Top-left
  }
  12.5% {
    background-size: 125% 112.5%;
    background-position: 25% 0%; // Between Top-left and Top-center
  }
  25% {
    background-size: 150% 125%;
    background-position: 50% 0%; // Move to Top-center
  }
  37.5% {
    background-size: 175% 137.5%;
    background-position: 75% 25%; // Between Top-center and Right-center
  }
  50% {
    background-size: 200% 150%;
    background-position: 100% 50%; // Move to Right-center
  }
  62.5% {
    background-size: 175% 137.5%;
    background-position: 75% 75%; // Between Right-center and Bottom-center
  }
  75% {
    background-size: 150% 125%;
    background-position: 50% 100%; // Move to Bottom-center
  }
  87.5% {
    background-size: 125% 112.5%;
    background-position: 25% 100%; // Between Bottom-center and Top-left
  }
`;

export const CompromiseLetterContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  
  background: linear-gradient(325deg, #B17584 0%, #76698B 44.03%, #354C6C 74.24%, #003C57 100%);
  background-size: 100% 100%;
  animation: ${moveGradient} 5s ease-in-out infinite; // Using ease-in-out for smoother transition
  
  min-height: 100vh;
`;

export const PointContainer = styled.div`
  padding: 24px;
  background-color: ${colors.white};
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${colors.gray};
  display: flex;
  align-items: center; /* Vertically center the items */
  gap: 16px;
  cursor: pointer;
`;

export const PointText = styled.p`
  font-size: 16px;
  text-align: justify;
  flex: 1; /* Occupy the remaining space */
`;

export const UncheckedCircle = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid ${colors.gray};
`;

