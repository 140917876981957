import React from 'react';
import API from '../../api';
import {AxiosResponse} from 'axios';

interface IApiAnalytics {
	enableLoaderHandler: () => void;
	disableLoaderHandler: () => void;
}

const APIAnalytics = ({enableLoaderHandler, disableLoaderHandler}: IApiAnalytics) => {
	const path = `sl/analytics/manuals`;

	const downloadComplianceMap = async (manualName: string) => {
		const url = `${path}/${manualName}/download/complianceMap`;

		try {
			disableLoaderHandler();
			const {data: response}: AxiosResponse = await API.get(url);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}
		} catch (err) {
			return Promise.reject(err);
		} finally {
			enableLoaderHandler();
		}
	};

	const downloadDiploma = async (manualName: string) => {
		const url = `${path}/${manualName}/download/diploma`;

		try {
			disableLoaderHandler();
			const {data: response}: AxiosResponse = await API.get(url);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}
		} catch (err) {
			return Promise.reject(err);
		} finally {
			enableLoaderHandler();
		}
	};

	const downloadPrinciplesLetter = async (manualName: string) => {
		const url = `${path}/${manualName}/download/principlesLetter`;

		try {
			disableLoaderHandler();
			const {data: response}: AxiosResponse = await API.get(url);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}
		} catch (err) {
			return Promise.reject(err);
		} finally {
			enableLoaderHandler();
		}
	};

	return {
		downloadComplianceMap,
		downloadDiploma,
		downloadPrinciplesLetter
	};
};

export default APIAnalytics;
