import styled, {css} from "styled-components";
import {IButtonStyleProps} from "./button.ints";
import {getRem} from "../../../styles/functions/functions";
import {colors} from "../../../styles/colors/colors";

export const ButtonStyle = styled.a<IButtonStyleProps>`
  position: relative;
  width: fit-content;
  height: auto;
  border-radius: 100px;
  transform: scale(1);
  cursor: pointer;
  text-decoration: none;

  /* font styles */
  font-weight: 600;
  font-size: ${getRem(16)};
  line-height: 25px;
  color: ${colors.white};

  /* remove button defaults */
  border: none;
  box-shadow: none;

  ${p => p.size === 'small' && css`
    padding: 8px;
    min-width: 200px;
    font-weight: 500;
  `};

  ${p => p.size === 'large' && css`
    padding: 16px;
    min-width: 250px;
  `};

  ${p => (p.appearance === 'primary' && !p.disabled) && css`
    background: ${colors.gradient.purple};

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${colors.gradient.darkPurple};
      opacity: 0;
      transition: opacity 0.4s;
      z-index: -1;
      border: none;
      border-radius: 100px;
    }
  `};

  ${p => (p.appearance === 'primary' && p.color === 'green' && !p.disabled) && css`
    background: ${colors.success};

    &:before {
      background: ${colors.success};
    }
  `};

  ${p => (p.appearance === 'secondary' && !p.disabled) && css`
    color: ${colors.purple};
    border: 1px solid ${colors.purple};
    background-color: ${colors.white};
    transition: background-color 0.4s;

    ${p.size === 'large' && css`
      padding: 15px;
    `};

    ${p.size === 'small' && css`
      padding: 7px;
    `};

    &:hover, &:focus {
      color: ${colors.white};
      border: 1px solid ${colors.darkPurple};
      background-color: ${colors.darkPurple};
    }
  `};

  ${p => p.disabled && css`
    cursor: unset;
    background: ${colors.gray};
    border-color: ${colors.gray};
    color: ${colors.text2};
  `};

  &:hover:before {
    opacity: 1;
  }
`;
