import styled from 'styled-components';
import {colors} from "../../styles/colors/colors";
import {BP_MAX_LG, media} from "../../styles/media/media";

export const FooterComponent = styled.footer`
  background-color: ${colors.lightGray};
  border-top: 1px solid ${colors.gray};
`;

export const FooterLink = styled.a`
  color: ${colors.text};
  font-size: getRem(10);
  text-decoration: none;
  margin-right: 10px;

  :hover {
    text-decoration: underline;
  }
`;

export const FooterComponent_Copyright = styled.span`
  color: ${colors.text};
  font-size: getRem(10);

  ${media.max.mobile} {
    text-align: center;
  }
`;

export const FooterImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
`;

export const DLAPiper_Image = styled.img`
  width: 68px;
  height: auto;

  ${media.max.mobile} {
    display: none;
  }
`;

export const FooterComponent_ContentContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 40px;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  ${media.max.mobile} {
    flex-direction: column;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
`;
