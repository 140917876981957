import React from 'react';
import {Anchor, AnchorContainer, AnchorIconContainer} from "./anchorInput.styles";
import {IAnchorProps} from "./anchorInput.ints";
import AnchorIcon from "../../../componentsBackup/assets/svg/anchorArrow";
import {colors} from "../../../styles/colors/colors";

const AnchorInput = ({appearance = 'link', color = 'purple', ...props}: IAnchorProps) => {
	const colorProp = color === 'purple' ? colors.purple : colors.white;
	const showIcon = appearance === 'link-arrow';
	const target = props.openInNewTab ? '_blank' : '_self';

	const onClickHandler = () => {
		if (props.onClick) {
			props.onClick();
		}
	};

	return (
		<AnchorContainer>
			<Anchor
				target={target}
				href={props.href}
				appearance={appearance}
				color={color}
				onClick={onClickHandler}>
				{props.children}
			</Anchor>
			{showIcon && (
				<AnchorIconContainer>
					<AnchorIcon color={colorProp}/>
				</AnchorIconContainer>
			)}
		</AnchorContainer>
	)
}

export default AnchorInput;
