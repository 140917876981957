import styled from "styled-components";
import {IAnchorStyleProps} from "./anchorInput.ints";
import {colors} from "../../../styles/colors/colors";

export const Anchor = styled.a<IAnchorStyleProps>`
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  color: ${p => p.color === 'purple' ? colors.purple : colors.white};
  cursor: pointer;
  text-decoration: none;

  &:hover, &:focus {
    text-decoration: underline;
  }
`;

export const AnchorContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AnchorIconContainer = styled.div`
  margin-left: 4px;
`;
