import styled, {keyframes} from "styled-components";
import {BP_MAX_LG, media} from "../../../../styles/media/media";
import {colors} from "../../../../styles/colors/colors";
import {H1_Style, H3_Style, Tag_Style} from "../../../../styles/font/styles/font.styles";

export const AppleseedLogo__Image = styled.img`
  width: 170px;
  height: auto;
  margin: 120px 0 60px 0;

  ${media.max.mobile} {
    margin: 145px 0 40px 0;
  }
`;

export const Galica = styled.img`
  width: 62.88px;
  height: 14.66px;
`;

export const Decanini = styled.img`
  width: 67px;
  height: 16.46px;
`;

export const ManualIntroduction__Section = styled.section`
  background: ${colors.gradient.default};
  width: 100%;
`;

export const ManualIntroduction__Content = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  max-width: ${BP_MAX_LG}px;
  min-height: 100vh;
  padding: 0 40px 40px 40px;
  margin: auto;

  ${media.max.tablet} {
    padding: 0 24px 24px 24px;
  }

  ${media.max.mobile} {
    padding: 0 16px 16px 16px;
  }
`;

export const ManualIntroduction__Headline = styled(H1_Style)`
  color: ${colors.white};
  margin-bottom: 16px;

  ${media.min.desktop} {
    max-width: 912px;
  }
`;

export const ManualIntroduction__Subheading = styled(H3_Style)`
  color: ${colors.white};
  margin-bottom: 40px;

  ${media.min.desktop} {
    max-width: 912px;
  }

  ${media.max.tablet} {
    margin-bottom: 60px;
  }

  ${media.max.mobile} {
    margin-bottom: 40px;
  }
`;

export const ManualIntroduction__ImageButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px 0;
  margin-bottom: 52px;

  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
`;

export const ManualIntroduction__EndingPhrase = styled(Tag_Style)`
  color: ${colors.white};
  font-weight: 400;
  max-width: 685px;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
`;

export const InvolvedAuthor = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  color: ${colors.white};
  text-align: center;
`;

export const ManualIntroduction__ContentContainer = styled.div`
  background-color: ${colors.white};
  width: 100%;
  padding: 42px;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.max.tablet} {
    padding: 22px;
  }

  ${media.max.mobile} {
    padding: 16px;
  }
`;

export const Button = styled.button`
  height: auto;
  border-radius: 100px;
  border: none;
  color: white;
  font-size: getRem(16);
  box-shadow: none;
  transform: scale(1);
  width: fit-content;

  position: relative;
  z-index: 1;
  background: ${colors.gradient.purple};

  cursor: pointer;

  padding: 16px 74px;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.gradient.darkPurple};
    opacity: 0;
    transition: opacity 0.4s;
    z-index: -1;
    border-radius: 100px;
    border: none;
  }

  :hover:before {
    opacity: 1;
  }

  margin-top: 93px;

  ${media.max.tablet} {
    margin-top: 42px;
  }

  ${media.max.mobile} {
    margin-top: 32px;
  }
`;

export const CollectiveActionText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${colors.white};
  text-align: center;
  margin-bottom: 40px;
`;

const bounceAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(4px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const BouncingContainer = styled.div`
  animation: ${bounceAnimation} 1.5s infinite;
`;
