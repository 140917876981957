/* LOCAL
export enum ApiConstants {
	DOMAIN = 'http://localhost:8000',
	LOGIN_SCREEN = 'http://164.92.93.185:8090',
	TOKEN_NAME = 'token_appleseed_aut'
}
 */

/* DEV
export enum ApiConstants {
	DOMAIN = 'http://3.135.214.3:5000',
	LOGIN_SCREEN = 'http://164.92.93.185:8090',
	TOKEN_NAME = 'token_appleseed_aut'
}
 */

export enum ApiConstants {
	DOMAIN = 'https://sl.mmpta.appleseedmexico.org',
	LOGIN_SCREEN = 'https://appleseedmexico.org/app_auth',
	TOKEN_NAME = 'token_appleseed_aut'
}
