import React, {SVGProps} from 'react';
import {colors} from "../../../styles/colors/colors";

interface HamburgerIconProps extends SVGProps<SVGSVGElement> {
	color?: string;
}

export const CrossIcon = ({color = colors.text, ...props}: HamburgerIconProps) => (
	<svg {...props} width="16" height="16" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/1600/svg">
		<line x1="1.29289" y1="17.2929" x2="17.2929" y2="1.29289" stroke="#363636" strokeWidth="2"/>
		<line x1="1.70711" y1="1.29289" x2="17.7071" y2="17.2929" stroke="#363636" strokeWidth="2"/>
	</svg>
);
