import styled, {css} from "styled-components";
import arrowDownImage from '../../assets/files/arrowDown.png';
import {colors} from "../../styles/colors/colors";
import {Body} from "../../styles/font/font";
import React from "react";
import {IAccordionContentProps} from "./accordion.int";
import {SecondaryButton} from "../../screens/manual/sections/dashboard/dashboard";
import {media} from "../../styles/media/media";

export const AccordionButton = styled.button<{ isOpen: boolean }>`
  width: 100%;
  background-color: ${colors.lightGray};
  border: none;
  outline: none;
  text-align: left;
  padding: 0 8px;
  font-size: 14px;
  color: ${colors.text};
  line-height: 18px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s linear;
  display: flex;
  align-items: center;

  ${p => p.isOpen && css`
    border-bottom: none;
  `};

  span {
    width: min-content;
  }

  :after {
    content: "";
    width: 12px;
    height: 6px;
    margin-right: 6px;
    display: inline-block;
    background-image: url(${arrowDownImage});
    background-size: 12px 6px;
    background-repeat: no-repeat;
    transition: transform 0.3s ease;
    transform: ${p => p.isOpen ? 'scaleY(-1)' : 'scaleY(1)'};
    margin-left: auto;
  }
`;

export const AccordionContent = styled.div<{ maxHeight: string | null }>`
  max-height: ${p => p.maxHeight ? p.maxHeight : '0'};
  overflow: hidden;
  transition: max-height 0.2s ease-in-out;
  margin-top: ${p => p.maxHeight ? '8px' : '0'};
`;

export const ContentWrapper = styled.div`
  padding: 0 16px;
`;

export const CheckContainer = styled.div`
  width: 24px;
  height: 24px;
  margin-right: 8px;
`;

export const AccordionFixContainer = styled.div<{
	highlight?: boolean;
	highlightMobile?: boolean;
}>`
  position: relative;
  padding: 16px;
  background-color: ${colors.lightGray};

  ${p => p.highlight && css`
    z-index: 200;
    border-radius: 4px;
    pointer-events: none;
  `}

  ${p => !p.highlightMobile && css`
    ${media.max.mobile} {
      z-index: unset;
    }
  `}
`;

export const AccordionContentContainer = styled.div`
  height: fit-content;
`;

export const AccordionContentComponent = (props: IAccordionContentProps) => {
	return (
		<AccordionContentContainer>
			<Body>{props.text}</Body>

			{(props.link && props.linkText) && (
				<SecondaryButton href={props.link}>{props.linkText}</SecondaryButton>
			)}

		</AccordionContentContainer>
	)
}