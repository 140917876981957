// authContext.tsx
import React, {createContext, useEffect, useState} from 'react';
import {IAuthContext, IAuthProviderProps} from "./AuthContext.int";
import {APIUser} from "../../api/user/profile/userProfile";

export const AuthContext = createContext<IAuthContext | undefined>(undefined);

export const AuthProvider = (props: IAuthProviderProps) => {
	const [userName, setUserName] = useState<string | null>(null);
	const {getUserProfile} = APIUser();

	const fetchUserProfile = async () => {
		const userProfile = await getUserProfile();

		if (userProfile) {
			const fullName = `${userProfile.firstName} ${userProfile.lastName}`;
			setUserName(fullName);
		}
	}

	useEffect(() => {
		if (userName === null) {
			fetchUserProfile();
		}
	}, []);

	return (
		<AuthContext.Provider value={{userName, setUserName}}>
			{props.children}
		</AuthContext.Provider>
	);
};
