import React, {useEffect, useState} from 'react';
import styled, {css} from 'styled-components';
import {media} from '../../../styles/media/media';
import {colors} from '../../../styles/colors/colors';
import {getRem} from '../../../styles/functions/functions';
import {Body_Style, H3_Style} from "../../../styles/font/styles/font.styles";
import Button from "../../../components/inputs/button/button";
import {IAnalyticsChapterModule, IUseCaseModule} from "../../../api/manual/chapter/manualChapter.int";
import {APIManualChapter} from "../../../api/manual/chapter/manualChapter";
import {DateTime} from "luxon";

interface IChapterModuleUseCase extends IUseCaseModule {
	id: number;
	setPreviousModule: () => void;
	setNextModule: () => void;
}

const ChapterModuleUseCase = (props: IChapterModuleUseCase) => {
	const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null);
	const [selectedRightAnswer, setSelectedRightAnswer] = useState<boolean | null>(null);
	const {completeChapterModule} = APIManualChapter();
	const [startDate, setStartDate] = useState<string | null>("");

	const handleAnswerSelection = (index: number) => {
		if (selectedAnswer !== index) {
			setSelectedAnswer(prevSelectedAnswer => (prevSelectedAnswer === index ? null : index));

			const hasSelectedRight = props.answers[index].correct;
			if (selectedRightAnswer === null) {
				setSelectedRightAnswer(hasSelectedRight);
			} else if (!selectedRightAnswer && hasSelectedRight) {
				setSelectedRightAnswer(true);
			}
		}
	};

	const getAnswerLabel = (index: number) => {
		const labels = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];
		return labels[index];
	};

	const isCorrectAnswer = selectedAnswer !== null && props.answers[selectedAnswer].correct;

	useEffect(() => {
		const startDate = DateTime.now().setZone('America/Mexico_City').toISO();
		setStartDate(startDate);
	}, []);

	const nextHandler = () => {
		const endDate = DateTime.now().setZone('America/Mexico_City').toISO();

		const body: IAnalyticsChapterModule = {
			startAt: startDate,
			endAt: endDate,
			isCompleted: selectedRightAnswer,
			moduleType: "useCase"
		}

		completeChapterModule(props.id, body).then(r => props.setNextModule());
	}

	return (
		<PaddingContainer>

			<ContentContainer>
				<CaseContentContainer>
					<Headline>{props.header}</Headline>
					<CaseText>{props.content}</CaseText>
					<BackgroundImage image={isCorrectAnswer ? props.desktopImageSuccessUrl : props.desktopImageUrl}/>
					<BackgroundImageMobile image={isCorrectAnswer ? props.mobileImageSuccessUrl : props.mobileImageUrl}/>
					<CaseImageTablet src={isCorrectAnswer ? props.tabletImageSuccessUrl : props.tabletImageUrl}
										  alt={'case_image'}/>
				</CaseContentContainer>

				<AnswersContainer>
					<AnswersHeadline>Selecciona la respuesta adecuada</AnswersHeadline>

					{props.answers.map((answer, index) => (
						<AnswerContainer
							key={index}
							onClick={() => handleAnswerSelection(index)}
							isSelected={selectedAnswer === index}
							isCorrect={answer.correct}
							highlightAnswer={selectedAnswer !== null && selectedAnswer === index}
						>
							<AnswerContainerContainer>
								<AnswerBullet
									isCheckbox={false}
									isSelected={selectedAnswer === index}
									isCorrect={answer.correct}
									highlightAnswer={selectedAnswer !== null && selectedAnswer === index}
								>
									<AnswerBulletValue>{getAnswerLabel(index)}</AnswerBulletValue>
								</AnswerBullet>
								<AnswerText>{answer.text}</AnswerText>
							</AnswerContainerContainer>
						</AnswerContainer>
					))}

					{selectedAnswer !== null && (
						<AnswerResultContainer>
							<AnswerResultText>
								<AnswerSpan isCorrect={isCorrectAnswer}>
									{isCorrectAnswer ? 'CORRECTO: ' : 'INCORRECTO: '}
								</AnswerSpan>
								{isCorrectAnswer ? props.rightAnswerSummary : props.wrongAnswerSummary}
							</AnswerResultText>
						</AnswerResultContainer>
					)}
					<ButtonsContainer>
						<Button
							appearance={"secondary"}
							size={"large"}
							onClick={props.setPreviousModule}>
							Anterior
						</Button>

						<Button
							size={"large"}
							onClick={nextHandler}>
							Siguiente
						</Button>
					</ButtonsContainer>
				</AnswersContainer>
			</ContentContainer>

		</PaddingContainer>
	);
};

export default ChapterModuleUseCase;

interface OptionItemProps {
	active: boolean;
	onClick: () => void;
	children: React.ReactNode;
}

const OptionItem: React.FC<OptionItemProps> = ({active, onClick, children}) => (
	<OptionContainer>
		<OptionArrowContainer>{active && <OptionArrow/>}</OptionArrowContainer>
		<Option active={active} onClick={onClick}>
			{children}
		</Option>
	</OptionContainer>
);

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  ${media.max.mobile} {
    padding: 0 16px;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: ${colors.text};
  margin-bottom: 20px;

  @media screen and (max-width: 1080px) {
    margin-bottom: 16px;
  }
`;

export const PaddingContainer = styled.div`
  padding: 36px 66px 36px 108px;

  @media screen and (max-width: 1080px) {
    padding: 44px 40px;
  }

  ${media.max.mobile} {
    padding: 16px 24px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;

  @media screen and (max-width: 1080px) {
    display: block;
  }

  ${media.max.mobile} {
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: 37.35%;
  padding-right: 46px;

  @media screen and (max-width: 1080px) {
    max-width: 40%;
    padding-right: 22px;
  }

  ${media.max.mobile} {
    display: none;
  }
`;

export const BulletsContainer = styled.div`
  width: 100%;
  max-width: 62.65%;
  border-left: 2px solid ${colors.purple};

  @media screen and (max-width: 1080px) {
    max-width: 60%;
  }

  ${media.max.mobile} {
    max-width: 100%;
    border-left: none;
  }
`;

export const BulletList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px 0;

  @media screen and (max-width: 1080px) {
    ${Description} {
      display: none;
    }
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionArrowContainer = styled.div`
  height: 100%;
  width: 85px;

  ${media.max.mobile} {
    display: none;
  }
`;

export const OptionArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 16px solid ${colors.purple};
`;

export const Option = styled.button<{ active: boolean }>`
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 5px;
  transform: scale(1);
  cursor: pointer;
  z-index: 1;
  padding: 24px;

  /* font styles */
  font-weight: 600;
  font-size: ${getRem(16)};
  line-height: 25px;
  color: ${colors.white};

  /* remove button defaults */
  border: none;
  box-shadow: none;

  background: ${colors.gradient.purple};

  ${p => !p.active && css`
    background: ${colors.white};
    border: 1px solid ${colors.gray};
    color: ${colors.text2};
  `};

  &:hover:before {
    opacity: 1;
  }
`;

export const ChapterHeader = styled.div`
  padding: 8px 40px;
  background: ${colors.gradient.orange};
`;

export const ChapterHeaderText = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.white};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  background: transparent;
  gap: 24px;
  margin-top: 40px;

  @media screen and (max-width: 1080px) {
    justify-content: center;
  }

  ${media.max.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;

export const AnswerContainer = styled.div<{
	isSelected: boolean;
	isCorrect: boolean;
	highlightAnswer: boolean;
}>`
  padding: 24px 32px;
  border: 1px solid ${colors.gray};

  ${p => !p.highlightAnswer && css`
    cursor: pointer;

    &:hover, &:focus {
      border-color: ${colors.purple};
      background-color: ${colors.lightPurple};
    }
  `}

  ${p => p.isSelected && css`
    border-color: ${colors.purple};
    background-color: ${colors.lightPurple};
  `}

  ${p => (p.highlightAnswer && p.isSelected) && css`
    border-color: ${p.isCorrect ? colors.success : colors.error};
    background-color: ${p.isCorrect ? colors.lightSuccess : colors.lightError};

    &:hover, &:focus {
      border-color: ${p.isCorrect ? colors.success : colors.error};
      background-color: ${p.isCorrect ? colors.lightSuccess : colors.lightError};
    }
  `}
`;

export const AnswerContainerContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const AnswerText = styled(Body_Style)`

`;

export const AnswerBullet = styled.div<{
	isSelected: boolean;
	isCorrect: boolean;
	highlightAnswer: boolean;
	isCheckbox: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: ${p => p.isCheckbox ? '5px' : '50%'};
  margin-right: 16px;

  color: ${colors.text};
  border: 1px solid ${colors.text};

  ${p => p.isSelected && css`
    color: ${colors.white};
    background: ${colors.gradient.purple};
    border: 1px solid ${colors.purple};
  `}

  ${p => (p.highlightAnswer && p.isSelected) && css`
    background: ${p.isCorrect ? colors.success : colors.error};
    border: 1px solid ${p.isCorrect ? colors.success : colors.error};
  `}
`;

export const AnswerBulletValue = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 12px;
  color: inherit;
`;

const AnswerResultContainer = styled.div`
  margin-top: 16px;
`;

const AnswerResultText = styled(Body_Style)`
  color: ${colors.text};
  font-size: 14px;
  text-align: justify;
`;

export const AnswerSpan = styled.span<{ isCorrect: boolean }>`
  color: ${p => p.isCorrect ? colors.success : colors.error};
`;

export const CaseInitImage = styled.img`
  width: 100%;
  height: auto;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const CaseImageTablet = styled.img`
  display: none;
  width: 100%;
  height: auto;
  margin-bottom: 32px;
  border-radius: 8px;

  @media screen and (max-width: 1080px) {
    display: block;
  }

  ${media.max.mobile} {
    display: none;
  }
`;

export const BackgroundImage = styled.div<{ image: string }>`
  padding-top: 107%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(p) => p.image && `url(${p.image})`};
  border-radius: 8px;

  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

export const BackgroundImageMobile = styled.div<{ image: string }>`
  display: none;

  ${media.max.mobile} {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-image: ${(p) => p.image && `url(${p.image})`};
    border-radius: 8px;
    padding-top: 67.27%;
    display: block;
    margin-bottom: 24px;
  }
`;

const Headline = styled(H3_Style)`
  font-weight: 600;
  margin-bottom: 18px;
`;

const CaseText = styled(Body_Style)`
  margin-bottom: 26px;
  text-align: justify;
`;

export const CaseContentContainer = styled.div`
  max-width: 37.5%;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-right: 50px;

  @media screen and (max-width: 1080px) {
    display: block;
    max-width: 100%;
  }
`;

export const AnswersContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 62.5%;
  width: 100%;
  margin-left: 50px;

  @media screen and (max-width: 1080px) {
    max-width: 100%;
    padding: 0 48px;
    margin: 0 auto;
  }

  @media screen and (max-width: 1080px) {
    padding: 0;
  }
`;

export const AnswersHeadline = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
`;
