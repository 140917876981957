import React, {createContext, useState} from 'react';
import {IAvatarMediaContext, IAvatarMediaContextProviderProps} from "./globalContainerContext.int";

export const AvatarMediaContext = createContext<IAvatarMediaContext | undefined>(undefined);

export const AvatarMediaContextProvider = (props: IAvatarMediaContextProviderProps) => {
	const [isMediaPlaying, setIsMediaPlaying] = useState<boolean>(false);
	const [isMediaMuted, setIsMediaMuted] = useState<boolean>(false);
	const [showMediaController, setShowMediaController] = useState<boolean>(false);

	const resetMedia = () => {
		setIsMediaPlaying(false);
		setIsMediaMuted(false);
	}

	const showMediaControllerHandler = () => {
		setShowMediaController(true);
	}

	const hideMediaControllerHandler = () => {
		setShowMediaController(false);
	}

	return (
		<AvatarMediaContext.Provider
			value={{
				isMediaPlaying,
				setIsMediaPlaying,
				isMediaMuted,
				setIsMediaMuted,
				resetMedia,
				showMediaController,
				showMediaControllerHandler,
				hideMediaControllerHandler
			}}>
			{props.children}
		</AvatarMediaContext.Provider>
	);
};

