import React, {useContext, useEffect, useRef} from "react";
import styled from "styled-components";
import {AvatarMediaContext} from "../../context/globalContainer/avatarMediaContext";
import {IAvatarMediaContext} from "../../context/globalContainer/globalContainerContext.int";

interface AutoPlayVideoProps {
	videoSrc: string;
	imageSrc: string;
	autoPlay: boolean;
	mute?: boolean;
}

const AnimatedAvatar = ({videoSrc, imageSrc, autoPlay, mute}: AutoPlayVideoProps) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const context = useContext(AvatarMediaContext) as IAvatarMediaContext;
	const {isMediaMuted, isMediaPlaying, setIsMediaPlaying} = context;

	const playAudioHandler = () => {
		videoRef.current?.play()
			.then(() => setIsMediaPlaying(true))
			.catch(() => setIsMediaPlaying(false));
	}

	const audioFinishedHandler = () => {
		setIsMediaPlaying(false);
	}

	useEffect(() => {
		const audio = videoRef.current;
		if (audio) {
			audio.muted = isMediaMuted;
		}
	}, [isMediaMuted]);

	useEffect(() => {
		const audio = videoRef.current;
		if (audio) {
			audio.addEventListener('ended', audioFinishedHandler);
		}
	}, [])

	useEffect(() => {
		if (isMediaPlaying) {
			playAudioHandler();
		}
	}, [isMediaPlaying]);

	useEffect(() => {
		const video = videoRef.current;

		if (autoPlay) {
			setTimeout(() => {
				playAudioHandler();
			}, 1000);
		}

		if (mute && video) {
			video.muted = true;
		}
	}, []);

	useEffect(() => {
		if (autoPlay) {
			setTimeout(() => {
				playAudioHandler();
			}, 1000);
		}
	}, [autoPlay])

	return (
		<Container>
			<Video ref={videoRef} src={videoSrc} isShown={isMediaPlaying} playsInline/>
			<AvatarImage src={imageSrc} isShown={!isMediaPlaying}/>
		</Container>
	)
}

export default AnimatedAvatar;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 100%;
  overflow: hidden;
`;

const Video = styled.video<{ isShown: boolean }>`
  width: 100%;
  height: auto;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;

  opacity: ${props => (props.isShown ? 1 : 0)};
`;

export const AvatarImage = styled.img<{ isShown: boolean }>`
  width: 100%;
  height: auto;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;

  opacity: ${props => (props.isShown ? 1 : 0)};
`;