import styled from "styled-components";
import {colors} from "../../styles/colors/colors";
import {media} from "../../styles/media/media";
import CheckIcon from "../assets/svg/check";
import React from "react";
import {IChapterCardMini} from "../../api/manual/autodiagnosis/autodiagnosis.int";

const ReducedChapterCard = ({isChecked = false, ...props}: IChapterCardMini) => {

	const openChapter = () => {
		const domain = window.location.protocol + "//" + window.location.host;
		const newPath = "/manuales/manual-didactico-de-anticorrupcion/capitulos/" + props.urlName;
		const newURL = domain + newPath;
		window.open(newURL, "_blank");
	};

	return (
		<ChapterCard_Component onClick={openChapter}>
			<CheckImageContainer>
				<CheckContainer>
					<CheckIcon checked={isChecked} error={props.isError}/>
				</CheckContainer>
				<Image src={props.imageUrl} alt={'chapter_image_icon'}/>
			</CheckImageContainer>
			<Title>{props.name}</Title>
		</ChapterCard_Component>
	)
}

export default ReducedChapterCard;

export const CheckImageContainer = styled.div`
  position: relative;
`;

export const CheckContainer = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 0;
  left: 0;
`;

export const ChapterCard_Component = styled.div`
  width: 100%;
  background-color: ${colors.white};
  border-radius: 10px;
  padding: 24px;
  display: flex;
  cursor: pointer;
  position: relative;
  height: 100%;
  box-shadow: 0 4px 10px rgba(4, 4, 4, 0.2);
  align-items: center;

  ${media.max.tablet} {
    padding: 16px;
  }
`;

export const Image = styled.img`
  border-radius: 50%;
  width: 74px;
  height: 74px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-left: 16px;
`;