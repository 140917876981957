import React, {useEffect, useState} from "react";
import {
	AnswerBullet,
	AnswerBulletValue,
	AnswerContainer,
	AnswerContainerContainer,
	AnswerText,
	QuestionChapterName,
	QuestionContainer,
	QuestionItemContainer,
	QuestionText,
} from "./question.styles";
import {IAutodiagnosisQuestion, IAutodiagnosisQuestionProps} from "../../api/manual/autodiagnosis/autodiagnosis.int";

const AutodiagnosisQuestion = ({selectedAnswers = [], highlight = false, ...props}: IAutodiagnosisQuestionProps) => {
	const [questionSelectedAnswers, setQuestionSelectedAnswers] = useState<number[]>(selectedAnswers);
	const [isCheckbox, setIsCheckbox] = useState<boolean>(false);
	const [highlightAnswers, setHighlightAnswers] = useState(highlight);

	const handleAnswerSelection = (index: number) => {
		if ((!highlightAnswers || props.allowSelect) && !questionSelectedAnswers.includes(index)) {
			if (isCheckbox) {
				// In case of multiple options, toggle selection of the clicked answer
				setQuestionSelectedAnswers(prev =>
					prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
				);
			} else {
				// In case of single option, just replace the selection
				setQuestionSelectedAnswers([index]);
			}
			// Call the onAnswerSelected function with the id of the selected answer
			props.onAnswerSelected(props.answers[index].id);
			setHighlightAnswers(false);
		}
	};

	const getAnswerLabel = (index: number) => {
		const labels = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J"];
		return labels[index];
	};

	const isMultipleOptions = (question: IAutodiagnosisQuestion): boolean => {
		const correctAnswers = question.answers.filter(answer => answer.isCorrect);
		return correctAnswers.length > 1;
	}

	useEffect(() => {
		setIsCheckbox(isMultipleOptions({...props}));
	}, [props]);

	useEffect(() => {
		setHighlightAnswers(highlight);
	}, [highlight])

	useEffect(() => {
		console.log("highlightAnswers", highlightAnswers);
	}, [highlightAnswers])

	return (
		<QuestionItemContainer>

			{props.slideTitle && props.slideTitle.length > 0 && (
				<QuestionChapterName>{props.slideTitle}</QuestionChapterName>
			)}

			<QuestionContainer>
				<QuestionText bold={props.boldText}>{props.text}</QuestionText>

				{props.answers.map((item, index) => (
					<AnswerContainer
						key={index}
						onClick={() => handleAnswerSelection(index)}
						isSelected={questionSelectedAnswers.includes(selectedAnswers.length > 0 ? item.id : index)}
						isCorrect={item.isCorrect}
						highlightAnswer={highlightAnswers}
						allowSelect={props.allowSelect}
					>
						<AnswerContainerContainer>
							<AnswerBullet
								isCheckbox={isCheckbox}
								isSelected={questionSelectedAnswers.includes(selectedAnswers.length > 0 ? item.id : index)}
								isCorrect={item.isCorrect}
								highlightAnswer={highlightAnswers}
							>
								<AnswerBulletValue>{getAnswerLabel(index)}</AnswerBulletValue>
							</AnswerBullet>
							<AnswerText>{item.text}</AnswerText>
						</AnswerContainerContainer>
					</AnswerContainer>
				))}
			</QuestionContainer>
		</QuestionItemContainer>
	);
};

export default AutodiagnosisQuestion;
