import styled, {keyframes} from "styled-components";
import {colors} from "../../styles/colors/colors";

export const circleAnimation = keyframes`
  0% {
    transform: scale(1);
    background-color: #52458A;
    width: 20px;
    height: 20px;
  }
  50% {
    background-color: #9C4E39;
    width: 14px;
    height: 14px;
  }
  100% {
    transform: scale(1);
    background-color: #4B7C87;
    width: 16px;
    height: 16px;
  }
`;

export const Overlay = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
  justify-content: center;
  align-items: center;
  z-index: 9999;
  display: flex;  // always flex
  opacity: ${(props) => props.show ? 1 : 0};
  transition: opacity 0.5s ease-in-out;
  pointer-events: ${(props) => props.show ? 'auto' : 'none'}; // ensure it doesn't block interaction
`;

export const CircleContainer = styled.div`
  display: flex;
`;

export const Circle = styled.div<{ delay: number }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 0 5px;
  animation: ${circleAnimation} 2s infinite alternate ease-in-out;
  animation-delay: ${(props) => props.delay}s;
  background-color: ${colors.purple};
`;