import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {media} from '../../../styles/media/media';
import {colors} from '../../../styles/colors/colors';
import {getRem} from '../../../styles/functions/functions';
import {IAnalyticsChapterModule, IKeyPoint, IKeyPointsModule} from "../../../api/manual/chapter/manualChapter.int";
import avatarImg from '../../../assets/files/avatar-use-case.png';
import Button from "../../../components/inputs/button/button";
import {APIManualChapter} from "../../../api/manual/chapter/manualChapter";
import {DateTime} from "luxon";

function sanitizeHTML(html: string): string {
	const allowedTags: string[] = ['p', 'span', 'strong', 'em', 'br'];
	const allowedAttributes: string[] = ['class', 'style'];

	const tempElement = document.createElement('div');
	tempElement.innerHTML = html;

	const disallowedElements = tempElement.querySelectorAll(
		`*:not(${allowedTags.join(',')})`
	);
	disallowedElements.forEach((element) => element.remove());

	const allElements = tempElement.querySelectorAll('*');
	allElements.forEach((element) => {
		const attributes = element.getAttributeNames();
		attributes.forEach((attribute) => {
			if (!allowedAttributes.includes(attribute)) {
				element.removeAttribute(attribute);
			}
		});
	});

	// Return the sanitized HTML
	return tempElement.innerHTML;
}

interface IChapterModuleKeyPoints extends IKeyPointsModule {
	id: number;
	setPreviousModule: () => void;
	setNextModule: () => void;
}

const ChapterModuleKeyPoints = (props: IChapterModuleKeyPoints) => {
	const {manualUrlName, chapterUrlName} = useParams<{
		manualUrlName?: string;
		chapterUrlName?: string;
	}>();

	const {completeChapterModule} = APIManualChapter();
	const [startDate, setStartDate] = useState<string | null>("");

	const handleOptionClick = (index: number) => {
		setSelectedIndex(index);

		if (index !== 0) {
			const updatedSelectedKeyPoints = [...selectedKeyPoints];
			updatedSelectedKeyPoints[index] = true;
			setSelectedKeyPoints(updatedSelectedKeyPoints);

			if (updatedSelectedKeyPoints.every(val => val === true)) {
				setKeyPointsChecked(true);
			}
		}
	};

	const [selectedIndex, setSelectedIndex] = useState<number | undefined>(undefined);
	const [keyPoints, setKeyPoints] = useState<IKeyPoint[]>([]);
	const [keyPointsChecked, setKeyPointsChecked] = useState(false);
	const [selectedKeyPoints, setSelectedKeyPoints] = useState<boolean[]>([]);

	useEffect(() => {
		if (props.keyPoints) {
			setKeyPoints(props.keyPoints);
			setSelectedKeyPoints(new Array(props.keyPoints.length).fill(false).map((val, index) => index === 0 ? true : val)); // El primer key point ya está seleccionado
			if (props.keyPoints.length > 0) {
				setSelectedIndex(0);
			}
		}
	}, [props.keyPoints]);

	useEffect(() => {
		const startDate = DateTime.now().setZone('America/Mexico_City').toISO();
		setStartDate(startDate);
	}, []);

	const nextHandler = () => {
		const endDate = DateTime.now().setZone('America/Mexico_City').toISO();

		const body: IAnalyticsChapterModule = {
			startAt: startDate,
			endAt: endDate,
			isCompleted: keyPointsChecked,
			moduleType: "keyPoints"
		}

		completeChapterModule(props.id, body).then(r => props.setNextModule());
	}

	return (
		<ContentContainer>

			<Container>

				{selectedIndex !== undefined && (
					<TextContainer>
						<div>
							<Title>{keyPoints[selectedIndex].header}</Title>
							<Description>
								<span>{keyPoints[selectedIndex].content}</span>
							</Description>
						</div>

						<AvatarImage src={avatarImg} alt={"avatar_image"}/>
					</TextContainer>
				)}

				<BulletButtonContainer>
					<BulletsContainer>
						<BulletList>
							{keyPoints.map((keyPoint, index) => (
								<>
									<OptionItem
										key={index}
										active={selectedIndex === index}
										onClick={() => handleOptionClick(index)}>
										{keyPoint.header}
									</OptionItem>

									{index === selectedIndex && (
										<Description>
											<span>{keyPoints[selectedIndex].content}</span>
										</Description>
									)}
								</>
							))}
						</BulletList>
					</BulletsContainer>

					<ButtonsSection>
						<ButtonsContainerContainer>
							<ButtonsContainer>
								<Button
									appearance={"secondary"}
									size={"large"}
									onClick={props.setPreviousModule}
								>
									Anterior
								</Button>

								<Button
									size={"large"}
									onClick={nextHandler}
								>Siguiente
								</Button>

							</ButtonsContainer>
						</ButtonsContainerContainer>
					</ButtonsSection>

				</BulletButtonContainer>
			</Container>

		</ContentContainer>
	);
};

export default ChapterModuleKeyPoints;

interface OptionItemProps {
	active: boolean;
	onClick: () => void;
	children: React.ReactNode;
}

const OptionItem: React.FC<OptionItemProps> = ({active, onClick, children}) => (
	<OptionContainer>
		<OptionArrowContainer>{active && <OptionArrow/>}</OptionArrowContainer>
		<Option active={active} onClick={onClick}>
			{children}
		</Option>
	</OptionContainer>
);

const AvatarImage = styled.img`
  width: 195px;
  height: auto;
  z-index: 1;
  align-self: flex-start; // Align to start

  ${media.max.tablet} {
    margin-left: -20px;
  }

  ${media.max.mobile} {
    display: none;
  }
`;

export const ButtonsContainerContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: flex-end;
  right: 0;

  ${media.max.tablet} {
    justify-content: center;
  }

  ${media.max.mobile} {
    position: relative;
    padding-bottom: 16px;
  }
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  ${media.max.mobile} {
    padding: 0 16px;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  color: ${colors.text};
  margin-bottom: 20px;

  ${media.max.tablet} {
    margin-bottom: 16px;
  }
`;

export const ContentContainer = styled.div`
  padding: 36px 66px 0 108px;
  display: flex;
  flex-grow: 1;

  ${media.max.tablet} {
    padding: 44px 40px 0 40px;
  }

  ${media.max.mobile} {
    padding: 16px 24px 0 24px;
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TextContainer = styled.div`
  width: 100%;
  max-width: 37.35%;
  padding-right: 46px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; // Set height to 100%

  ${media.max.tablet} {
    max-width: 40%;
    padding-right: 22px;
  }

  ${media.max.mobile} {
    display: none;
  }
`;

export const BulletsContainer = styled.div`
  width: 100%;
  border-left: 2px solid ${colors.purple};
  height: fit-content;

  ${media.max.mobile} {
    border-left: none;
  }
`;

const BulletButtonContainer = styled.div`
  width: 100%;
  max-width: 62.65%;
  position: relative;

  ${media.max.tablet} {
    max-width: 60%;
  }

  ${media.max.mobile} {
    max-width: 100%;
    border-left: none;
  }
`;

export const BulletList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px 0;

  ${media.min.tablet} {
    ${Description} {
      display: none;
    }
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const OptionArrowContainer = styled.div`
  height: 100%;
  width: 85px;

  ${media.max.mobile} {
    display: none;
  }
`;

export const OptionArrow = styled.div`
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 16px solid ${colors.purple};
`;

export const Option = styled.button<{ active: boolean }>`
  position: relative;
  width: 100%;
  height: auto;
  border-radius: 5px;
  transform: scale(1);
  cursor: pointer;
  z-index: 1;
  padding: 24px;

  /* font styles */
  font-weight: 600;
  font-size: ${getRem(16)};
  line-height: 25px;
  color: ${colors.white};

  /* remove button defaults */
  border: none;
  box-shadow: none;

  background: ${colors.gradient.purple};

  ${p => !p.active && css`
    font-weight: 400;
    background: ${colors.white};
    border: 1px solid ${colors.gray};
    color: ${colors.text2};
  `};

  &:hover:before {
    opacity: 1;
  }
`;

export const ChapterHeader = styled.div`
  padding: 8px 40px;
  background: ${colors.gradient.orange};
`;

export const ChapterHeaderText = styled.p`
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: ${colors.white};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  background: transparent;
  gap: 24px;
  margin-top: 40px;
  z-index: 10;

  ${media.max.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

const ButtonsSection = styled.div`
  position: relative;
  height: 130px;
  
  
  ${media.max.tablet} {
    height: 210px;
  }

  ${media.max.mobile} {
    height: unset;
  }
`;
