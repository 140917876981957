import React from 'react';
import {colors} from "../../../styles/colors/colors";

interface ClockIconProps extends React.SVGProps<SVGSVGElement> {
	color?: string;
}

const ClockIcon = ({color = colors.text, ...props}: ClockIconProps) => (
	<svg width="16" height="16" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<mask id="mask0_1275_4964" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="17"
				height="17">
			<path
				d="M8.44334 0.357849C12.9143 0.357849 16.5388 3.9823 16.5388 8.45328C16.5388 12.9243 12.9143 16.5487 8.44334 16.5487C3.97235 16.5487 0.347908 12.9243 0.347908 8.45328C0.347908 3.9823 3.97235 0.357849 8.44334 0.357849Z"
				fill="white"/>
		</mask>
		<g mask="url(#mask0_1275_4964)">
			<path
				d="M8.44334 16.5487C12.9143 16.5487 16.5388 12.9243 16.5388 8.45328C16.5388 3.9823 12.9143 0.357849 8.44334 0.357849C3.97235 0.357849 0.347908 3.9823 0.347908 8.45328C0.347908 12.9243 3.97235 16.5487 8.44334 16.5487Z"
				stroke="#8D99A2" strokeWidth="3.03579" strokeMiterlimit="5"/>
		</g>
		<path d="M8.44335 4.40558V8.90505L11.8164 10.2411" stroke="#8D99A2" strokeWidth="1.51789" strokeLinecap="round"
				strokeLinejoin="round"/>
	</svg>
);

export default ClockIcon;
