import React, {useEffect, useRef} from 'react';

interface AudioProps {
	src: string;
}

const AudioPlayerNoContext = ({src}: AudioProps) => {
	const audioRef = useRef<HTMLAudioElement>(null);

	const playAudioHandler = () => {
		audioRef.current?.play();
	}

	useEffect(() => {
		playAudioHandler();
	}, []);

	return <audio ref={audioRef} src={src} autoPlay/>;
};

export default AudioPlayerNoContext;
