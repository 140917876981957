import React, {useContext, useEffect} from 'react';
import confettiImage from "../../../assets/files/confetti.png";
import {ConfettiBackground, ConfettiBackgroundContainer} from "../autodiagnosisCompleted/autodiagnosisCompleted";
import styled from "styled-components";
import {media} from "../../../styles/media/media";
import {colors} from "../../../styles/colors/colors";
import AnchorInput from "../../../components/inputs/anchor/anchorInput";
import AnimatedAvatar from "../../../componentsBackup/animatedAvatar/animatedAvatar";
import ButtonAnchor from "../../../components/inputs/buttonAnchor/buttonAnchor";
import {Link} from "react-router-dom";
import {AvatarMediaContext} from "../../../context/globalContainer/avatarMediaContext";
import {IAvatarMediaContext} from "../../../context/globalContainer/globalContainerContext.int";

interface IChapterFinished {
	isLastChapter: boolean;
	chapterName: string;
	setPreviousModule: () => void;
}

const ChapterFinished = (props: IChapterFinished) => {
	const context = useContext(AvatarMediaContext) as IAvatarMediaContext;

	const returnToHome = () => {
		const currentURL = window.location.href;
		const domain = window.location.protocol + "//" + window.location.host;
		const newPath = "/manuales/manual-didactico-de-anticorrupcion";
		let newURL = currentURL.replace(domain, "");
		newURL = domain + newPath
		window.location.href = newURL;
	}

	const returnToRecognition = () => {
		const currentURL = window.location.href;
		const domain = window.location.protocol + "//" + window.location.host;
		const newPath = "/manuales/manual-didactico-de-anticorrupcion/reconocimiento-y-compromiso";
		let newURL = currentURL.replace(domain, "");
		newURL = domain + newPath;
		window.location.href = newURL;
	}

	const videoSrc = "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/capitulo/capitulo-terminado.mp4";
	const imageSrc = "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/capitulo/capitulo-terminado.png";

	useEffect(() => {
		context.showMediaControllerHandler();
	}, [])

	const goToPreviousModule = () => {
		props.setPreviousModule();
		context.hideMediaControllerHandler();
	}

	return (
		<AutodiagnosisCompletedContainer>

			<ConfettiBackgroundContainer>
				<ConfettiBackground src={confettiImage} alt={'confetti_image'}/>
			</ConfettiBackgroundContainer>

			<Congrats isLastChapter={props.isLastChapter}>
				<AvatarContainer>
					<AnimatedAvatar
						autoPlay={true}
						videoSrc={videoSrc}
						imageSrc={imageSrc}
					/>
				</AvatarContainer>
				<Results>¡Felicidades!</Results>

				{props.isLastChapter && (
					<>
						<LastChapterSubtitle>
							Has adquirido el 100% del aprendizaje
						</LastChapterSubtitle>
						<TextSmall>
							Es momento de aplicar tus nuevos conocimientos en el siguiente exámen para reiterar tu compromiso y
							así ayudar a tu organización a ser más transparente. Se te otorgará un reconocimiento por apoyar
							este movimiento de transparencia y rendición de cuentas para las organizaciones de la sociedad
							civil.
						</TextSmall>
					</>
				)}

				{!props.isLastChapter && (
					<>
						<Text>Has adquirido los conocimientos y ahora podrás cumplir adecuadamente con</Text>
						<ChapterName>{props.chapterName}</ChapterName>
					</>
				)}

				<ButtonsContainer>

					{props.isLastChapter && (
						<ButtonAnchor size={"large"} as={Link} to={"/manuales/manual-didactico-de-anticorrupcion/reconocimiento-y-compromiso"}>
							Siguiente
						</ButtonAnchor>
					)}

					{!props.isLastChapter && (
						<>
							<ButtonAnchor size={"large"} as={Link} to={"/manuales/manual-didactico-de-anticorrupcion"}>
								Volver al menú
							</ButtonAnchor>
							<AnchorInput
								onClick={goToPreviousModule}
								appearance={'link'}
								href={'javascript:void(0)'}>
								{"Regresar"}
							</AnchorInput>
						</>
					)}
				</ButtonsContainer>
			</Congrats>

		</AutodiagnosisCompletedContainer>
	);
};

export default ChapterFinished;


export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const AvatarContainer = styled.div`
  max-width: 230px;
  width: 100%;
  background: ${colors.gradient.green};
  border-radius: 50%;
  margin-top: 16px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
`;

export const Text = styled.p`
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 16px;
`;

export const TextSmall = styled.p`
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 16px;
`;

export const LastChapterSubtitle = styled.h3`
  margin-bottom: 16px;
  font-size: 25px;
  font-weight: 600;
`;

export const ChapterName = styled.h3`
  margin-bottom: 16px;
  font-size: 25px;
  font-weight: 600;
`;

export const Congrats = styled.h1<{ isLastChapter: boolean }>`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  max-width: ${p => p.isLastChapter ? '600px' : '448px'};
  padding: 0 34px;

  ${media.max.mobile} {
    top: 50px;
  }
`;

export const Results = styled.h1`
  text-align: center;
  margin-bottom: 16px;
  width: 100%;

  font-weight: 600;
  font-size: 50px;
  line-height: 61px;

  ${media.max.mobile} {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
  }
`;

export const AutodiagnosisCompletedContainer = styled.div`
  position: relative;
  min-height: 100vh;
`;

export const GeneralContainer = styled.div`
  position: relative;
  min-height: calc(100vh - 143px);
`;
