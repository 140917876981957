import {SVGProps} from 'react';
import {colors} from "../../../styles/colors/colors";

interface HamburgerIconProps extends SVGProps<SVGSVGElement> {
	color?: string;
}

export const HamburgerIcon = ({color = colors.text, ...props}: HamburgerIconProps) => (
	<svg width="20" height="16" viewBox="0 0 20 16" fill="none" {...props}>
		<line y1="1" x2="20" y2="1" stroke={color} strokeWidth="2"/>
		<line y1="8" x2="20" y2="8" stroke={color} strokeWidth="2"/>
		<line y1="15" x2="20" y2="15" stroke={color} strokeWidth="2"/>
	</svg>
);
