import React, {useEffect, useRef, useState} from 'react';
import {
	AccordionButton,
	AccordionContent,
	AccordionFixContainer,
	CheckContainer,
	ContentWrapper
} from "./accordion.styles";
import {IAccordionProps} from "./accordion.int";
import CheckIcon from "../assets/svg/check";

const Accordion = ({isOpen = false, ...props}: IAccordionProps) => {
	const [showContent, setShowContent] = useState(isOpen);
	const [contentMaxHeight, setContentMaxHeight] = useState<string | null>(null);
	const contentRef = useRef<HTMLDivElement | null>(null);

	const handleToggle = () => {
		showContent ? close() : open();
		setShowContent(!showContent);
	};

	const open = () => {
		if (contentRef.current) {
			setContentMaxHeight(`${contentRef.current.scrollHeight}px`);
		}
	}

	const close = () => {
		setContentMaxHeight(null);
	}

	useEffect(() => {
		if (showContent) open();
	}, []);

	return (
		<AccordionFixContainer
			highlight={props.highlight}
			highlightMobile={props.highlightMobile}>
			<AccordionButton onClick={handleToggle} isOpen={showContent}>
				<CheckContainer>
					<CheckIcon checked={props.isChecked}/>
				</CheckContainer>
				<span>{props.title}</span>
			</AccordionButton>
			<AccordionContent maxHeight={contentMaxHeight} ref={contentRef}>
				<ContentWrapper>
					{props.children}
				</ContentWrapper>
			</AccordionContent>
		</AccordionFixContainer>
	);
};

export default Accordion;
