import {AutodiagnosisContainer2} from "../../../componentsBackup/question/question.styles";
import styled from "styled-components";

import confettiImage from '../../../assets/files/confetti.png';
import {media} from "../../../styles/media/media";
import ReducedChapterCard from "../../../componentsBackup/reducedChapterCard/reducedChapterCard";
import Button from "../../../components/inputs/button/button";
import React, {useContext, useEffect} from "react";
import AnchorInput from "../../../components/inputs/anchor/anchorInput";
import {IChapterCardMini} from "../../../api/manual/autodiagnosis/autodiagnosis.int";
import AudioPlayer from "../../../componentsBackup/audioPlayer/audioPlayer";
import {AvatarMediaContext} from "../../../context/globalContainer/avatarMediaContext";
import {IAvatarMediaContext} from "../../../context/globalContainer/globalContainerContext.int";

interface IAutodiagnosisCompletedPageProps {
	chapters: IChapterCardMini[];
	repeatAutodiagnosis: () => void;
	show: boolean;
	playAvatarAudio: boolean;
	resetSlide: () => void;
}

const AutodiagnosisCompletedPage = (props: IAutodiagnosisCompletedPageProps) => {

	const correctAnswers = props.chapters.filter((card) => card.isChecked).length;
	const totalChapters = props.chapters.length;
	const context = useContext(AvatarMediaContext) as IAvatarMediaContext;

	const filterCard = (isCorrect: boolean) => {
		return props.chapters.filter(card => card.isError !== isCorrect);
	}

	const repeatHandler = () => {
		props.repeatAutodiagnosis();
	}

	const returnToHome = () => {
		const currentURL = window.location.href;
		const domain = window.location.protocol + "//" + window.location.host;
		const newPath = "/manuales/manual-didactico-de-anticorrupcion";
		let newURL = currentURL.replace(domain, "");
		newURL = domain + newPath
		window.location.href = newURL;
	}

	useEffect(() => {
		if (props.show) {
			context.showMediaControllerHandler();
		} else {
			context.hideMediaControllerHandler();
		}
		props.resetSlide();
	}, [props.show])

	return (
		<AutodiagnosisContainer2 show={props.show}>

			{props.show && (
				<AudioPlayer
					autoPlay={props.playAvatarAudio}
					src={"https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/autodiagnostico/autodiagnostico-resultados.mp3"}/>
			)}

			<AutodiagnosisCompletedContainer>

				<ConfettiBackgroundContainer>
					<ConfettiBackground src={confettiImage} alt={'confetti_image'}/>
					<Results>Obtuviste {correctAnswers} de {totalChapters}</Results>
				</ConfettiBackgroundContainer>

				<ContentContainer>
					<CardGrid>
						{filterCard(true).map((card, index) => (
							<ReducedChapterCard
								key={index}
								name={card.name}
								imageUrl={card.imageUrl}
								isChecked={card.isChecked}
								isError={card.isError}
								urlName={card.urlName}
							/>
						))}
					</CardGrid>

					<ThankText>Te recomendamos que fortalezcas tus conocimientos en los siguientes temas:</ThankText>

					<CardGrid>
						{filterCard(false).map((card, index) => (
							<ReducedChapterCard
								key={index}
								name={card.name}
								imageUrl={card.imageUrl}
								isChecked={card.isChecked}
								isError={card.isError}
								urlName={card.urlName}
							/>
						))}
					</CardGrid>

					<ButtonSection>
						<Button
							size={'large'}
							onClick={returnToHome}>
							Ir al menú principal
						</Button>
						<AnchorButtonContainer>
							<AnchorInput
								color={"white"}
								onClick={repeatHandler}>
								Consultar resultados
							</AnchorInput>
						</AnchorButtonContainer>
					</ButtonSection>
				</ContentContainer>

			</AutodiagnosisCompletedContainer>

		</AutodiagnosisContainer2>
	)
}

export default AutodiagnosisCompletedPage;

export const AnchorButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;

  ${media.max.tablet} {
    margin-top: 28px;
  }

  ${media.max.mobile} {
    margin-top: 24px;
  }
`;

export const ContentContainer = styled.div`
  margin: auto;
  max-width: 908px;
  padding: 40px;

  ${media.max.mobile} {
    padding: 16px;
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 68px;
`;

export const ThankText = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 52px;
  margin-bottom: 34px;
  color: white;

  ${media.max.mobile} {
    margin: 28px 0;
    text-align: center;
  }
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;

  ${media.max.tablet} {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  ${media.max.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;


export const AutodiagnosisCompletedContainer = styled.div`

`;

export const ConfettiBackgroundContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  position: relative;
`;

export const ConfettiBackground = styled.img`
  width: 1162px;
  height: auto;
  margin: 0 60px;

  ${media.max.mobile} {
    width: 466px;
  }
`;

export const Results = styled.h1`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
  color: white;

  font-weight: 600;
  font-size: 50px;
  line-height: 61px;

  ${media.max.tablet} {
    font-weight: 600;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 48px;
  }

  ${media.max.mobile} {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 0;

    bottom: 50%;
    transform: translate(-50%, 50%);
  }
`;