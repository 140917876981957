import React from 'react';
import {colors} from "../../../styles/colors/colors";

interface ICheckIconProps extends React.SVGProps<SVGSVGElement> {
	color?: string;
	checked?: boolean;
}

const MouseIcon = ({color = colors.white}: ICheckIconProps) => {
	return (
		<svg width="34" height="55" viewBox="0 0 34 55" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="0.772727" y="0.772727" width="32.4545" height="53.0606" rx="16.2273" stroke={color}
					strokeWidth="1.54545"/>
			<line x1="17.7727" y1="15.7121" x2="17.7727" y2="26.5303" stroke={color} strokeWidth="1.54545"
					strokeLinecap="round"/>
		</svg>
	);
};

export default MouseIcon;
