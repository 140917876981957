import React, {useContext, useEffect} from 'react';
import confettiImage from "../../../assets/files/confetti.png";
import {ConfettiBackground, ConfettiBackgroundContainer} from "../autodiagnosisCompleted/autodiagnosisCompleted";
import styled, {keyframes} from "styled-components";
import {media} from "../../../styles/media/media";
import Button from "../../../components/inputs/button/button";
import {colors} from "../../../styles/colors/colors";
import AnchorInput from "../../../components/inputs/anchor/anchorInput";
import {Body_Style, H3_Style} from "../../../styles/font/styles/font.styles";
import AnimatedAvatar from "../../../componentsBackup/animatedAvatar/animatedAvatar";
import {AvatarMediaContext} from "../../../context/globalContainer/avatarMediaContext";
import {IAvatarMediaContext} from "../../../context/globalContainer/globalContainerContext.int";

interface FinalExamCompletedProps {
	setHighlightAnswers: () => void;
	showResults: boolean;
	repeatExamHandler: () => void;
	correctAnswersNumber: number;
	questionsLength: number;
	playAvatar: boolean;
	playAvatarFinished: boolean;
}

const FinalExamCompletedPage = (props: FinalExamCompletedProps) => {

	const passed = props.correctAnswersNumber === props.questionsLength;
	const titleText = passed ? '¡Felicidades!' : '¡Ya casi lo tienes!';
	const context = useContext(AvatarMediaContext) as IAvatarMediaContext;

	const returnToHome = () => {
		const currentURL = window.location.href;
		const domain = window.location.protocol + "//" + window.location.host;

		// Replace the path in the current URL with the desired path
		const newPath = "/manuales/manual-didactico-de-anticorrupcion/reconocimiento-y-compromiso";
		let newURL = currentURL.replace(domain, "");
		newURL = domain + newPath

		// Redirect to the new URL
		window.location.href = newURL;
	}

	const getSubText = () => {
		return passed ? 'Has aprobado el examen. Antes de obtener tu reconocimiento, es importante que conozcas  la carta compromiso que Appleseed tiene para ti.' : `Para obtener tu reconocimiento debes obtener ${props.questionsLength} respuestas correctas. Puedes repetir el examen las veces que sean necesarias.`;
	}

	const repeatExamHandler = () => {
		props.repeatExamHandler();
		props.setHighlightAnswers();
	};

	const videoSrc = !passed ? "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/examen/examen-casi-lo-tienes.mp4"
		: "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/examen/examen-felicidades.mp4";

	const imageSrc = "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/examen/examen.gif";

	useEffect(() => {
		if (props.showResults) {
			context.showMediaControllerHandler();
		} else {
			context.hideMediaControllerHandler();
		}
	})

	useEffect(() => {
		console.log("props.playAvatar", props.playAvatar);
		console.log("props.playAvatarFinished", props.playAvatarFinished);
	}, [])

	return (
		<AutodiagnosisCompletedContainer show={props.showResults}>

			{passed && (
				<ConfettiBackgroundContainer>
					<ConfettiBackground src={confettiImage} alt={'confetti_image'}/>
				</ConfettiBackgroundContainer>
			)}

			<Congrats>
				<AvatarContainer>
					<AnimatedAvatar
						autoPlay={props.playAvatar || props.playAvatarFinished}
						videoSrc={videoSrc}
						imageSrc={imageSrc}
					/>
				</AvatarContainer>
				<Results>{titleText}</Results>
				<SubTitle>{`Obtuviste ${props.correctAnswersNumber} de ${props.questionsLength}`}</SubTitle>
				<Text>{getSubText()}</Text>

				<ButtonsContainer>
					<Button size={"large"} onClick={passed ? returnToHome : repeatExamHandler}>
						{passed ? 'Volver al menú' : 'Repetir examen'}
					</Button>
					<AnchorInput
						color={"white"}
						onClick={!passed ? returnToHome : repeatExamHandler}
						appearance={'link'}
						href={'javascript:void(0)'}>
						{!passed ? 'Volver al menú' : 'Repetir examen'}
					</AnchorInput>
				</ButtonsContainer>
			</Congrats>

		</AutodiagnosisCompletedContainer>
	);
};

export default FinalExamCompletedPage;


export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const AvatarContainer = styled.div`
  max-width: 230px;
  width: 100%;
  background: ${colors.gradient.green};
  border-radius: 50%;
  margin-top: 16px;
  margin-bottom: 8px;
  margin-left: auto;
  margin-right: auto;
`;

export const Text = styled(Body_Style)`
  margin-bottom: 16px;
  color: white;
`;

export const ChapterName = styled.h3`
  margin-bottom: 16px;
  font-size: 25px;
  font-weight: 600;
`;

export const Congrats = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  width: 100%;
  max-width: 525px;
  padding: 0 34px;

  ${media.max.mobile} {
    top: 50px;
  }
`;

export const Results = styled.h1`
  text-align: center;
  margin-bottom: 16px;
  width: 100%;
  color: white;

  font-weight: 600;
  font-size: 50px;
  line-height: 61px;

  ${media.max.mobile} {
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
  }
`;

const moveGradient = keyframes`
  0%, 100% {
    background-size: 100% 100%;
    background-position: 0% 0%; // Start at Top-left
  }
  12.5% {
    background-size: 125% 112.5%;
    background-position: 25% 0%; // Between Top-left and Top-center
  }
  25% {
    background-size: 150% 125%;
    background-position: 50% 0%; // Move to Top-center
  }
  37.5% {
    background-size: 175% 137.5%;
    background-position: 75% 25%; // Between Top-center and Right-center
  }
  50% {
    background-size: 200% 150%;
    background-position: 100% 50%; // Move to Right-center
  }
  62.5% {
    background-size: 175% 137.5%;
    background-position: 75% 75%; // Between Right-center and Bottom-center
  }
  75% {
    background-size: 150% 125%;
    background-position: 50% 100%; // Move to Bottom-center
  }
  87.5% {
    background-size: 125% 112.5%;
    background-position: 25% 100%; // Between Bottom-center and Top-left
  }
`;

export const AutodiagnosisCompletedContainer = styled.div<{ show: boolean }>`
  position: relative;
  min-height: 100vh;
  
  background: linear-gradient(325deg, #B17584 0%, #76698B 44.03%, #354C6C 74.24%, #003C57 100%);
  background-size: 100% 100%;
  animation: ${moveGradient} 5s ease-in-out infinite; // Using ease-in-out for smoother transition
  
  display: ${p => p.show ? 'block' : 'none'};
`;

export const GeneralContainer = styled.div`
  position: relative;
  min-height: calc(100vh - 143px);
`;

export const SubTitle = styled(H3_Style)`
  font-weight: 600;
  margin-bottom: 18px;
  color: white;
`;
