import React, {useContext, useEffect, useState} from 'react';
import {
	HeaderComponent,
	HeaderComponent_Audio,
	HeaderComponent_ContentContainer,
	HeaderComponent_HamburgerButton,
	HeaderComponent_Items,
	HeaderComponent_Items_Container,
	HeaderComponent_Link_Logo_Image,
	HeaderComponent_LogoLink,
	HeaderComponent_LogoutButton,
	HeaderComponent_Menu,
	HeaderComponent_Menu_CloseButton,
	HeaderComponent_Menu_Content,
	HeaderComponent_Menu_Content_Item,
	HeaderComponent_Menu_Content_Items,
	HeaderComponent_Menu_Content_Title,
	HeaderComponent_Overlay,
	HeaderComponent_UserName
} from './header.styles';
import logoImage from '../../assets/files/logo/logo2.png';
import {HamburgerIcon} from "../assets/svg/hamburgerIcon";
import {CrossIcon} from "../assets/svg/crossIcon";
import {ApiConstants} from "../../api/constraints";
import {AuthContext} from "../../context/auth/AuthContext";
import {IAuthContext} from "../../context/auth/AuthContext.int";
import {IUserProfileProps} from "../../api/user/profile/userProfile.int";
import {APIUser} from "../../api/user/profile/userProfile";
import MediaController from "../mediaController/mediaController";
import {APIManual} from "../../api/manual/dashboard/manualDashboard";
import {ManualChapterCardDTO} from "../../api/manual/dashboard/manualDashboard.int";
import {useParams} from "react-router-dom";

const Header = () => {
	const { manualUrlName } = useParams();
	const [showMenu, setShowMenu] = useState(false);
	const {userName, setUserName} = useContext(AuthContext) as IAuthContext;
	const [userProfile, setUserProfile] = useState<IUserProfileProps>();
	const {getUserProfile} = APIUser();

	const {getDashboard} = APIManual();
	const [chapters, setChapters] = useState<ManualChapterCardDTO[]>([]);

	const manualChaptersPath = '/manuales/manual-didactico-de-anticorrupcion/capitulos';

	const fetchDashboard = async () => {
		const manualUrlName = "manual-didactico-de-anticorrupcion";
		const manualDashboard = await getDashboard(manualUrlName);

		if (manualDashboard) {
			setChapters(manualDashboard.chapters);
		}
	};

	useEffect(() => {
		fetchDashboard();
	}, [])

	const fetchUserProfile = async () => {
		const userProfile = await getUserProfile();

		if (userProfile) {
			setUserProfile(userProfile);
		}
	}

	const toggleMenu = () => {
		setShowMenu(!showMenu);
		if (!showMenu) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "auto";
		}
	};

	const closeMenu = () => {
		setShowMenu(false);
		document.body.style.overflow = "auto";
	};

	const logoutUser = () => {
		document.cookie = 'token_appleseed_aut=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
		window.location.href = ApiConstants.LOGIN_SCREEN;
	}

	useEffect(() => {
		fetchUserProfile();
	}, []);

	useEffect(() => {
		if (userProfile && userProfile.firstName && userProfile.lastName && setUserName) {
			const fullName = `${userProfile.firstName} ${userProfile.lastName}`;
			setUserName(fullName);
		}
	}, [userProfile, setUserName]);

	return (
		<>
			<HeaderComponent>
				<HeaderComponent_ContentContainer>
					<HeaderComponent_LogoLink href="/manuales/manual-didactico-de-anticorrupcion">
						<div>
							<HeaderComponent_Link_Logo_Image src={logoImage} alt={"logo_img"}/>
						</div>
					</HeaderComponent_LogoLink>

					<HeaderComponent_Items_Container>
						<HeaderComponent_Audio>
							<MediaController/>
						</HeaderComponent_Audio>

						<HeaderComponent_Items>
							<HeaderComponent_UserName>{userName}</HeaderComponent_UserName>
							<HeaderComponent_LogoutButton onClick={logoutUser}>Cerrar sesión</HeaderComponent_LogoutButton>
						</HeaderComponent_Items>

						<HeaderComponent_HamburgerButton onClick={toggleMenu}>
							<HamburgerIcon/>
						</HeaderComponent_HamburgerButton>
					</HeaderComponent_Items_Container>

					<HeaderComponent_Overlay onClick={closeMenu} show={showMenu}/>

					<HeaderComponent_Menu show={showMenu}>
						<HeaderComponent_Menu_CloseButton>
							<CrossIcon onClick={closeMenu} style={{cursor: 'pointer'}}/>
						</HeaderComponent_Menu_CloseButton>

						<HeaderComponent_Menu_Content>
							<HeaderComponent_Menu_Content_Title>Autodiagnostico</HeaderComponent_Menu_Content_Title>
						</HeaderComponent_Menu_Content>

						{chapters.length > 0 && (
							<HeaderComponent_Menu_Content>
								<HeaderComponent_Menu_Content_Title>Capítulos</HeaderComponent_Menu_Content_Title>

								<HeaderComponent_Menu_Content_Items>
									{chapters.map((chapter, index) => (
										<HeaderComponent_Menu_Content_Item href={`${manualChaptersPath}/${chapter.urlName}`} key={index}>
											{chapter.name}
										</HeaderComponent_Menu_Content_Item>
									))}
								</HeaderComponent_Menu_Content_Items>

							</HeaderComponent_Menu_Content>
						)}

						<HeaderComponent_Menu_Content>
							<HeaderComponent_Menu_Content_Title>
								Reconocimiento y compromiso
							</HeaderComponent_Menu_Content_Title>
							<HeaderComponent_Menu_Content_Items>

								<HeaderComponent_Menu_Content_Items>
									<HeaderComponent_Menu_Content_Item href="#">
										Exámen
									</HeaderComponent_Menu_Content_Item>
									<HeaderComponent_Menu_Content_Item href="#">
										Carta compromiso
									</HeaderComponent_Menu_Content_Item>
									<HeaderComponent_Menu_Content_Item href="#">
										Carta principios
									</HeaderComponent_Menu_Content_Item>
									<HeaderComponent_Menu_Content_Item href="#">
										Mapa de cumplimiento
									</HeaderComponent_Menu_Content_Item>
								</HeaderComponent_Menu_Content_Items>

							</HeaderComponent_Menu_Content_Items>
						</HeaderComponent_Menu_Content>

						<HeaderComponent_Menu_Content>
							<HeaderComponent_Menu_Content_Title>
								{userName}
							</HeaderComponent_Menu_Content_Title>
							<HeaderComponent_Menu_Content_Items>

								<HeaderComponent_Menu_Content_Items>
									<HeaderComponent_Menu_Content_Item href="#" onClick={logoutUser}>
										Cerrar sesión
									</HeaderComponent_Menu_Content_Item>
								</HeaderComponent_Menu_Content_Items>

							</HeaderComponent_Menu_Content_Items>
						</HeaderComponent_Menu_Content>

					</HeaderComponent_Menu>
				</HeaderComponent_ContentContainer>
			</HeaderComponent>
		</>
	);
};

export default Header;
