import React, {useContext, useEffect, useRef} from 'react';
import {AvatarMediaContext} from '../../context/globalContainer/avatarMediaContext';
import {IAvatarMediaContext} from "../../context/globalContainer/globalContainerContext.int";

interface AudioProps {
	src: string;
	autoPlay: boolean;
}

const AudioPlayer = ({src, autoPlay}: AudioProps) => {
	const audioRef = useRef<HTMLAudioElement>(null);
	const context = useContext(AvatarMediaContext) as IAvatarMediaContext;
	const {isMediaMuted, isMediaPlaying, setIsMediaPlaying} = context;

	const playAudioHandler = () => {
		audioRef.current?.play()
			.then(() => setIsMediaPlaying(true))
			.catch(() => setIsMediaPlaying(false));
	}

	const audioFinishedHandler = () => {
		setIsMediaPlaying(false);
	}

	useEffect(() => {
		const audio = audioRef.current;
		if (audio) {
			audio.muted = isMediaMuted;
		}
	}, [isMediaMuted]);

	useEffect(() => {
		const audio = audioRef.current;
		if (audio) {
			audio.addEventListener('ended', audioFinishedHandler);
		}
	}, [])

	useEffect(() => {
		if (isMediaPlaying) {
			playAudioHandler();
		}
	}, [isMediaPlaying]);

	useEffect(() => {
		if (autoPlay) {
			setTimeout(() => {
				playAudioHandler();
			}, 1000);
		}
	}, []);

	return <audio ref={audioRef} src={src} />;
};

export default AudioPlayer;
