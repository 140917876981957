import {IUserManualRecognitionStatus, TUserManualRecognitionStatusResponse} from './userManualStatus.int';

import API from '../../api';
import {AxiosResponse} from "axios";

export const APIManualRecognition = () => {
	const path = `sl/manuals`;

	const getRecognitionStatus = async (manualName: string): Promise<IUserManualRecognitionStatus | undefined> => {
		const url = `${path}/${manualName}/recognition-and-compromise`;

		try {
			const {data: response}: TUserManualRecognitionStatusResponse = await API.get(url);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}

			return response.data;
		} catch (err) {
			return Promise.reject(err);
		}
	};

	const completeCompromiseLetter = async (manualName: string) => {
		const url = `${path}/${manualName}/completeCompromiseLetter`;

		try {
			const {data: response}: AxiosResponse = await API.get(url);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}
		} catch (err) {
			return Promise.reject(err);
		}
	}

	return {
		getRecognitionStatus,
		completeCompromiseLetter
	};
};
