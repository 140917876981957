import React from 'react';
import {colors} from "../../../styles/colors/colors";

interface ICheckIconProps extends React.SVGProps<SVGSVGElement> {
	color?: string;
	checked?: boolean;
}

const AnchorArrow = ({color = colors.purple}: ICheckIconProps) => {
	return (
		<svg width="10" height="8" viewBox="0 0 10 8" fill={color} xmlns="http://www.w3.org/2000/svg">
			<path
				d="M9.0408 1.85015C9.09612 1.55147 8.89883 1.26451 8.60015 1.2092L3.7329 0.307853C3.43422 0.252542 3.14726 0.449831 3.09195 0.748509C3.03664 1.04719 3.23393 1.33415 3.5326 1.38946L7.85905 2.19066L7.05785 6.5171C7.00254 6.81578 7.19983 7.10274 7.49851 7.15805C7.79719 7.21336 8.08415 7.01607 8.13946 6.7174L9.0408 1.85015ZM0.811591 7.70322L8.81159 2.20322L8.18841 1.29678L0.188409 6.79678L0.811591 7.70322Z"/>
		</svg>
	)
};

export default AnchorArrow;
