import styled from "styled-components";
import {colors} from "../../styles/colors/colors";

export const AvatarAudioContainer = styled.div<{show: boolean}>`
  border-radius: 102px;
  background: ${colors.background.gray};
  padding: 8px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  gap: 6px;
  display: ${p => p.show ? 'flex' : 'none'}
`;

export const AudioButton = styled.button`
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  display: flex;
  
  img {
	 width: 32px;
	 height: auto;
  }
`;
