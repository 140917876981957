import React, {useCallback, useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {media} from "../../../styles/media/media";

const ProgressContainer = styled.div`
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.5);
  position: relative;
  cursor: pointer;
  margin: 0 4px;

  ${media.max.mobile} {
    height: 2px;
  }
`;

const ProgressBarStyled = styled.div<{ width: number }>`
  height: 100%;
  background-color: white;
  width: ${props => props.width}%;
  position: absolute;
  top: 0;
  left: 0;
`;

const DraggableContainer = styled.div<{ left: number }>`
  position: absolute;
  left: ${props => props.left}%;
  width: 0;
  top: -3.5px; // center vertically
`;

const Circle = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  top: 5px;
  left: -5px;
  transform: translateY(-50%); // shifts up by half of its own height
  cursor: pointer;

  ${media.max.mobile} {
    width: 5px;
    height: 5px;
    left: -2.5px; // Adjust the left value for mobile to keep the circle centered
    top: 4px;
  }
`;

interface IProgressBar {
	progressPercentage: number;
	setProgressPercentage: (percentage: number) => void;
}

const ProgressBar = ({progressPercentage = 0, ...props}: IProgressBar) => {
	const containerRef = useRef<HTMLDivElement | null>(null);

	const handleMouseMove = useCallback((event: MouseEvent) => {
		if (!containerRef.current) {
			return;
		}
		const rect = containerRef.current.getBoundingClientRect();
		const x = event.clientX - rect.left;
		let percent = (x / rect.width) * 100;
		percent = Math.min(Math.max(percent, 0), 100);
		props.setProgressPercentage(percent);
	}, []);

	const handleMouseDown = useCallback((event: React.MouseEvent<HTMLDivElement>) => {
		event.preventDefault();
		handleMouseMove(event.nativeEvent);
		window.addEventListener('mousemove', handleMouseMove);
	}, [handleMouseMove]);

	const handleMouseUp = useCallback(() => {
		window.removeEventListener('mousemove', handleMouseMove);
	}, [handleMouseMove]);

	useEffect(() => {
		window.addEventListener('mouseup', handleMouseUp);
		return () => {
			window.removeEventListener('mouseup', handleMouseUp);
			window.removeEventListener('mousemove', handleMouseMove);
		};
	}, [handleMouseUp, handleMouseMove]);

	return (
		<ProgressContainer
			ref={containerRef}
			onMouseDown={handleMouseDown}
		>
			<ProgressBarStyled width={progressPercentage}/>
			<DraggableContainer left={progressPercentage}>
				<Circle/>
			</DraggableContainer>
		</ProgressContainer>
	);
};

export default ProgressBar;
