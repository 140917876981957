import {em_base_multiplier, rem_base_multiplier} from "../base";

export const getRem = (pixels: number, fontBase = rem_base_multiplier) => {
	return `${pixels / fontBase}rem`;
}

export const getEm = (pixels: number, fontBase = em_base_multiplier) => {
	return `${pixels / fontBase}em`;
}

export const getMinWidthMedia = (width: number) => {
	return `@media screen and (min-width: ${getEm(width)})`;
}

export const getMaxWidthMedia = (width: number) => {
	return `@media screen and (max-width: ${getEm(width)})`;
}
