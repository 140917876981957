import API from '../../api';
import {IUserProfileProps, TUserProfileResponse} from "./userProfile.int";

export const APIUser = () => {
	const path = `sl/user`;

	const getUserProfile = async (): Promise<IUserProfileProps | undefined> => {
		const url = `${path}/profile`;

		try {
			const {data: response}: TUserProfileResponse = await API.get(url);

			if (!response.success) {
				return Promise.reject(new Error('Response not successful'));
			}

			return response.data;
		} catch (err) {
			return Promise.reject(err);
		}
	};


	return {
		getUserProfile,
	};
};
