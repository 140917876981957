import styled, {css} from "styled-components";
import {Body_Style, H3_Style} from "../../styles/font/styles/font.styles";

import ribbonImage from '../../assets/files/ribbon.png';
import examIcon from '../../assets/files/exam_icon.png';
import compromise_letter_icon from '../../assets/files/compromise_letter_icon.png';
import principle_letter_icon from '../../assets/files/principle_letter_icon.png';
import trasparency_guide_icon from '../../assets/files/trasparency_guide_icon.png';
import {IButtonStyleProps} from "../../components/inputs/button/button.ints";
import {getRem} from "../../styles/functions/functions";
import {colors} from "../../styles/colors/colors";
import {media} from "../../styles/media/media";
import Button from "../../components/inputs/button/button";
import React, {useContext, useEffect, useState} from "react";
import Breadcrumb, {ITab} from "../../componentsBackup/breadcrumb/breadcrumb";
import {APIManualRecognition} from "../../api/manual/userManualStatus/userManualStatus";
import {useParams} from "react-router-dom";
import {IUserManualRecognitionStatus} from "../../api/manual/userManualStatus/userManualStatus.int";
import AnimatedAvatar from "../../componentsBackup/animatedAvatar/animatedAvatar";
import {AvatarMediaContext} from "../../context/globalContainer/avatarMediaContext";
import {IAvatarMediaContext} from "../../context/globalContainer/globalContainerContext.int";
import {LoaderContext} from "../../componentsBackup/loader/loader";
import {ILoaderContext} from "../../componentsBackup/loader/loader.int";
import APIAnalytics from "../../api/manual/analytics/analytics";

const RenownCompromisePage = () => {
	const {manualUrlName} = useParams<{ manualUrlName?: string }>();
	const loaderContext = useContext(LoaderContext) as ILoaderContext;
	const {getRecognitionStatus} = APIManualRecognition();
	const [recognitionStatus, setRecognitionStatus] = useState<IUserManualRecognitionStatus>();
	const context = useContext(AvatarMediaContext) as IAvatarMediaContext;

	const fetchRecognitionStatus = async () => {
		if (!manualUrlName) return;
		const recognitionStatus = await getRecognitionStatus(manualUrlName);

		if (recognitionStatus) {
			console.log(recognitionStatus)
			setRecognitionStatus(recognitionStatus);
			setPlayAvatar(recognitionStatus.playAvatar);
		}
	};

	useEffect(() => {
		fetchRecognitionStatus();
	}, []);

	const [enableExamButton, setEnableExamButton] = useState(false);
	const [enableCompromiseLetter, setEnableCompromiseLetter] = useState(false);
	const [recognitionCompleted, setRecognitionCompleted] = useState(false);
	const [diplomaUrl, setDiplomaUrl] = useState<string | null>(null);
	const [compromiseLetterUrl, setCompromiseLetterUrl] = useState<string | null>(null);

	const finalExamButtonText = enableCompromiseLetter ? 'Ver resultados' : 'Comenzar';
	const finalExamButtonColor = enableCompromiseLetter ? 'green' : undefined;

	const compromiseLetterButtonText = recognitionCompleted ? 'Descargar' : 'Consultar';
	const compromiseLetterButtonColor = recognitionCompleted ? 'green' : undefined;

	const [playAvatar, setPlayAvatar] = useState<boolean>(false);

	useEffect(() => {
		if (recognitionStatus) {
			setEnableExamButton(recognitionStatus.enableFinalQuiz);
			setEnableCompromiseLetter(recognitionStatus.enableFinalQuiz && recognitionStatus.enableCompromiseLetter);

			if (recognitionStatus.diplomaUrl && recognitionStatus.compromiseLetterUrl) {
				setDiplomaUrl(recognitionStatus.diplomaUrl);
				setCompromiseLetterUrl(recognitionStatus.compromiseLetterUrl);
				setRecognitionCompleted(true);
			}
		}
	}, [recognitionStatus]);

	const redirectToComplianceMap = () => {
		window.open("/manuales/manual-didactico-de-anticorrupcion/reconocimiento-y-compromiso/mapa-de-cumplimiento", "_blank");
	}

	const redirectToFinalExam = () => {
		window.location.href = `/manuales/manual-didactico-de-anticorrupcion/examen-final`;
	}

	const redirectToPrinciplesLetterPdf = () => {
		window.open("https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/files/carta-de-principios-para-las-osc.pdf", "_blank");
		if (manualUrlName) {
			APIAnalytics(loaderContext).downloadPrinciplesLetter(manualUrlName);
		}
	}

	const compromiseLetterButtonHandler = () => {
		if (!recognitionCompleted) {
			window.location.href = `/manuales/manual-didactico-de-anticorrupcion/carta-compromiso`;
		} else if (compromiseLetterUrl) {
			window.open(compromiseLetterUrl, "_blank");
		}
	}

	const openDiplomaInNewTab = () => {
		if (diplomaUrl !== null) {
			window.open(diplomaUrl, "_blank");
			if (manualUrlName) {
				APIAnalytics(loaderContext).downloadDiploma(manualUrlName);
			}
		}
	}

	const tabs: ITab[] = [
		{
			name: "Reconocimiento y compromiso",
			href: `#`
		}
	]

	const videoSrc = !recognitionCompleted ? "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/reconocimiento/reconocimiento-init.mp4"
		: "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/reconocimiento/reconocimiento-end.mp4";

	const imageSrc = "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/reconocimiento/reconocimiento.gif";

	useEffect(() => {
		context.showMediaControllerHandler();
	}, [])

	return (
		<>
			<Breadcrumb tabs={tabs}/>

			<MainContainer>
				<TopSection>
					<TopSectionContent>
						<AvatarContainer>
							<AnimatedAvatar
								autoPlay={playAvatar}
								videoSrc={videoSrc}
								imageSrc={imageSrc}
							/>
						</AvatarContainer>
						<TitleSection>
							<Title>Reconocimiento y compromiso</Title>
							<TitleText>¡Para obtener tu reconocimiento deberás realizar el examen final y demostrar tu
								compromiso en apoyar el movimiento de transparencia en anticorrupción.</TitleText>
						</TitleSection>
						<DiplomaButtonContainer>
							<RibbonImage src={ribbonImage} alt={'ribbon_image'}/>
							<ButtonStyle
								color={undefined}
								disabled={!recognitionCompleted}
								appearance={'primary'}
								size={'small'}
								onClick={openDiplomaInNewTab}>
								Descargar Reconocimiento
							</ButtonStyle>
						</DiplomaButtonContainer>
					</TopSectionContent>
				</TopSection>

				<CardsGrid>
					<Card>
						<CardImage src={examIcon} alt={'exam_image'}/>
						<CardContent>
							<CardTitle>
								Examen
							</CardTitle>
							<CardText>
								Corrobora tus conocimientos adquiridos
							</CardText>
							<Button
								color={finalExamButtonColor}
								disabled={!enableExamButton}
								size={'small'}
								onClick={redirectToFinalExam}>
								{finalExamButtonText}
							</Button>
						</CardContent>
					</Card>

					<Card>
						<CardImage src={compromise_letter_icon} alt={'exam_image'}/>

						<CardContent>
							<CardTitle>
								Carta compromiso
							</CardTitle>
							<CardText>
								Demuestra tu compromiso a seguir los puntos clave para ser un aliado de la anticorrupción
							</CardText>
							<Button
								color={compromiseLetterButtonColor}
								disabled={!enableCompromiseLetter}
								size={'small'}
								onClick={compromiseLetterButtonHandler}>
								{compromiseLetterButtonText}
							</Button>
						</CardContent>
					</Card>

					<Card>
						<CardImage src={principle_letter_icon} alt={'exam_image'}/>
						<CardContent>
							<CardTitle>
								Carta de principios
							</CardTitle>
							<CardText>
								Descarga y conoce la Carta de Principios que Appleseed ofrece para tu organización.
							</CardText>
							<Button
								disabled={!recognitionCompleted}
								size={'small'}
								onClick={redirectToPrinciplesLetterPdf}>
								Consultar
							</Button>
						</CardContent>
					</Card>

					<Card>
						<CardImage src={trasparency_guide_icon} alt={'exam_image'}/>
						<CardContent>
							<CardTitle>
								Mapa de cumplimiento
							</CardTitle>
							<CardText>
								Descarga y conoce los puntos que le permitirán a tu organización mostrar transparencia
							</CardText>
							<Button
								disabled={!recognitionCompleted}
								size={'small'}
								onClick={redirectToComplianceMap}>
								Ver
							</Button>
						</CardContent>
					</Card>
				</CardsGrid>
			</MainContainer>
		</>
	)
}

export default RenownCompromisePage;

export const MainContainer = styled.div`
  padding: 58px 75px 96px 75px;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;

  ${media.max.mobile} {
    padding: 36px 24px 96px 24px;
  }
`;

export const TopSection = styled.div`
  display: flex;
  margin-bottom: 68px;
`;

export const TopSectionContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;

  ${media.max.tablet} {
    flex-direction: column;
    gap: 16px;
  }
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 524px;
  width: 100%;

  ${media.max.tablet} {
    margin: 0 auto;
  }
`;

export const Title = styled(H3_Style)`
  font-weight: 700;
  margin-bottom: 8px;
  text-align: center;
`;

export const TitleText = styled.p`
  font-size: 14px;
  text-align: center;
`;

export const DiplomaButtonContainer = styled.div`
  position: relative;

  ${media.max.tablet} {
    margin: 0 auto;
  }
`;

export const RibbonImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  width: 46px;
  height: auto;
`;

export const ButtonStyle = styled.button<IButtonStyleProps>`
  position: relative;
  width: fit-content;
  height: auto;
  border-radius: 100px;
  transform: scale(1);
  cursor: pointer;

  /* font styles */
  font-weight: 600;
  font-size: ${getRem(16)};
  line-height: 25px;
  color: ${colors.white};

  /* remove button defaults */
  border: none;
  box-shadow: none;

  ${p => p.size === 'small' && css`
    padding: 11px 11px 11px 56px;
    min-width: 200px;
  `};

  ${p => p.size === 'large' && css`
    padding: 16px;
    min-width: 250px;
  `};

  ${p => (p.appearance === 'primary' && !p.disabled) && css`
    background: ${colors.gradient.purple};

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${colors.gradient.darkPurple};
      opacity: 0;
      transition: opacity 0.4s;
      z-index: -1;
      border: none;
      border-radius: 100px;
    }
  `};

  ${p => (p.appearance === 'primary' && p.color === 'green' && !p.disabled) && css`
    background: ${colors.success};

    &:before {
      background: ${colors.success};
    }
  `};

  ${p => (p.appearance === 'secondary' && !p.disabled) && css`
    color: ${colors.purple};
    border: 1px solid ${colors.purple};
    background-color: ${colors.white};
    transition: background-color 0.4s;

    ${p.size === 'large' && css`
      padding: 15px;
    `};

    ${p.size === 'small' && css`
      padding: 7px;
    `};

    &:hover, &:focus {
      color: ${colors.white};
      border: 1px solid ${colors.darkPurple};
      background-color: ${colors.darkPurple};
    }
  `};

  ${p => p.disabled && css`
    cursor: unset;
    background: ${colors.gray};
    border-color: ${colors.gray};
    color: ${colors.text2};
  `};

  &:hover:before {
    opacity: 1;
  }
`;

export const CardsGrid = styled.div`
  display: grid;
  gap: 22px;
  grid-template-columns: repeat(4, 1fr);

  @media screen and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
  }

  ${media.max.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const Card = styled.div`
  background-color: ${colors.white};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  ${media.max.mobile} {
    flex-direction: row;
  }
`;

export const CardImage = styled.img`
  margin: 0 auto 24px auto;
  max-width: 153px;
  width: 100%;
  height: auto;

  ${media.max.mobile} {
    max-width: 64px;
    margin: 0 16px 0 0;
  }
`;

export const CardTitle = styled.p`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
`;

export const CardText = styled(Body_Style)`
  text-align: center;
  min-height: 60px;
  margin-bottom: 24px;
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const AvatarContainer = styled.div`
  max-width: 230px;
  width: 100%;
  background: ${colors.gradient.green};
  border-radius: 50%;
`;
