import styled from "styled-components";
import {colors} from "../../styles/colors/colors";
import {H3_Style} from "../../styles/font/styles/font.styles";

import logoImg1 from '../../assets/files/greetings/g-logo-1.png';
import logoImg2 from '../../assets/files/greetings/g-logo-2.png';
import logoImg3 from '../../assets/files/greetings/g-logo-3.png';
import logoImg4 from '../../assets/files/greetings/g-logo-4.png';
import logoImg5 from '../../assets/files/greetings/g-logo-5.png';
import logoImg6 from '../../assets/files/greetings/g-logo-6.png';
import logoImg7 from '../../assets/files/greetings/g-logo-7.png';
import {media} from "../../styles/media/media";

const GreetingsPage = () => {
	return (
		<InnerContainer>
			<ContentContainer>
				<Title>Agradecimientos</Title>
				<TextBlockContainer>
					<TextBlock>
						Agradecemos a nuestros aliados por dedicar su tiempo y experiencia en revisar y compartir sus
						observaciones sobre el manual de mejores prácticas de transparencia y anticorrupción para las
						OSC. Reconocemos plenamente que las alianzas son fundamentales para alcanzar nuestros objetivos,
						y ustedes son parte esencial de todo lo que hacemos. Juntos, fortalecemos el impacto de nuestro
						trabajo y avanzamos hacia un futuro más transparente y libre de corrupción.
					</TextBlock>
					<TextBlock>
						Agradecimientos a la consultora en Legal Tech, Claudia Félix, y al equipo de desarrollo,
						Francisco C., Víctor C. y María A., quienes hicieron posible la creación de esta plataforma
						innovadora.
					</TextBlock>
				</TextBlockContainer>
				<LogoSection>
					<LogoImage src={logoImg1} alt={'logo_image'} width={84}/>
					<LogoImage src={logoImg2} alt={'logo_image'} width={110}/>
					<LogoImage src={logoImg3} alt={'logo_image'} width={129}/>
					<LogoImage src={logoImg4} alt={'logo_image'} width={80}/>
					<LogoImage src={logoImg5} alt={'logo_image'} width={101}/>
					<LogoImage src={logoImg6} alt={'logo_image'} width={138}/>
					<LogoImage src={logoImg7} alt={'logo_image'} width={106}/>
				</LogoSection>
			</ContentContainer>
		</InnerContainer>
	)
}

export default GreetingsPage;

const LogoSection = styled.section`
  display: grid;
  grid-template-columns: repeat(7, auto); // Asume que hay dos elementos, ajusta según necesites
  column-gap: 32px;
  row-gap: 20px;
  margin: 92px 0;
  align-items: center;
  justify-content: center; // This will center the grid items horizontally
  align-content: center;
  
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(5, auto);
    margin: 70px 0;
  } 
  
  ${media.max.tablet} {
    grid-template-columns: repeat(4, auto);
    margin: 40px 0;
  }

  @media screen and (max-width: 589px) {
    grid-template-columns: repeat(3, auto);
  }
  
  ${media.max.tablet} {
    grid-template-columns: repeat(2, auto);
  }
`;

const LogoContainer = styled.div`
  width: 138px;
  height: 66px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LogoImage = styled.img<{ width: number }>`
  width: ${p => p.width}px;
  height: auto;
  align-self: center;
  justify-self: center;
`;

const InnerContainer = styled.div`
  flex-grow: 1;
  background-color: ${colors.lightGray};
`;

const Title = styled(H3_Style)`
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 28px;
`;

const TextBlock = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 24px 0 24px;
  max-width: 720px;
  margin: 0 auto;
`;

const TextBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;