import axios, {AxiosInstance} from 'axios';
import {ApiConstants} from './constraints';
import {apiEventEmitter} from "./eventEmitter";

const getCookie = (name: string): string | null => {
	let cookie = null;

	try {
		const regex = new RegExp(`(?:^|;\\s*)${encodeURIComponent(name)}=([^;]+)`);
		const match = document.cookie.match(regex);
		if (match && match.length > 1) cookie = decodeURIComponent(match[1]);
	} catch (error) {
		console.error("Error retrieving the cookie: ", error);
	}

	return cookie;
};

const removeCookie = (name: string) => {
	document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
};

const removeAuthTokenFromCookie = () => {
	removeCookie("token_appleseed_aut");
}

export const redirectToLoginScreen = () => {
	removeAuthTokenFromCookie();
	const currentUrl = encodeURIComponent(window.location.href);
	const redirectToUrl = `${ApiConstants.LOGIN_SCREEN}?redirectTo=${currentUrl}`;
	window.location.replace(redirectToUrl);
};

const API = (): AxiosInstance => {
	const token = getCookie(ApiConstants.TOKEN_NAME);

	if (token === null) {
		redirectToLoginScreen();
	}

	const configHeader = {
		headers: {
			Authorization: token,
		},
	};

	const instance = axios.create({
		baseURL: `${ApiConstants.DOMAIN}`,
		...configHeader,
	});

	instance.interceptors.request.use(config => {
		apiEventEmitter.emit('request');
		return config;
	});

	instance.interceptors.response.use(
		response => {
			apiEventEmitter.emit('response');
			return response;
		},
		error => {
			apiEventEmitter.emit('response');
			if (error.response && (error.response.status === 401 || error.response.status === 500)) {
				redirectToLoginScreen();
			}
			return Promise.reject(error);
		}
	);

	return instance;
};

export default API();
