import styled, {css} from "styled-components";
import {colors} from "../../styles/colors/colors";
import {media} from "../../styles/media/media";
import {Body_Style, Tag_Style} from "../../styles/font/styles/font.styles";

export const ChapterCard_Component = styled.div<{
	highlight?: boolean;
	highlightMobile?: boolean;
}>`
  width: 100%;
  background-color: ${colors.white};
  border-radius: 10px;
  padding: 24px;
  display: flex;
  cursor: pointer;
  position: relative;
  height: 100%;

  ${media.max.tablet} {
    padding: 16px;
  }

  ${p => p.highlight && css`
    z-index: 200;
    pointer-events: none;
  `}

  ${p => !p.highlightMobile && css`
    ${media.max.mobile} {
      z-index: unset;
    }
  `}
`;

export const Image = styled.img`
  border-radius: 50%;
  width: 74px;
  height: 74px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 16px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 2px;
`;

export const Summary = styled(Body_Style)`
  color: ${colors.text2};
  margin-bottom: 4px;
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const TimeText = styled(Tag_Style)`
  font-weight: 600;
  color: ${colors.text2};
  margin-left: 4px;
`;