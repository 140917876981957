import {createGlobalStyle} from "styled-components";
import {font_size_percentage} from "../base";
import {colors} from "../colors/colors";

export const GlobalStyles = createGlobalStyle`
  html {
    font-size: ${font_size_percentage}%;
  }

  h1, h2, h3, h4, p, a, span, button, li {
    font-family: 'Inter', sans-serif;
  }

  h1, h2, h3, h4, p, a, span, li {
    color: ${colors.text}
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
`;