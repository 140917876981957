import React from 'react';
import {
	FooterComponent,
	FooterComponent_ContentContainer,
	FooterComponent_Copyright,
	FooterLink
} from './footer.styles';

import {Link} from "react-router-dom";

const Footer: React.FC = () => {
	const currentYear = new Date().getFullYear();
	const copyright = `@ ${currentYear}. Fundación Appleseed México, AC. Todos los Derechos Reservados`;

	return (
		<FooterComponent>
			<FooterComponent_ContentContainer>
				<FooterLink as={Link} to="/agradecimientos" target={"_blank"}>Agradecimientos</FooterLink>
				<FooterLink as={Link} to="aviso-de-privacidad" target={"_blank"}>Aviso de Privacidad</FooterLink>
				<FooterLink as={Link} to="/terminos-y-condiciones" target={"_blank"}>Terminos y condiciones</FooterLink>
				<FooterComponent_Copyright>{copyright}</FooterComponent_Copyright>
			</FooterComponent_ContentContainer>
		</FooterComponent>
	);
};

export default Footer;