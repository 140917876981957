import React from 'react';
import {colors} from "../../../styles/colors/colors";

interface ICheckIconProps extends React.SVGProps<SVGSVGElement> {
	color?: string;
	checked?: boolean;
	error?: boolean;
}

const CheckIcon = ({color = colors.gray, ...props}: ICheckIconProps) => {
	const backgroundColor = props.error ? colors.error : props.checked ? colors.success : color;

	return (
		<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="24" height="24" rx="12" fill={backgroundColor}/>

			{props.error ? (
				<>
					<rect x="8.0166" y="18.0931" width="2.02386" height="13.9835" rx="1.01193"
							transform="rotate(-135.057 8.0166 18.0931)" fill="white"/>
					<rect x="7.00488" y="8.19696" width="2.02386" height="13.9835" rx="1.01193"
							transform="rotate(-45 7.00488 8.19696)" fill="white"/>
				</>
			) : (
				<path
					d="M18.6937 7.3172C19.0837 7.70821 19.083 8.34137 18.692 8.73142L10.7191 16.6847C10.3295 17.0733 9.69912 17.0741 9.30849 16.6866L5.30996 12.7193C4.9179 12.3303 4.91542 11.6972 5.3044 11.3051C5.69339 10.9131 6.32655 10.9106 6.71861 11.2996L10.0103 14.5654L17.2795 7.31546C17.6705 6.92542 18.3037 6.9262 18.6937 7.3172Z"
					fill="white"/>
			)}
		</svg>
	);
}

export default CheckIcon;
