import React, {useContext} from 'react';
import rewindActiveImage from '../../assets/files/temporal/rewindInactiveImage.png';
import rewindInactiveImage from '../../assets/files/temporal/rewindActiveImage.png';
import soundOnImage from '../../assets/files/temporal/soundActive.png';
import soundOffImage from '../../assets/files/temporal/soundInactive.png';
import {AudioButton, AvatarAudioContainer} from "./mediaController.styles";
import {AvatarMediaContext} from "../../context/globalContainer/avatarMediaContext";
import {IAvatarMediaContext} from "../../context/globalContainer/globalContainerContext.int";

const MediaController = () => {
	const context = useContext(AvatarMediaContext) as IAvatarMediaContext;

	const toggleVolumeHandler = () => {
		if (context) {
			context.setIsMediaMuted(!context.isMediaMuted);
		}
	};

	const rewindHandler = () => {
		if (!context.isMediaPlaying) {
			context.setIsMediaPlaying(true);
		}
	}

	return (
		<AvatarAudioContainer show={context.showMediaController}>
			<AudioButton onClick={toggleVolumeHandler}>
				<img src={context.isMediaMuted ? soundOffImage : soundOnImage} alt="button_image"/>
			</AudioButton>
			<AudioButton onClick={rewindHandler}>
				<img src={context.isMediaPlaying ? rewindInactiveImage : rewindActiveImage} alt="button_image"/>
			</AudioButton>
		</AvatarAudioContainer>
	);
};

export default MediaController;
