import React, {useContext, useEffect, useState} from "react";
import VideoPlayer from "../temporal/components/chapterModuleVideo";
import styled from "styled-components";
import {media} from "../../styles/media/media";
import ChapterModuleKeyPoints from "../temporal/components/chapterModuleKeyPoints";
import ChapterModuleUseCase, {ChapterHeader, ChapterHeaderText} from "../temporal/components/chapterModuleUseCase";
import ChapterFinished from "../temporal/components/chapterFinished";
import {APIManualChapter} from "../../api/manual/chapter/manualChapter";
import {useParams} from "react-router-dom";
import {IManualChapterDTO} from "../../api/manual/chapter/manualChapter.int";
import Breadcrumb, {ITab} from "../../componentsBackup/breadcrumb/breadcrumb";
import {AvatarMediaContext} from "../../context/globalContainer/avatarMediaContext";
import {IAvatarMediaContext} from "../../context/globalContainer/globalContainerContext.int";

const ChapterPage = () => {
	const {manualUrlName} = useParams<{ manualUrlName?: string }>();
	const {chapterUrlName} = useParams<{ chapterUrlName?: string }>();
	const {getManualChapter, completeChapter} = APIManualChapter();
	const [chapter, setChapter] = useState<IManualChapterDTO | null>(null);
	const [chapterCompleted, setChapterCompleted] = useState(false);
	const context = useContext(AvatarMediaContext) as IAvatarMediaContext;

	const fetchChapter = async () => {
		if (!chapterUrlName || !manualUrlName) return;
		const chapter = await getManualChapter(manualUrlName, chapterUrlName);

		if (chapter) {
			setChapter(chapter);
		}
	};

	useEffect(() => {
		fetchChapter();
		context.hideMediaControllerHandler();
	}, []);

	const [slideIndex, setSlideIndex] = useState(0);
	const [chapterHeader, setChapterHeader] = useState('');

	const setModuleIndex = (index: number) => {
		setSlideIndex(index);
		window.scroll(0, 0);
	}

	useEffect(() => {
		if (slideIndex === 0) {
			setChapterHeader('Video');
		} else if (slideIndex === 1) {
			setChapterHeader('Puntos importantes');
		} else if (slideIndex === 2) {
			setChapterHeader('Caso práctico');
		} else {
			setChapterHeader('');
		}
	}, [slideIndex]);

	useEffect(() => {
		if (chapter !== null && chapterUrlName && manualUrlName && slideIndex === chapter?.modules?.length && !chapterCompleted) {
			completeChapter(manualUrlName, chapterUrlName);
			setChapterCompleted(true);
		}
	}, [slideIndex]);

	const tabs: ITab[] = [
		{
			name: chapter?.name ? chapter.name : '',
			href: "#"
		}
	]

	const isChapterFinished = chapter !== null && slideIndex >= (chapter?.modules?.length ?? 0);

	return (
		<>
			<Breadcrumb tabs={tabs}/>

			{chapterHeader && chapterHeader.length > 0 && !isChapterFinished && (
				<ChapterHeader>
					<ChapterHeaderText>
						{chapterHeader}
					</ChapterHeaderText>
				</ChapterHeader>
			)}

			{chapter?.modules.map((module, index) => {
				if (index !== slideIndex) {
					return null;
				}
				switch (module.moduleType) {
					case 'video':
						return <VideoPlayer
							key={index}
							{...module}
							setModuleIndex={() => setModuleIndex(index + 1)}/>;
					case 'keyPoints':
						return <ChapterModuleKeyPoints
							key={index}
							{...module}
							setPreviousModule={() => setModuleIndex(index - 1)}
							setNextModule={() => setModuleIndex(index + 1)}/>;
					case 'useCase':
						return <ChapterModuleUseCase
							key={index}
							{...module}
							setPreviousModule={() => setModuleIndex(index - 1)}
							setNextModule={() => setModuleIndex(index + 1)}/>;
					default:
						return null;
				}
			})}
			
			{isChapterFinished && (
				<ChapterFinished
					isLastChapter={chapter.lastChapter}
					chapterName={chapter.name}
					setPreviousModule={() => setModuleIndex(chapter.modules.length - 1)}
				/>
			)}
		</>
	);

};

export default ChapterPage;

export const ButtonsContainer = styled.div`
  background: transparent;
  gap: 24px;
  display: flex;
  justify-content: flex-end;

  ${media.max.mobile} {
    flex-direction: column;
    align-items: center;
  }
`;
