import styled from "styled-components";
import React from "react";
import {
  bodyFontMixin,
  h1FontMixin,
  h2FontMixin,
  h3FontMixin,
  h4FontMixin,
  tagFontMixin
} from "../../mixins/font/font.mixin";

export const H1_Style = styled.h1`
  ${h1FontMixin.desktop}
  ${h1FontMixin.tablet}
  ${h1FontMixin.mobile}
`;

export const H2_Style = styled.h2`
  ${h2FontMixin.desktop}
  ${h2FontMixin.tablet}
  ${h2FontMixin.mobile}
`;

export const H3_Style = styled.h3`
  ${h3FontMixin.desktop}
  ${h3FontMixin.tablet}
  ${h3FontMixin.mobile}
`;

export const H4_Style = styled.h4`
  ${h4FontMixin.desktop}
  ${h4FontMixin.tablet}
  ${h4FontMixin.mobile}
`;

export const Body_Style = styled.p`
  ${bodyFontMixin.desktop}
  ${bodyFontMixin.tablet}
  ${bodyFontMixin.mobile}
`;

export const Tag_Style = styled.span`
  ${tagFontMixin.desktop}
  ${tagFontMixin.tablet}
  ${tagFontMixin.mobile}
`;
