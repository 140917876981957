import {useLocation} from "react-router-dom";
import {
	BottomMenuButton,
	BottomMenuContainer,
	ButtonContainer,
	InvisibleComponent,
	MobileFixedOptionsContainer
} from "./mobileDashboardOptions.styles";
import React, {useState} from "react";

const MobileFixedOptions = () => {
	const location = useLocation();

	const determineInitialSelectedButton = () => {
		const path = location.pathname;
		if (new RegExp("^/manuales/[^/]+/autodiagnostico$").test(path)) {
			return 1;
		}
		if (new RegExp("^/manuales/[^/]+$").test(path)) {
			return 2;
		}
		if (new RegExp("^/manuales/[^/]+/reconocimiento-y-compromiso$").test(path)) {
			return 3;
		}
		return 2; // default value, you can change this if necessary
	};

	const [selectedButton, setSelectedButton] = useState(determineInitialSelectedButton);

	const handleClickAutodiagnostico = () => {
		if (selectedButton !== 1) {
			setSelectedButton(1);
			window.location.href = `/manuales/manual-didactico-de-anticorrupcion/autodiagnostico`;
		}
	};

	const handleClickCapitulos = () => {
		if (selectedButton !== 2) {
			setSelectedButton(2);
			window.location.href = `/manuales/manual-didactico-de-anticorrupcion`;
		}
	};

	const handleClickReconocimiento = () => {
		if (selectedButton !== 3) {
			setSelectedButton(3);
			window.location.href = `/manuales/manual-didactico-de-anticorrupcion/reconocimiento-y-compromiso`;
		}
	};

	return (
		<>
			<InvisibleComponent/>
			<MobileFixedOptionsContainer>
				<BottomMenuContainer>
					<ButtonContainer isSelected={selectedButton === 1} onClick={handleClickAutodiagnostico}>
						<BottomMenuButton>Autodiagnóstico</BottomMenuButton>
					</ButtonContainer>
					<ButtonContainer isSelected={selectedButton === 2} onClick={handleClickCapitulos}>
						<BottomMenuButton>Capítulos</BottomMenuButton>
					</ButtonContainer>
					<ButtonContainer isSelected={selectedButton === 3} onClick={handleClickReconocimiento}>
						<BottomMenuButton>Reconocimiento y compromiso</BottomMenuButton>
					</ButtonContainer>
				</BottomMenuContainer>
			</MobileFixedOptionsContainer>
		</>
	);
};

export default MobileFixedOptions;
