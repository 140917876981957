import React from 'react';
import {colors} from "../../../styles/colors/colors";

interface ICheckIconProps extends React.SVGProps<SVGSVGElement> {
	color?: string;
	checked?: boolean;
}

const TwoArrowsDownSVG = ({color = colors.white}: ICheckIconProps) => {
	return (
		<svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0.788175 1.24237L7.87394 8.45449L14.6973 1.24237" stroke={color} strokeWidth="1.54545"
					strokeLinecap="round"/>
			<path d="M0.788174 8.96967L7.87394 16.1818L14.6973 8.96967" stroke={color} strokeWidth="1.54545"
					strokeLinecap="round"/>
		</svg>
	);
};

export default TwoArrowsDownSVG;
