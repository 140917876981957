import React, {useEffect, useRef, useState} from 'react';
import styled from 'styled-components';
import {colors} from '../../../styles/colors/colors';
import { DateTime } from 'luxon';

import videoPlayIcon from '../../../assets/files/video_play.png';
import videoStopIcon from '../../../assets/files/video_stop.png';
import videoReturnIcon from '../../../assets/files/video_return.png';
import videoAdvanceIcon from '../../../assets/files/video_advance.png';
import videoExpandIcon from '../../../assets/files/video_expand.png';
import videoVolumeOnIcon from '../../../assets/files/video_volume_on.png';
import videoVolumeOffIcon from '../../../assets/files/video_volume_on.png';
import Button from "../../../components/inputs/button/button";
import {ButtonsContainer} from "../../chapter/chapterPage";
import {media} from "../../../styles/media/media";
import ProgressBar from "./videoProgressBar";
import {IAnalyticsChapterModule, IVideoModule} from "../../../api/manual/chapter/manualChapter.int";
import {APIManualChapter} from "../../../api/manual/chapter/manualChapter";

interface IVideoPlayerProps extends IVideoModule {
	id: number;
	setModuleIndex: () => void;
}

const VideoPlayer = (props: IVideoPlayerProps) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const [isPlaying, setPlaying] = useState(false);
	const [isExpanded, setExpanded] = useState(false);
	const [showControls, setShowControls] = useState(false);
	const [currentTime, setCurrentTime] = useState(0);
	const [duration, setDuration] = useState(0);
	const [videoPercentage, setVideoPercentage] = useState(0);
	const [volumePercentage, setVolumePercentage] = useState(70);
	const [muted, setMuted] = useState(false);

	const {completeChapterModule} = APIManualChapter();
	const [startDate, setStartDate] = useState<string | null>("");

	useEffect(() => {
		const percentage = duration !== 0 ? (currentTime / duration) * 100 : 0;
		setVideoPercentage(percentage);
	}, [currentTime]);

	const playPauseHandler = () => {
		const video = videoRef.current;
		if (video) {
			if (isPlaying) {
				video.pause();
			} else {
				video.play();
			}
			setPlaying(!isPlaying);
		}
	};

	const backwardSelectHandler = () => {
		const video = videoRef.current;
		if (video) {
			video.currentTime -= 10;
		}
	};

	const forwardSelectHandler = () => {
		const video = videoRef.current;
		if (video) {
			video.currentTime += 10;
		}
	};

	const handleExpand = () => {
		const video = videoRef.current;
		if (video) {
			video.requestFullscreen();
		}
	};

	const videoProgressHandler = (percentage: number) => {
		const video = videoRef.current;
		if (video) {
			const newTime = (video.duration / 100) * percentage;
			video.currentTime = newTime;
			setCurrentTime(newTime);
		}
	};

	const handleVolumeToggle = () => {
		const video = videoRef.current;
		if (video) {
			video.muted = !video.muted;
			setMuted(video.muted);
		}
	};

	const handleVolumeChange = (percentage: number) => {
		const video = videoRef.current;
		if (video) {
			const newVolume = percentage / 100;
			video.volume = newVolume;
			setVolumePercentage(percentage);
			if (newVolume === 0) {
				video.muted = true;
				setMuted(true);
			} else {
				video.muted = false;
				setMuted(false);
			}
		}
	};

	useEffect(() => {
		const video = videoRef.current;

		if (video) {
			const updateTime = () => {
				const newTime = video.currentTime;
				setCurrentTime(newTime);
				const newPercentage = (newTime / video.duration) * 100; // calculate the percentage
				setVideoPercentage(newPercentage);
			};

			video.addEventListener('timeupdate', updateTime);
			video.addEventListener('loadedmetadata', () => setDuration(video.duration));
			return () => {
				video.removeEventListener('timeupdate', updateTime);
			};
		}
	}, []);

	const [watchedSeconds, setWatchedSeconds] = useState(new Set());
	const [hasWatched70Percent, setHasWatched70Percent] = useState(false);

	useEffect(() => {
		const video = videoRef.current;
		if (!video) return;

		const checkWatchedSeconds = () => {
			const currentSecond = Math.floor(video.currentTime);
			setWatchedSeconds(prevSet => {
				const newSet = new Set(prevSet);
				newSet.add(currentSecond);
				return newSet;
			});
		};

		// Add an event listener that fires the function every time the 'timeupdate' event triggers
		video.addEventListener('timeupdate', checkWatchedSeconds);

		return () => {
			// Cleanup: remove the event listener when the component unmounts or the dependencies change
			video.removeEventListener('timeupdate', checkWatchedSeconds);
		};
	}, [videoRef.current]);

	useEffect(() => {
		const video = videoRef.current;
		if (video && watchedSeconds.size >= 0.7 * video.duration && !hasWatched70Percent) {
			setHasWatched70Percent(true);
		}
	}, [watchedSeconds, videoRef.current]);

	useEffect(() => {
		const startDate = DateTime.now().setZone('America/Mexico_City').toISO();
		setStartDate(startDate);
	}, []);

	const nextHandler = () => {
		const endDate = DateTime.now().setZone('America/Mexico_City').toISO();

		const body: IAnalyticsChapterModule = {
			startAt: startDate,
			endAt: endDate,
			isCompleted: hasWatched70Percent,
			moduleType: "video"
		}

		completeChapterModule(props.id, body).then(r => props.setModuleIndex());
	}

	return (
		<>
			<GeneralContainer>
				<VideoContainer
					onMouseEnter={() => setShowControls(true)}
					onMouseLeave={() => setShowControls(false)}
					onTouchStart={() => setShowControls(true)}>
					<CustomVideo ref={videoRef} src={props.url}/>

					{showControls && (
						<>
							<CenterControlsContainer>

								<ControlButton onClick={backwardSelectHandler}>
									<SeekBackwardIcon src={videoReturnIcon} alt="retroceder_10s_image"/>
								</ControlButton>

								<ControlButton onClick={playPauseHandler}>
									{isPlaying ? (
										<CenterPauseIcon src={videoStopIcon} alt="pausar_image"/>
									) : (
										<CenterPlayIcon src={videoPlayIcon} alt="detener_image"/>
									)}
								</ControlButton>

								<ControlButton onClick={forwardSelectHandler}>
									<SeekForwardIcon src={videoAdvanceIcon} alt="adelantar_10s_image"/>
								</ControlButton>
							</CenterControlsContainer>

							<BottomControlsContainer>
								<BottomControls>

									<ControlButton onClick={playPauseHandler}>
										<BottomPlayPauseContainer>
											<PlayPauseIcon
												src={isPlaying ? videoStopIcon : videoPlayIcon}
												alt={isPlaying ? 'Pausar' : 'Reproducir'}
											/>
										</BottomPlayPauseContainer>
									</ControlButton>
									<Span>{new Date(currentTime * 1000).toISOString().substr(14, 5)}</Span>

									<ProgressBar
										progressPercentage={videoPercentage}
										setProgressPercentage={videoProgressHandler}
									/>

									<Span>{new Date(duration * 1000).toISOString().substr(14, 5)}</Span>

									<VolumeIcon
										src={muted ? videoVolumeOffIcon : videoVolumeOnIcon}
										alt={muted ? 'Volumen desactivado' : 'Volumen activado'}
									/>

									<VideoAudioProgressBar>
										<ProgressBar
											progressPercentage={volumePercentage}
											setProgressPercentage={handleVolumeChange}
										/>
									</VideoAudioProgressBar>

									<ControlButton onClick={handleExpand}>
										<ExpandIcon src={videoExpandIcon} alt="Expandir"/>
									</ControlButton>
								</BottomControls>
							</BottomControlsContainer>
						</>
					)}
				</VideoContainer>

				<ButtonsContainer>
					<Button
						appearance={"primary"}
						size={"large"}
						onClick={nextHandler}>
						Siguiente
					</Button>
				</ButtonsContainer>
			</GeneralContainer>

		</>

	);
};

const VideoAudioProgressBar = styled.div`
  width: 100%;
  max-width: 60px;

  ${media.max.mobile} {
    max-width: 46px;
  }
`;

const Span = styled.span`
  font-size: 12px;
  color: ${colors.white};
  font-weight: 100;

  ${media.max.mobile} {
    font-size: 10px;
  }
`;


const GeneralContainer = styled.div`
  position: relative;
  max-width: 888px;
  width: 100%;
  margin: 52px auto 24px auto;
  padding: 0 24px;

  ${media.max.tablet} {
    padding: 0 40px;
  }

  ${media.max.mobile} {
    padding: 0 16px;
  }
`;

const VideoContainer = styled.div`
  position: relative;
  background-color: black;
  width: 100%;
  margin-bottom: 24px;

  ${media.max.tablet} {
    margin-bottom: 40px;
  }

  ${media.max.mobile} {
    margin-bottom: 16px;
  }
`;

const CustomVideo = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const BottomControlsContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;

  padding: 0 36px;

  ${media.max.tablet} {
    padding: 0 20px;
  }

  ${media.max.mobile} {
    padding: 0 10px;
  }
`;

const BottomControls = styled.div`
  padding: 16px 32px;
  background-color: rgba(45, 45, 45, 0.65);
  display: flex;
  justify-content: space-between;
  transition: all 0.5s;
  border-radius: 8px;
  align-items: center;
  margin-bottom: 36px;
  gap: 12px;

  ${media.max.tablet} {
    margin-bottom: 20px;
    padding: 16px;
    gap: 10px;
  }

  ${media.max.mobile} {
    margin-bottom: 10px;
    padding: 8px 10px;
    gap: 6px;
  }
`;

const CenterControlsContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 1;


  max-width: 408px;

  ${media.max.tablet} {
    max-width: 340px;
  }

  ${media.max.mobile} {
    max-width: 206px;
  }
`;

const ControlButton = styled.button`
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const TimeBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: white;
`;

const Slider = styled.input`
  flex-grow: 1;
  height: 3px;
  appearance: none;
  background: #fff;
  width: 95%;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s;
    transform-origin: center;
    transform: scale(1) translateX(-50%);
  }
`;

const CenterPauseIcon = styled.img`
  height: 28px;
  width: auto;

  ${media.max.mobile} {
    height: 20px;
  }
`;

const CenterPlayIcon = styled.img`
  height: 52px;
  width: auto;

  ${media.max.mobile} {
    height: 28px;
  }
`;

const PlayPauseIcon = styled.img`
  display: flex;
  height: 100%;
  width: auto;
`;

const ExpandIcon = styled.img`
  width: auto;
  height: 14px;

  ${media.max.mobile} {
    height: 10px;
  }
`;

const SeekBackwardIcon = styled.img`
  width: 40px;
  height: auto;

  ${media.max.mobile} {
    width: 28px;
  }
`;

const SeekForwardIcon = styled.img`
  width: 40px;
  height: auto;

  ${media.max.mobile} {
    width: 28px;
  }
`;

const VolumeButton = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  z-index: 1;
`;

const VolumeIcon = styled.img`
  width: auto;
  height: 14px;

  ${media.max.mobile} {
    height: 10px;
  }
`;

const VolumeSlider = styled.input`
  width: 60px;
  height: 3px;
  appearance: none;
  background: #fff;

  &::-webkit-slider-thumb {
    appearance: none;
    width: 10px;
    height: 10px;
    background: #fff;
    border-radius: 50%;
    cursor: pointer;
    transition: background 0.3s;
    transform-origin: center;
    transform: scale(1) translateX(-50%);
  }
`;

const BottomPlayPauseContainer = styled.div`
  width: 20px;
  height: 20px;

  ${media.max.mobile} {
    width: 10px;
    height: 10px;
  }
`;

const BottomPauseIcon = styled.img`
  width: 20px;
  height: auto;

  ${media.max.mobile} {
    width: 10px;
  }
`;

const BottomPlayIcon = styled.img`
  height: 100%;
  width: auto;
`;

export default VideoPlayer;
