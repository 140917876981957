import styled from "styled-components";
import {media} from "../../styles/media/media";
import {colors} from "../../styles/colors/colors";

export const MobileFixedOptionsContainer = styled.div`
  display: none;
  position: fixed;
  bottom: 0;

  ${media.max.mobile} {
    display: block;
  }
`;

export const InvisibleComponent = styled.div`
  ${media.max.mobile} {
    width: 100%;
    height: 97px;
  }
`;

export const TempBottomMenuContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
`;

export const BottomMenuContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  background-color: ${colors.white};
  width: 100%;
  border-top: 1px solid ${colors.gray};
`;

export const ButtonContainer = styled.div<{ isSelected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  border-top: 2px solid ${p => p.isSelected ? colors.purple : colors.white};
  color: ${p => p.isSelected ? colors.text : colors.text2};
`;

export const BottomMenuButton = styled.a`
  border: none;
  box-shadow: none;
  background-color: transparent;
  padding: 32px 0;
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: inherit;
  height: 100%;
  width: 100%;
`;