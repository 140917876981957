import {
	AccordionContainer,
	AvatarContainer,
	ChaptersContainer,
	ChaptersSection,
	DashboardContainer,
	ManualTitle,
	RepeatButtonsContainer,
	SideMenu,
	SideMenuContainer,
	TitleContainer,
	TitleMainContainer
} from "./dashboard.styles";
import {IManualDashboardProps, ManualChapterCardDTO} from "../../../../api/manual/dashboard/manualDashboard.int";
import Accordion from "../../../../componentsBackup/accordion/accordion";
import Separator from "../../../../componentsBackup/separator/separator";
import ProgressBar from "../../../../componentsBackup/progressBar/progressBar";
import ChapterCard from "../../../../componentsBackup/chapterCard/chapterCard";
import {colors} from "../../../../styles/colors/colors";
import React, {useContext, useEffect, useRef, useState} from "react";
import styled, {css} from "styled-components";
import {ModalContainer, ModalOverlay, OverlayBackground} from "../tutorial/tutorial";
import {AccordionContentComponent} from "../../../../componentsBackup/accordion/accordion.styles";
import {
	ITutorialStep,
	TAccordionContentObject,
	TTutorialStep
} from "../../../../componentsBackup/accordion/accordion.int";
import tutorialAvatarImage from '../../../../assets/files/manual/tutorial/tutorialAvatarStepImage.png';
import closeButton from "../../../../assets/files/manual/tutorial/closeTutorialButton.png";
import {APIManual} from "../../../../api/manual/dashboard/manualDashboard";
import Button from "../../../../components/inputs/button/button";
import AnchorInput from "../../../../components/inputs/anchor/anchorInput";
import {BP_MAX_SM, media} from "../../../../styles/media/media";
import {
	ButtonsSection,
	CloseButton,
	ContentContainer,
	FirstTextSection,
	TooltipContainer2,
	TooltipContentDescription,
	TooltipContentTitle,
	TooltipImage
} from "../../../../componentsBackup/tooltip/tooltip.styles";
import Tooltip from "../../../../componentsBackup/tooltip/tooltip";
import {
	BottomMenuButton,
	BottomMenuContainer,
	ButtonContainer,
	MobileFixedOptionsContainer,
	TempBottomMenuContainer
} from "../../../../componentsBackup/mobileDashboardOptions/mobileDashboardOptions.styles";
import TooltipNoContainer from "../../../../componentsBackup/tooltipNoContainer/tooltipNoContainer";
import {ITooltipContentProps} from "./dashboard.int";
import {TJustify} from "../../../../componentsBackup/tooltip/tooltip.int";
import AnimatedAvatar from "../../../../componentsBackup/animatedAvatar/animatedAvatar";
import AudioPlayerNoContext from "../../../../componentsBackup/audioPlayer/audioPlayerNoContext";
import {AvatarMediaContext} from "../../../../context/globalContainer/avatarMediaContext";
import {IAvatarMediaContext} from "../../../../context/globalContainer/globalContainerContext.int";

const accordionItems: TAccordionContentObject = {
	autodiagnosis: {
		title: 'Autodiagnóstico',
		text: 'Antes de empezar, realiza un autodiagnóstico para descubrir tu nivel de cumplimiento actual.',
		linkText: 'Empezar aquí',
		link: '/manuales/manual-didactico-de-anticorrupcion/autodiagnostico'
	},
	chapters: {
		title: 'Capítulos',
		text: 'Comienza aprender con los capítulos que tienes a la derecha en el orden que prefieras.',
	},
	recognition: {
		title: 'Reconocimiento  y compromiso',
		text: 'Prueba tus conocimientos e incentiva la transparencia de tu Organización',
		linkText: 'click aquí',
		link: '/manuales/manual-didactico-de-anticorrupcion/reconocimiento-y-compromiso'
	}
}

const stepItems: TTutorialStep = {
	first: {
		header: '1/4 Comienza aquí',
		subheading: 'Tienes la oportunidad de hacer un autodiagnóstico y revisar cada uno de los capítulos de este manual.'
	},
	second: {
		header: '2/4 Capítulos',
		subheading: 'Selecciona cada uno de los capítulos para conocer más acerca del tema y los puntos de cumplimiento en la materia.  Puedes consultarlos en el orden que tú prefieras.'
	},
	third: {
		header: '3/4 Reconocimiento y compromiso',
		subheading: 'Completa todos los capítulos para  realizar un examen; conocer nuestra carta de principios; obtener un reconocimiento, e incentivar la transparencia de tu Organización.'
	},
	fourth: {
		header: '4/4 Material de apoyo',
		subheading: 'Recuerda que puedes acceder a los manuales que Appleseed que te ofrece para una mejor comprensión en la materia.'
	}
}

const Dashboard = (props: IManualDashboardProps) => {

	const [tutorialStep, setTutorialStep] = useState(0);
	const [isTutorialCompleted, setIsTutorialCompleted] = useState<boolean>(props.isTutorialCompleted);
	const [showStep1, setShowStep1] = useState(false);
	const [showStep2, setShowStep2] = useState(false);
	const [showStep3, setShowStep3] = useState(false);
	const [showStep4, setShowStep4] = useState(false);
	const [selectedStepItem, setSelectedStepItem] = useState<ITutorialStep>(stepItems.first);
	const [justify, setJustify] = useState<TJustify>('start');

	const modalRef = useRef<HTMLDivElement>(null);

	const {completeTutorial, increaseChapterScreenLoadCount} = APIManual();

	const [showTutorial, setShowTutorial] = useState<boolean>(!props.isTutorialCompleted);

	const context = useContext(AvatarMediaContext) as IAvatarMediaContext;
	const {resetMedia} = context;

	const showIntroduction = () => {
		resetMedia();
		props.setShowIntroduction(true);
		window.scroll(0, 0);
	}

	const showTutorialHandler = () => {
		resetMedia();
		window.scroll(0, 0);
		setShowTutorial(true);
	}

	const nextStep = () => {
		if (tutorialStep < 4) {
			setTutorialStep(tutorialStep + 1);
		} else {
			completeTutorialHandler();
		}
	}

	const prevStep = () => {
		if (tutorialStep > 0) {
			setTutorialStep(tutorialStep - 1);
		}
	}

	const completeTutorialHandler = async () => {
		setIsTutorialCompleted(true);
		setShowTutorial(false);
		setTutorialStep(0);
		window.scroll(0, 0);
		if (!isTutorialCompleted && props.manualUrlName) {
			await completeTutorial(props.manualUrlName);
		}
	}

	useEffect(() => {
		setShowStep1(tutorialStep === 1);
		setShowStep2(tutorialStep === 2);
		setShowStep3(tutorialStep === 3);
		setShowStep4(tutorialStep === 4);

		switch (tutorialStep) {
			case 1:
				setSelectedStepItem(stepItems.first);
				setJustify('start');
				break;
			case 2:
				setSelectedStepItem(stepItems.second);
				setJustify('center');
				break;
			case 3:
				setSelectedStepItem(stepItems.third);
				setJustify("end");
				break;
			case 4:
				setSelectedStepItem(stepItems.fourth);
				break;
		}

	}, [tutorialStep]);

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
				completeTutorialHandler();
			}
		};

		document.addEventListener('mousedown', handleOutsideClick);

		return () => {
			document.removeEventListener('mousedown', handleOutsideClick);
		};
	}, [completeTutorialHandler]);

	const TooltipContent = (props: ITooltipContentProps) => {
		return (
			<TooltipContainer2 stretch={props.stretch}>
				<TooltipImage src={tutorialAvatarImage} alt={'avatar_image'}/>
				<ContentContainer>
					<FirstTextSection>
						<TooltipContentTitle>{selectedStepItem.header}</TooltipContentTitle>
						<TooltipContentDescription>{selectedStepItem.subheading}</TooltipContentDescription>
					</FirstTextSection>
					<ButtonsSection>
						<DirectionButton disabled={tutorialStep < 0} onClick={prevStep}>Anterior</DirectionButton>
						<DirectionButton onClick={nextStep}>{tutorialStep === 4 ? 'Terminar' : 'Siguiente'}</DirectionButton>
					</ButtonsSection>

				</ContentContainer>
				<CloseButton src={closeButton} alt={'close_image'} onClick={completeTutorialHandler}/>
			</TooltipContainer2>
		)
	}

	const calculateProgress = (chapters: ManualChapterCardDTO[]): number => {
		const totalChapters = chapters.length;
		let completedChapters = 0;

		chapters.forEach((chapter) => {
			if (chapter.completed) {
				completedChapters += 1;
			}
		});

		let progress = Math.floor((completedChapters / totalChapters) * 100);
		progress = Math.max(0, Math.min(100, progress));

		return progress;
	}

	const [isMobile, setIsMobile] = useState(false);

	const onResizeHandler = () => {
		const isMobile = window.matchMedia(`(max-width: ${BP_MAX_SM}px)`).matches;
		setIsMobile(isMobile);
	}

	useEffect(() => {
		onResizeHandler();
		document.addEventListener('resize', onResizeHandler);
		console.log({...props})
	}, [])

	const progressPercentage = calculateProgress(props.chapters);

	const [avatarContent, setAvatarContent] = useState({
		videoSrc: "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/menu-principal/menu-principal.mp4",
		imageSrc: "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/menu-principal/menu-principal.gif"
	})

	useEffect(() => {
		if (!props.playInitAvatar) {
			if (progressPercentage < 25) {
				/* 0% */
				setAvatarContent({
					videoSrc: "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/menu-principal/menu-principal-0.mp4",
					imageSrc: "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/menu-principal/menu-principal.gif"
				});
			} else if (progressPercentage < 50) {
				/* 25% */
				setAvatarContent({
					videoSrc: "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/menu-principal/menu-principal-25.mp4",
					imageSrc: "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/menu-principal/menu-principal.gif"
				});
			} else if (progressPercentage < 100) {
				/* 50% */
				setAvatarContent({
					videoSrc: "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/menu-principal/menu-principal-50.mp4",
					imageSrc: "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/menu-principal/menu-principal.gif"
				});
			} else {
				/* 100% */
				setAvatarContent({
					videoSrc: "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/menu-principal/menu-principal-100.mp4",
					imageSrc: "https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/menu-principal/menu-principal.gif"
				});
			}
		}
	}, [])

	const increaseLoadCountHandler = (chapterName: string) => {
		/*
		increaseChapterScreenLoadCount(props.name, chapterName);
		 */
	}

	return (
		<>
			<DashboardContainer>
				<SideMenuContainer>
					<SideMenu>

						<AvatarContainer>
							<AnimatedAvatar
								mute={isMobile}
								autoPlay={!showTutorial && (props.playInitAvatar || props.playAvatar)}
								videoSrc={avatarContent.videoSrc}
								imageSrc={avatarContent.imageSrc}
							/>
						</AvatarContainer>

						<AccordionContainer>
							<Tooltip
								hideOnMobile={true}
								content={
									<>
										<TooltipContent/>

										{showStep1 && !isTutorialCompleted && (
											<AudioPlayerNoContext
												src={"https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/tutorial/tutorial-1-4.mp3"}/>
										)}
									</>
								}
								direction="right"
								show={showStep1}>
								<Accordion
									highlight={showStep1}
									highlightMobile={false}
									title={accordionItems.autodiagnosis.title}
									isChecked={props.isAutodiagnosisCompleted}
									isOpen={true}>
									<AccordionContentComponent {...accordionItems.autodiagnosis}/>
								</Accordion>
							</Tooltip>

							<Separator/>

							<Accordion
								title={accordionItems.chapters.title}
								isChecked={props.isChaptersCompleted}>
								<AccordionContentComponent {...accordionItems.chapters}/>
							</Accordion>

							<Separator/>

							<Tooltip
								hideOnMobile={true}
								content={<TooltipContent/>}
								direction={'right'}
								justify={'end'}
								show={showStep3}>
								<Accordion
									title={accordionItems.recognition.title}
									isChecked={props.isRecognitionCompleted}
									highlight={showStep3}
									highlightMobile={false}>
									<AccordionContentComponent {...accordionItems.recognition}/>
								</Accordion>
							</Tooltip>

						</AccordionContainer>

					</SideMenu>

					{props.supportMaterials && (
						<Tooltip
							hideOnMobile={true}
							content={<TooltipContent/>}
							direction={'right'}
							justify={'end'}
							show={showStep4}>
							<SupportMaterialsContainer
								highlight={showStep4}
								highlightMobile={false}>
								{props.supportMaterials.map((supportMaterial, index) => (
									<AnchorInput
										openInNewTab={true}
										key={index}
										appearance={'link-arrow'}
										href={supportMaterial.url}>
										{supportMaterial.text}
									</AnchorInput>
								))}
							</SupportMaterialsContainer>
						</Tooltip>
					)}
				</SideMenuContainer>
				<ChaptersSection>
					<TitleMainContainer>
						<TitleContainer>
							<ManualTitle>{props.name}</ManualTitle>
							<ProgressBar progress={progressPercentage}/>
						</TitleContainer>

						<MobileAvatarContainer>
							<AnimatedAvatar
								mute={!isMobile}
								autoPlay={!showTutorial && (props.playInitAvatar || props.playAvatar)}
								videoSrc={avatarContent.videoSrc}
								imageSrc={avatarContent.imageSrc}
							/>
						</MobileAvatarContainer>
					</TitleMainContainer>
					<ChaptersContainer>

						{props.chapters.map((chapter, index) => {
							if (index === 0) {
								return (
									<Tooltip
										hideOnMobile={true}
										content={<TooltipContent/>}
										direction="right"
										show={showStep2}
										key={index}>

										<ChapterCard
											highlightMobile={false}
											urlName={chapter.urlName}
											highlight={showStep2}
											imageSrc={chapter.imageUrl}
											title={chapter.name}
											summary={chapter.description}
											time={chapter.duration}
											isCompleted={chapter.completed}
											onClick={() => increaseLoadCountHandler(chapter.name)}
										/>
									</Tooltip>
								)
							}
							return (
								<ChapterCard
									key={index}
									urlName={chapter.urlName}
									imageSrc={chapter.imageUrl}
									title={chapter.name}
									summary={chapter.description}
									time={chapter.duration}
									isCompleted={chapter.completed}
									onClick={() => increaseLoadCountHandler(chapter.name)}
								/>
							)
						})}

					</ChaptersContainer>

					<RepeatButtonsContainer>
						<AnchorInput
							href={'#'}
							color={'white'}
							appearance={'link'}
							onClick={showIntroduction}>
							Repetir introducción
						</AnchorInput>

						<AnchorInput
							href={'#'}
							color={'white'}
							appearance={'link'}
							onClick={showTutorialHandler}>
							Ver tutorial
						</AnchorInput>
					</RepeatButtonsContainer>

				</ChaptersSection>
			</DashboardContainer>

			<SupportMaterialMobile>
				<SupportMaterialContainer>
					{props.supportMaterials?.map((supportMaterial, index) => (
						<AnchorInput
							key={index}
							appearance={'link-arrow'}
							href={supportMaterial.url}>
							{supportMaterial.text}
						</AnchorInput>
					))}
				</SupportMaterialContainer>
			</SupportMaterialMobile>

			<ModalOverlay show={showTutorial}>
				<OverlayBackground/>

				{(showTutorial && tutorialStep === 0) && (
					<ModalContainer ref={modalRef}>
						<TutorialWelcomeContainer>

							<AutoPlayVideo
								playVideo={!isTutorialCompleted}
								videoSrc={"https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/tutorial/tutorial-bienvenida.mp4"}
								imageSrc={"https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/avatar/tutorial/tutorial-bienvenida.gif"}
							/>

							<TutorialWelcomeText>{`Bienvenido al ${props.name} para OSC`}</TutorialWelcomeText>

							<Button
								size={'large'}
								onClick={nextStep}>
								Siguiente
							</Button>

						</TutorialWelcomeContainer>
					</ModalContainer>
				)}

				{(showTutorial && tutorialStep > 0 && tutorialStep < 4) && (
					<MobileFixedOptionsContainer>
						<TempBottomMenuContainer>
							<TooltipNoContainer
								content={<TooltipContent stretch={true}/>}
								direction={"top"}
								justify={justify}
								show={true}
							/>
							<BottomMenuContainer>
								<ButtonContainer isSelected={showStep1}>
									<BottomMenuButton>Autodiagnóstico</BottomMenuButton>
								</ButtonContainer>
								<ButtonContainer isSelected={showStep2}>
									<BottomMenuButton>Capítulos</BottomMenuButton>
								</ButtonContainer>
								<ButtonContainer isSelected={showStep3}>
									<BottomMenuButton>Reconocimiento y compromiso</BottomMenuButton>
								</ButtonContainer>
							</BottomMenuContainer>
						</TempBottomMenuContainer>

					</MobileFixedOptionsContainer>
				)}

				{(showTutorial && tutorialStep === 4 && props.supportMaterials) && (

					<SupportMaterialContainerStep>
						<TooltipNoContainer
							content={<TooltipContent stretch={true}/>}
							direction={"top"}
							justify={justify}
							show={true}
						/>
						<SupportMaterialContainer>
							{props.supportMaterials.map((supportMaterial, index) => (
								<AnchorInput
									key={index}
									appearance={'link-arrow'}
									href={supportMaterial.url}>
									{supportMaterial.text}
								</AnchorInput>
							))}
						</SupportMaterialContainer>
					</SupportMaterialContainerStep>
				)}
			</ModalOverlay>
		</>
	)
}

export default Dashboard;

export const SupportMaterialMobile = styled.div`
  display: none;

  ${media.max.mobile} {
    display: block;
  }
`;

const MobileAvatarContainer = styled.div`
  width: 100px;
  height: fit-content;
  border: 2px solid white;
  border-radius: 50%;
  margin-left: 8px;

  ${media.min.tablet} {
    display: none;
  }
`;

export const SupportMaterialContainer = styled.div`
  background-color: white;
  width: 100%;
  padding: 16px;
`;

export const SupportMaterialContainerStep = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 200;

  ${media.min.tablet} {
    display: none;
  }
`;

/* refactorizar */

export const DirectionButton = styled.button`
  font-weight: 500;
  font-size: 14.167px;
  line-height: 25px;
  color: ${colors.purple};
  border: none;
  background-color: unset;
  cursor: pointer;

  &:hover, &:focus {
    text-decoration: underline;
  }

  &:disabled {
    cursor: unset;
    color: ${colors.text2};
    text-decoration: unset;
  }
`;

const SupportMaterialsContainer = styled.div<{
	highlight?: boolean;
	highlightMobile?: boolean;
}>`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: relative;
  padding: 8px;
  margin: 0 16px;
  width: -webkit-fill-available;
  max-width: 250px;

  ${p => p.highlight && css`
    z-index: 200;
    background-color: ${colors.white};
    border-radius: 4px;
    pointer-events: none;
  `};

  ${p => !p.highlightMobile && css`
    ${media.max.mobile} {
      z-index: unset;
    }
  `};

  & > {
    :not(:last-child) {
      margin-bottom: 8px;
    }
  }
`;

export const SecondaryButton = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 25px;
  text-decoration: none;
  color: ${colors.purple};

  &:hover {
    text-decoration: underline;
  }
`;

export const TutorialWelcomeContainer = styled.div`
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 360px;
  border-radius: 6px;
  background-color: ${colors.white};

  ${media.max.mobile} {
    width: min-content;
  }
`;

export const TutorialWelcomeAvatarImage = styled.img`
  width: 228px;
  height: auto;
  margin-bottom: 16px;
  border-radius: 50%;
`;

export const TutorialWelcomeText = styled.p`
  font-weight: 600;
  font-size: 16.5px;
  line-height: 20px;
  color: ${colors.text};
  text-align: center;
  margin-bottom: 16px;
`;

export const GifContainer = styled.div`

`;

interface AutoPlayVideoProps {
	videoSrc: string;
	imageSrc: string;
	playVideo: boolean;
}

const AutoPlayVideo = ({videoSrc, imageSrc, playVideo}: AutoPlayVideoProps) => {
	const videoRef = useRef<HTMLVideoElement>(null);
	const [play, setPlay] = useState(playVideo);

	useEffect(() => {
		const videoElement = videoRef.current;
		if (videoElement) {
			videoElement.controls = false;
			const handleVideoEnded = () => setPlay(false);
			videoElement.addEventListener('ended', handleVideoEnded);

			return () => {
				videoElement.removeEventListener('ended', handleVideoEnded);
			}
		}
	}, []);

	useEffect(() => {
		const videoElement = videoRef.current;
		if (playVideo && videoElement) {
			setTimeout(() => {
				videoElement.play();
			}, 1000);
		}
	}, [playVideo]);

	return (
		<Container>
			<Video ref={videoRef} src={videoSrc} isShown={play} playsInline/>
			<AvatarImage src={imageSrc} isShown={!play}/>
		</Container>
	);
};

const Container = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  padding-top: 100%;
  overflow: hidden;
  margin-bottom: 16px;
`;

export const AvatarImage = styled.img<{ isShown: boolean }>`
  width: 100%;
  height: auto;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;

  opacity: ${props => (props.isShown ? 1 : 0)};
`;

const Video = styled.video<{ isShown: boolean }>`
  width: 100%;
  height: auto;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;

  opacity: ${props => (props.isShown ? 1 : 0)};
`;
