import styled from "styled-components";
import {BP_MAX_MD, BP_MAX_SM, media} from "../../../../styles/media/media";
import {getMaxWidthMedia} from "../../../../styles/functions/functions";
import {colors} from "../../../../styles/colors/colors";
import {H4_Style} from "../../../../styles/font/styles/font.styles";

const menuWidth = {
	desktop: 302,
	tablet: 238
}

/* media - fixed tablet */
const BP_FIXED_MAX_MD = BP_MAX_MD + menuWidth.desktop;
const fixedMaxWidthTablet = getMaxWidthMedia(BP_FIXED_MAX_MD);

/* media - fixed mobile */
const BP_FIXED_MAX_SM = BP_MAX_SM + menuWidth.tablet;
const fixedMaxWidthMobile = getMaxWidthMedia(BP_FIXED_MAX_SM);

export const DashboardContainer = styled.div`
  display: flex;
`;

export const SideMenuContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${fixedMaxWidthTablet} {
    width: ${menuWidth.tablet}px;
  }

  ${media.max.mobile} {
    display: none;
  }
`;

export const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${menuWidth.desktop}px;
  background: ${colors.white};
  padding: 16px 24px 8px 24px;
`;

export const ChaptersSection = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100vw - ${menuWidth.desktop}px);
  padding: 28px 40px 40px 24px;
  background: ${colors.gradient.default};

  ${fixedMaxWidthTablet} {
    width: calc(100vw - ${menuWidth.tablet}px);
    padding: 24px 20px 20px 24px;
  }

  ${media.max.mobile} {
    width: 100%;
    padding: 16px 16px 16px 16px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  flex: 1;

  ${fixedMaxWidthTablet} {
    display: block;
    margin-bottom: 16px;

    & > {
      &:first-child {
        margin-bottom: 8px;
      }
    }
  }

  ${media.max.mobile} {
    margin-bottom: 24px;
  }
`;

export const TitleMainContainer = styled.div`
  display: flex;
  
  ${media.max.mobile} {
	 margin-bottom: 12px;
  }
`;

export const ManualTitle = styled(H4_Style)`
  color: ${colors.white};
`;

export const ChaptersContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 8px 16px;

  ${fixedMaxWidthTablet} {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  ${fixedMaxWidthMobile} {
    grid-template-columns: 1fr;
  }
`;

export const RepeatIntroductionButton = styled.button`
  color: ${colors.white};
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  width: fit-content;
  margin-top: 24px;
  align-self: flex-end;
  text-decoration: unset;
  background-color: unset;
  border: unset;
  cursor: pointer;

  ${media.max.mobile} {
    margin-top: 28px;
    align-self: center;
  }

  &:hover {
    text-decoration: underline;
  }
`;

export const AccordionContainer = styled.div`
  background-color: ${colors.lightGray};
  border: 1px solid ${colors.gray};
  border-radius: 4px;
`;

export const AvatarContainer = styled.div`
  max-width: 230px;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 8px;

  ${fixedMaxWidthTablet} {
    max-width: 152px;
  }

  ${media.max.mobile} {
    display: none;
  }
`;

export const AvatarImage = styled.img`
  width: 100%;
  height: auto;
  border-radius: 50%;
`;

export const AvatarBackgroundImage = styled.div<{
	image: string;
}>`
  padding-top: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: ${(p) => p.image && `url(${p.image})`};
`;

export const TutorialButton = styled.button`
  height: auto;
  border-radius: 100px;
  border: none;
  color: white;
  font-size: getRem(16);
  box-shadow: none;
  transform: scale(1);
  width: fit-content;

  position: relative;
  z-index: 1;
  background: ${colors.gradient.purple};

  cursor: pointer;

  padding: 16px 74px;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${colors.gradient.darkPurple};
    opacity: 0;
    transition: opacity 0.4s;
    z-index: -1;
    border-radius: 100px;
    border: none;
  }

  :hover:before {
    opacity: 1;
  }
`;

export const RepeatButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  margin-top: 20px;
`;
