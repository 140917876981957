export const colors = {
	transparent: '#00000000',
	white: '#FFFFFF',
	black: '#000000',
	text: '#363636',
	text2: '#8D99A2',

	gray: '#EAEAEA',
	lightGray: '#FBFBFB',

	success: '#7DD86F',
	lightSuccess: '#7DD86F1A',

	error: '#E80505',
	lightError: '#E805051A',

	purple: '#534D8D',
	lightPurple: '#534D8D1A',
	darkPurple: '#3C2362',


	gradient: {
		default: `linear-gradient(63.87deg, #93636F 0.87%, #76698B 32.36%, #354C6C 67.12%, #003C57 99.14%)`,
		green: `linear-gradient(130.43deg, #69B9A7 12.77%, #41677C 47.21%, #1A1B52 84.65%)`,
		brown: `linear-gradient(130.43deg, #B87536 12.77%, #9B4D39 47.21%, #7C233B 84.65%)`,
		purple: `linear-gradient(130.43deg, #585F9B 12.77%, #534D8D 48.71%, #4F2E80 84.65%)`,
		darkPurple: `linear-gradient(130.43deg, #3C2362 12.77%, #3C2362 48.71%, #3C2362 84.65%)`,
		orange: `linear-gradient(135deg, #FFAA85 0%, #B3315F 100%);`
	},

	background: {
		gray: '#F5F5FA'
	}
};