import styled, {css} from 'styled-components';
import {colors} from "../../styles/colors/colors";
import {getRem} from "../../styles/functions/functions";
import * as React from 'react';
import {H4_Style} from "../../styles/font/styles/font.styles";
import {media} from "../../styles/media/media";

export const HeaderComponent = styled.header`
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.gray};
`;

export const HeaderComponent_LogoLink = styled.a``;

export const HeaderComponent_HamburgerButton = styled.div`
  display: none;
  align-items: center;
  cursor: pointer;

  ${media.max.mobile} {
    display: flex;
  }
`;

export const HeaderComponent_Items_Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderComponent_Audio = styled.div`
  margin-right: 14px;
`;

export const HeaderComponent_Items = styled.div`
  display: flex;
  align-items: center;

  ${media.max.mobile} {
    display: none;
  }
`;

export const HeaderComponent_UserName = styled.span`
  color: ${colors.text};
  font-size: ${getRem(14)};
  font-weight: 600;
  margin-right: 24px;
`;

export const HeaderComponent_LogoutButton = styled.a`
  color: ${colors.text2};
  font-size: ${getRem(14)};
  font-weight: 600;
  cursor: pointer;
`;

export const DLAPiper_Image = styled.img`
  width: 84px;
  height: auto;
`;

export const HeaderComponent_Overlay = styled.div<MobileNavProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${colors.gradient.purple};
  z-index: -1;
  opacity: 0;
  transition: opacity 0.3s;

  ${p => p.show && css`
    opacity: 0.8;
    z-index: 10;
  `}
`;

interface MobileNavProps {
	show: boolean;
}

export const HeaderComponent_Menu = styled.div<MobileNavProps>`
  position: fixed;
  top: 0;
  right: ${p => (p.show ? '0' : '-100%')};
  background-color: ${colors.white};
  transition: right 0.3s ease-in-out;
  z-index: 20;
  padding: 40px 40px 140px 40px;
  max-height: 100vh;
  overflow-y: auto;
  width: calc(100vw - 34px);
`;

export const HeaderComponent_Link_Logo_Image = styled.img`
  width: 126px;
  height: auto;
`;

export const HeaderComponent_Menu_CloseButton = styled.div`
  display: flex;
  justify-content: right;
`;

export const HeaderComponent_Menu_Content = styled.div`
  padding: 24px 0;
  border-bottom: 1px solid ${colors.gray}
`;

export const HeaderComponent_Menu_Content_Title = styled(H4_Style)`

`;

export const HeaderComponent_Menu_Content_Items = styled.div`
  margin-top: 24px;

  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const HeaderComponent_Menu_Content_Item = styled.a`
  display: block;
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;

  :hover {
    text-decoration: underline;
  }
`;

export const HeaderComponent_ContentContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 40px;
  
  ${media.max.mobile} {
	 padding: 16px;
  }
`;
