import React, {useEffect, useRef, useState} from 'react';
import {TDirection, TJustify} from "./tooltip.int";
import {TooltipContainer, TooltipContentContainer, Triangle} from "./tooltip.styles";

interface TooltipProps extends React.PropsWithChildren<{}> {
	direction?: TDirection;
	show: boolean;
	content: React.ReactNode;
	justify?: TJustify;
	hideOnMobile?: boolean;
}

const getRootSize = () => {
	const rootElement = document.getElementById('root');
	let width = 0;
	let height = 0;

	if (rootElement) {
		width = rootElement.clientWidth;
		height = rootElement.clientHeight;
	}

	return {width, height}
}

const Tooltip = ({
						  justify: initialJustify = 'start',
						  direction: initialDirection = 'bottom',
						  children,
						  ...props
					  }: TooltipProps) => {
	const [alignment, setAlignment] = useState<TJustify>(initialJustify);
	const [direction, setDirection] = useState<TDirection>(initialDirection);
	const stateRef = useRef<DOMRect | null>(null);
	const tooltipRef = useRef<HTMLDivElement | null>(null);

	useEffect(() => {
		const handleResize = () => {
			const windowSize = getRootSize();
			const currentRef = tooltipRef.current;

			if (props.show && currentRef) {
				const rect = currentRef.getBoundingClientRect();
				const prevRef = stateRef.current;
				const {top, right, bottom, left} = rect;

				switch (initialDirection) {
					case 'top':

						if (top < 0) {
							setDirection('bottom');
							setAlignment('start');
							stateRef.current = rect;
						} else if (initialDirection !== direction && prevRef && prevRef.top < 0) {
							setDirection(initialDirection);
							setAlignment(initialJustify);
							stateRef.current = rect;
						}

						break;
					case 'right':

						if (right > windowSize.width) {
							setDirection('bottom');
							setAlignment('start');
							stateRef.current = rect;
						} else if (initialDirection !== direction && prevRef && prevRef.right < windowSize.width) {
							setDirection(initialDirection);
							setAlignment(initialJustify);
							stateRef.current = rect;
						}

						break;
					case 'bottom':

						if (bottom > windowSize.height) {
							setDirection('top');
							setAlignment('start');
							stateRef.current = rect;
						} else if (initialDirection !== direction && prevRef && prevRef.bottom < windowSize.height) {
							setDirection(initialDirection);
							setAlignment(initialJustify);
							stateRef.current = rect;
						}

						break;
					case 'left':
						if (left < 0) {
							setDirection('bottom');
							setAlignment('start');
							stateRef.current = rect;
						} else if (initialDirection !== direction && prevRef && prevRef.left < 0) {
							setDirection(initialDirection);
							setAlignment(initialJustify);
							stateRef.current = rect;
						}
						break;
				}
			}
		}
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [props.show, direction]);


	return (
		<TooltipContainer direction={direction}>
			{children}
			<TooltipContentContainer
				hideOnMobile={props.hideOnMobile}
				justify={alignment}
				direction={direction}
				show={props.show}
				ref={tooltipRef}>
				<Triangle
					justify={alignment}
					direction={direction}/>
				{props.content}
			</TooltipContentContainer>
		</TooltipContainer>
	);
};

export default Tooltip;
