import styled, {css, keyframes} from "styled-components";
import {colors} from "../../styles/colors/colors";
import {Body_Style} from "../../styles/font/styles/font.styles";
import {media} from "../../styles/media/media";

const progressAnimation = (width: number) => keyframes`
  from {
    width: 0;
  }
  to {
    width: ${width}%;
  }
`;

export const ProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BarContainer = styled.div`
  width: 200px;
  height: fit-content;
  background-color: ${colors.white};
  border-radius: 10px;
  margin-right: 8px;

  ${media.max.mobile} {
    width: 180px;
  }
`;

export const BarProgress = styled.div<{ width: number }>`
  width: ${p => p.width}%;
  height: 14px;
  background-color: ${colors.success};
  border-radius: 10px;
  animation: ${p => p.width && css`${progressAnimation(p.width)} 0.75s ease-in forwards`};
`;

export const ProgressText = styled(Body_Style)`
  color: ${colors.white};
`;
