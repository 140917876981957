import styled from "styled-components";
import {colors} from "../../styles/colors/colors";

const Privacy = () => {
	return (
		<InnerContainer>
			<ContentContainer>
				<Title>Aviso de privacidad</Title>

				<AllSectionContainer>

					<SectionContainer>

						<SubsectionsContainer>

							<SubSectionContainer>
								<SectionSubtitle>
									2. Obtención de Datos Personales.
								</SectionSubtitle>
								<TextBlockContainer>
									<TextBlock>
										Para dar cumplimiento a lo dispuesto en la Ley y para las finalidades previstas en el
										presente Aviso de Privacidad, le informamos que podemos recabar sus Datos Personales de la
										siguiente manera:
									</TextBlock>
									<List>
										<ListItem>
											<BulletBlackSpan>I. Beneficiarios</BulletBlackSpan>
											<List>
												<ListItem>
													<NormalBlackSpan>a. Directamente del Titular: mediante trato directo con
														Appleseed.</NormalBlackSpan>
												</ListItem>
											</List>
										</ListItem>
									</List>
								</TextBlockContainer>
							</SubSectionContainer>

						</SubsectionsContainer>


					</SectionContainer>


				</AllSectionContainer>
			</ContentContainer>
		</InnerContainer>
	)
}

export default Privacy;

const GeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const InnerContainer = styled.div`
  flex-grow: 1;
  background-color: ${colors.lightGray};
`;

const Title = styled.h1`
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 28px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  align-self: center;
`;

const SectionSubtitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  align-self: start;
`;

const TextBlock = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 24px;
  max-width: 720px;
  margin: 0 auto;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Link = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

const AllSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const SubSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubsectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const List = styled.ul`
  padding-left: 20px;

  & > li {
    list-style-type: none;
    position: relative;
    padding-left: 15px;

    ::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      transform: translateY(6px);
      height: 6px;
      width: 6px;
      border-radius: 50%;
      background: black;
    }
  }

  & ul {
    list-style-type: upper-alpha;
  }

  & ul > li {
    position: initial;
    padding-left: 0;

    ::before {
      content: none;
    }
  }
`;

const ListItem = styled.li`
  font-size: 12px;
  margin-bottom: 14px;
  line-height: 18px;
`;

const BulletBlackSpan = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

const NormalBlackSpan = styled.span`
  font-size: 12px;
  font-weight: 400;
`;

const BulletLink = styled.a`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;