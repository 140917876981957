import React, {useContext} from "react";
import DefaultTemplate from "../templates/default/defaultTemplate";

import bannerDesktop from '../../assets/files/complianceMap/banner/0-D.png';
import bannerTablet from '../../assets/files/complianceMap/banner/0-T.png';
import bannerMobile from '../../assets/files/complianceMap/banner/0-M.png';

import chapter1Desktop from '../../assets/files/complianceMap/chapter1/1-D.png';
import chapter1Tablet from '../../assets/files/complianceMap/chapter1/1-T.png';
import chapter1Mobile from '../../assets/files/complianceMap/chapter1/1-M.png';

import chapter2Desktop from '../../assets/files/complianceMap/chapter2/2-D.png';
import chapter2Tablet from '../../assets/files/complianceMap/chapter2/2-T.png';
import chapter2Mobile from '../../assets/files/complianceMap/chapter2/2-M.png';

import chapter3Desktop from '../../assets/files/complianceMap/chapter3/3-D.png';
import chapter3Tablet from '../../assets/files/complianceMap/chapter3/3-T.png';
import chapter3Mobile from '../../assets/files/complianceMap/chapter3/3-M.png';

import chapter4Desktop from '../../assets/files/complianceMap/chapter4/4-D.png';
import chapter4Tablet from '../../assets/files/complianceMap/chapter4/4-T.png';
import chapter4Mobile from '../../assets/files/complianceMap/chapter4/4-M.png';

import chapter5Desktop from '../../assets/files/complianceMap/chapter5/5-D.png';
import chapter5Tablet from '../../assets/files/complianceMap/chapter5/5-T.png';
import chapter5Mobile from '../../assets/files/complianceMap/chapter5/5-M.png';

import chapter6Desktop from '../../assets/files/complianceMap/chapter6/6-D.png';
import chapter6Tablet from '../../assets/files/complianceMap/chapter6/6-T.png';
import chapter6Mobile from '../../assets/files/complianceMap/chapter6/6-M.png';

import chapter7Desktop from '../../assets/files/complianceMap/chapter7/7-D.png';
import chapter7Tablet from '../../assets/files/complianceMap/chapter7/7-T.png';
import chapter7Mobile from '../../assets/files/complianceMap/chapter7/7-M.png';

import chapter8Desktop from '../../assets/files/complianceMap/chapter8/8-D.png';
import chapter8Tablet from '../../assets/files/complianceMap/chapter8/8-T.png';
import chapter8Mobile from '../../assets/files/complianceMap/chapter8/8-M.png';

import chapter9Desktop from '../../assets/files/complianceMap/chapter9/9-D.png';
import chapter9Tablet from '../../assets/files/complianceMap/chapter9/9-T.png';
import chapter9Mobile from '../../assets/files/complianceMap/chapter9/9-M.png';

import chapter10Desktop from '../../assets/files/complianceMap/chapter10/10-D.png';
import chapter10Tablet from '../../assets/files/complianceMap/chapter10/10-T.png';
import chapter10Mobile from '../../assets/files/complianceMap/chapter10/10-M.png';

import chapter11Desktop from '../../assets/files/complianceMap/chapter11/11-D.png';
import chapter11Tablet from '../../assets/files/complianceMap/chapter11/11-T.png';
import chapter11Mobile from '../../assets/files/complianceMap/chapter11/11-M.png';

import chapter12Desktop from '../../assets/files/complianceMap/chapter12/12-D.png';
import chapter12Tablet from '../../assets/files/complianceMap/chapter12/12-T.png';
import chapter12Mobile from '../../assets/files/complianceMap/chapter12/12-M.png';

import styled from "styled-components";
import {BP_MAX_MD, BP_MIN_MD, media} from "../../styles/media/media";
import Button from "../../components/inputs/button/button";
import {colors} from "../../styles/colors/colors";
import Breadcrumb, {ITab} from "../../componentsBackup/breadcrumb/breadcrumb";
import {useParams} from "react-router-dom";
import APIAnalytics from "../../api/manual/analytics/analytics";
import {LoaderContext} from "../../componentsBackup/loader/loader";
import {ILoaderContext} from "../../componentsBackup/loader/loader.int";

interface IImage {
	href: string;
	desktop: string;
	tablet: string;
	mobile: string;
}

const Image = (props: IImage) => {
	return (
		<ImageAnchor href={props.href} target={'_blank'}>
			<ImageElementDesktop src={props.desktop} alt={"image_desktop"}/>
			<ImageElementTablet src={props.tablet} alt={"image_tablet"}/>
			<ImageElementMobile src={props.mobile} alt={"image_mobile"}/>
		</ImageAnchor>
	)
}

const ComplianceMapPage = () => {
	const {manualUrlName} = useParams<{ manualUrlName?: string }>();
	const context = useContext(LoaderContext) as ILoaderContext;

	const redirectToComplianceMapPDF = () => {
		window.open("https://mosc-mx.s3.us-east-2.amazonaws.com/manuals/files/mapa-de-cumplimiento.pdf", "_blank");

		if (manualUrlName) {
			APIAnalytics(context).downloadComplianceMap(manualUrlName);
		}
	}

	const tabs: ITab[] = [
		{
			name: "Reconocimiento y compromiso",
			href: `/manuales/${manualUrlName}/reconocimiento-y-compromiso`
		},
		{
			name: "Mapa de cumplimiento",
			href: `#`
		},
	]

	return (
		<>
			<Breadcrumb tabs={tabs} />
			<ImageElementDesktop src={bannerDesktop} alt={"image_desktop"}/>
			<ImageElementTablet src={bannerTablet} alt={"image_tablet"}/>
			<ImageElementMobile src={bannerMobile} alt={"image_mobile"}/>
			<Image href={"/manuales/manual-didactico-de-anticorrupcion/capitulos/evaluacion-interna-de-riesgos"} desktop={chapter1Desktop} tablet={chapter1Tablet} mobile={chapter1Mobile}/>
			<Image href={"/manuales/manual-didactico-de-anticorrupcion/capitulos/codigo-de-etica-y-conducta"} desktop={chapter2Desktop} tablet={chapter2Tablet} mobile={chapter2Mobile}/>
			<Image href={"/manuales/manual-didactico-de-anticorrupcion/capitulos/manual-interno-de-anticorrupcion"} desktop={chapter3Desktop} tablet={chapter3Tablet} mobile={chapter3Mobile}/>
			<Image href={"/manuales/manual-didactico-de-anticorrupcion/capitulos/conflictos-de-interes"} desktop={chapter4Desktop} tablet={chapter4Tablet} mobile={chapter4Mobile}/>
			<Image href={"/manuales/manual-didactico-de-anticorrupcion/capitulos/encargados-de-cumplimiento"} desktop={chapter5Desktop} tablet={chapter5Tablet} mobile={chapter5Mobile}/>
			<Image href={"/manuales/manual-didactico-de-anticorrupcion/capitulos/capacitacion-interna"} desktop={chapter6Desktop} tablet={chapter6Tablet} mobile={chapter6Mobile}/>
			<Image href={"/manuales/manual-didactico-de-anticorrupcion/capitulos/mecanismos-de-denuncia-y-proteccion-de-victimas"} desktop={chapter7Desktop} tablet={chapter7Tablet} mobile={chapter7Mobile}/>
			<Image href={"/manuales/manual-didactico-de-anticorrupcion/capitulos/difusion-evaluacion-y-monitoreo"} desktop={chapter8Desktop} tablet={chapter8Tablet} mobile={chapter8Mobile}/>
			<Image href={"/manuales/manual-didactico-de-anticorrupcion/capitulos/transparencia-y-politicas-de-reclutamiento"} desktop={chapter9Desktop} tablet={chapter9Tablet} mobile={chapter9Mobile}/>
			<Image href={"/manuales/manual-didactico-de-anticorrupcion/capitulos/ley-anticorrupcion"} desktop={chapter10Desktop} tablet={chapter10Tablet} mobile={chapter10Mobile}/>
			<Image href={"/manuales/manual-didactico-de-anticorrupcion/capitulos/relaciones-con-terceros"} desktop={chapter11Desktop} tablet={chapter11Tablet} mobile={chapter11Mobile}/>
			<Image href={"/manuales/manual-didactico-de-anticorrupcion/capitulos/codigo-penal"} desktop={chapter12Desktop} tablet={chapter12Tablet} mobile={chapter12Mobile}/>

			<ButtonContainer>
				<Button
					size={"large"}
					onClick={redirectToComplianceMapPDF}>
					Descargar PDF
				</Button>
			</ButtonContainer>
		</>
	);
};

export default ComplianceMapPage;

const ImageAnchor = styled.a`
  &:hover {
    background: ${colors.gray};
    transition: background 1s ease;
  }
`;

const ImageElementDesktop = styled.img`
  width: 100%;
  height: auto;
  display: none;
  background: inherit;

  ${media.min.desktop} {
    display: block;
  }
`;

const ImageElementTablet = styled.img`
  width: 100%;
  height: auto;
  display: none;
  background: inherit;

  @media screen and (min-width: ${BP_MIN_MD}px) and (max-width: ${BP_MAX_MD}px) {
    display: block;
  }
`;

const ImageElementMobile = styled.img`
  width: 100%;
  height: auto;
  display: none;
  background: inherit;

  ${media.max.mobile} {
    display: block;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px 0 80px 0;

  ${media.max.tablet} {
    padding: 46px 0 156px 0;
  }

  ${media.max.mobile} {
    padding-top: 32px;
  }
`;
