import styled from "styled-components";
import {colors} from "../../styles/colors/colors";

const TermsAndConditions = () => {
	return (
		<InnerContainer>
			<ContentContainer>
				<Title>Términos y Condiciones</Title>

				<AllSectionContainer>

					<SectionContainer>

						<SectionTitle>
							I. Condiciones Generales.
						</SectionTitle>

						<SubsectionsContainer>
							<SubSectionContainer>
								<SectionSubtitle>
									1. Objeto.
								</SectionSubtitle>
								<TextBlockContainer>
									<TextBlock>
										Los presentes términos y condiciones generales de uso (en adelante las
										“Condiciones”) tienen
										por objeto regular el acceso y uso que hagan aquellas personas físicas o morales
										identificadas como usuarios del sitio web <span><Link
										href={"https://appleseedmexico.org"}
										target={"_blank"}>https://appleseedmexico.org</Link></span> o cualquiera
										de sus
										extensiones tales como [redprobonomexico.org] (en adelante el “Sitio Web”) el cual
										tiene el
										uso exclusivo La Fundación Appleseed México, A.C., (“Appleseed”).
									</TextBlock>
								</TextBlockContainer>
							</SubSectionContainer>

							<SubSectionContainer>
								<SectionSubtitle>
									2. Definiciones.
								</SectionSubtitle>
								<TextBlockContainer>
									<TextBlock>
										Para efectos de claridad de las presentes Condiciones se establecen las definiciones
										respecto de las palabras que se enlistan a continuación y cómo deberán de
										entenderse, teniendo el mismo significado tanto si se usa en singular como en
										plural:
									</TextBlock>
									<List>
										<ListItem>
											<BulletBlackSpan>Appleseed</BulletBlackSpan>: Significa La Fundación Appleseed
											México, A.C., en su carácter de propietaria del Sitio Web.
										</ListItem>
										<ListItem>
											<BulletBlackSpan>Condiciones</BulletBlackSpan>: Significa los presentes términos
											y condiciones generales de uso.
										</ListItem>
										<ListItem>
											<BulletBlackSpan>Cuenta</BulletBlackSpan>: Significa el medio de
											identificación del Usuario que podrán crear en Appleseed aquellas personas con
											capacidad suficiente, incluyendo aquellas en representación de personas morales
											debidamente constituidas y representadas, que tengan la intención de hacer uso de
											las herramientas que el Sitio web ofrece bajo estas Condiciones.
										</ListItem>
										<ListItem>
											<BulletBlackSpan>Enlaces</BulletBlackSpan>: Significa cualesquier otro dominio o
											hipervínculo a los que se puede acceder de cualquier manera mediante el Sitio
											Web.
										</ListItem>
										<ListItem>
											<BulletBlackSpan>Funcionalidades</BulletBlackSpan>: Significan todas las
											herramientas, funcionalidades y asistencia que se describen en el punto IV de las
											presentes Condiciones.
										</ListItem>
										<ListItem>
											<BulletBlackSpan>México</BulletBlackSpan>: Significa Estados Unidos Mexicanos.
										</ListItem>
										<ListItem>
											<BulletBlackSpan>Recursos Digitales</BulletBlackSpan>: significan aquellas
											herramientas, textos, publicaciones, cursos, seminarios (en línea o
											presenciales), descritas en el punto II de las presentes Condiciones.
										</ListItem>
										<ListItem>
											<BulletBlackSpan>Registro</BulletBlackSpan>: Significa la permanencia en la base
											de datos de la Cuenta en el Sitio Web.
										</ListItem>
										<ListItem>
											<BulletBlackSpan>Sitio Web</BulletBlackSpan>: Dirección de
											Internet <span><BulletLink
											href={"https://appleseedmexico.org"}
											target={"_blank"}>https://appleseedmexico.org</BulletLink></span>,
											redprobonomexico.org de la cual es titular
											Appleseed.
										</ListItem>
										<ListItem>
											<BulletBlackSpan>Tercero</BulletBlackSpan>: Significa cualquier persona que no
											sea Appleseed, incluyendo sus directivos, trabajadores, representantes o
											dependiente de alguna forma de Appleseed ni el Usuario.
										</ListItem>
										<ListItem>
											<BulletBlackSpan>Usuario</BulletBlackSpan>: Significa toda persona, ya sea física
											o moral, que esté interesada en hacer uso de las Funcionalidades y de los
											Recursos Digitales contenidas en el Sitio Web, ya sea colaborando al prestar
											asesoría legal o al hacer uso de las Funcionalidades y de los Recursos Digitales.
										</ListItem>
									</List>
								</TextBlockContainer>
							</SubSectionContainer>

							<SubSectionContainer>
								<SectionSubtitle>
									3. Aceptación.
								</SectionSubtitle>
								<TextBlockContainer>
									<TextBlock>
										La sola utilización del Sitio Web representa la aceptación, plena e incondicional,
										de los Usuarios de todas y cada una de las presentes Condiciones desde el momento
										mismo en que el Usuario acceda, se registre y haga uso del Sitio Web.
									</TextBlock>
								</TextBlockContainer>
							</SubSectionContainer>

							<SubSectionContainer>
								<SectionSubtitle>
									4. Licitud y Propiedad Intelectual.
								</SectionSubtitle>
								<TextBlockContainer>
									<TextBlock>
										El Sitio Web y la Cuenta serán utilizados únicamente para fines lícitos, de
										conformidad y en cumplimiento con la legislación vigente y aplicable en México,
										misma que puede ser modificada de tiempo en tiempo. El contenido, información y/o
										material que Appleseed pone al alcance de los Usuarios a través de su Sitio Web
										contiene elementos amparados por normas de propiedad intelectual, incluyendo
										protección del software, fotografías, logos, diseños, imágenes gráficas, música y
										sonido, sin que esta descripción implique limitación de naturaleza alguna.
									</TextBlock>
									<TextBlock>
										Cualquier trasgresión a estas Condiciones por parte de un Usuario (incluyendo, pero
										no limitándose a la comisión de actos que, a criterio exclusivo de Appleseed,
										constituyan conductas abusivas, inapropiadas o ilícitas) generarán el derecho, en
										favor de Appleseed, en cualquier momento y sin necesidad de notificación previa de
										ningún tipo, para suspender o terminar el uso de las Funcionalidades, de los
										Recursos Digitales y/o de retirarle o negarle el acceso al Sitio Web y/o a su Cuenta
										a cualquier Usuario.
									</TextBlock>
								</TextBlockContainer>
							</SubSectionContainer>

						</SubsectionsContainer>


					</SectionContainer>


				</AllSectionContainer>
			</ContentContainer>
		</InnerContainer>
	)
}

export default TermsAndConditions;

const GeneralContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

const InnerContainer = styled.div`
  flex-grow: 1;
  background-color: ${colors.lightGray};
`;

const Title = styled.h1`
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 28px;
`;

const SectionTitle = styled.h2`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;
  align-self: center;
`;

const SectionSubtitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 12px;
  align-self: start;
`;

const TextBlock = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: justify;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 60px 24px;
  max-width: 720px;
  margin: 0 auto;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TextBlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Link = styled.a`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
`;

const AllSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
`;

const SubSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SubsectionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const List = styled.ul`
  list-style-type: none;
  padding-left: 20px;
`;

const ListItem = styled.li`
  font-size: 12px;
  margin-bottom: 14px;
  position: relative;
  padding-left: 15px;
  line-height: 18px;

  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(6px);
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: black;
  }
`;

const BulletBlackSpan = styled.span`
  font-size: 12px;
  font-weight: 600;
`;

const BulletLink = styled.a`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
`;