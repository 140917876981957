import {useParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {ManualDashboardDTO} from "../../api/manual/dashboard/manualDashboard.int";
import {APIManual} from "../../api/manual/dashboard/manualDashboard";
import {Introduction} from "./sections/introduction/introduction";
import Dashboard from "./sections/dashboard/dashboard";
import {AvatarMediaContext} from "../../context/globalContainer/avatarMediaContext";
import {IAvatarMediaContext} from "../../context/globalContainer/globalContainerContext.int";

const ManualPage = () => {
	const {manualUrlName} = useParams<{ manualUrlName?: string }>();
	const [manual, setManual] = useState<ManualDashboardDTO>();
	const [isIntroductionCompleted, setIsIntroductionCompleted] = useState<boolean>(false);
	const [showIntroduction, setShowIntroduction] = useState<boolean>(true);
	const {getDashboard, completeIntroduction} = APIManual();
	const context = useContext(AvatarMediaContext) as IAvatarMediaContext;

	const completeIntroductionHandler = async () => {
		if (manualUrlName && manual && !manual.isIntroductionCompleted) {
			await completeIntroduction(manualUrlName);
		}
	}

	const fetchDashboard = async () => {
		if (!manualUrlName) return;
		const manualDashboard = await getDashboard(manualUrlName);

		if (manualDashboard) {
			setManual(manualDashboard);
			setIsIntroductionCompleted(manualDashboard.isIntroductionCompleted);
			setShowIntroduction(!manualDashboard.isIntroductionCompleted);
		}
	};

	useEffect(() => {
		fetchDashboard();
		context.showMediaControllerHandler();
	}, []);

	return (
		<>
			{(manual && showIntroduction) && (
				<Introduction
					{...manual.introduction}
					completeIntroductionHandler={completeIntroductionHandler}
					isIntroductionCompleted={isIntroductionCompleted}
					setIsIntroductionCompleted={setIsIntroductionCompleted}
					setShowIntroduction={setShowIntroduction}
				/>
			)}

			{(manual && !showIntroduction) && (
				<Dashboard
					{...manual}
					manualUrlName={manualUrlName}
					setShowIntroduction={setShowIntroduction}
				/>
			)}
		</>
	)
}

export default ManualPage;
