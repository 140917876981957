import {ITextElementProps} from "./interfaces/font.int";
import React from "react";
import {Body_Style, H1_Style, H2_Style, H3_Style, H4_Style, Tag_Style} from "./styles/font.styles";

export const H1 = (props: ITextElementProps) => (
	<H1_Style style={props.style}>
		{props.children}
	</H1_Style>
);

export const H2 = (props: ITextElementProps) => (
	<H2_Style style={props.style}>
		{props.children}
	</H2_Style>
);

export const H3 = (props: ITextElementProps) => (
	<H3_Style style={props.style}>
		{props.children}
	</H3_Style>
);

export const H4 = (props: ITextElementProps) => (
	<H4_Style style={props.style}>
		{props.children}
	</H4_Style>
);

export const Body = (props: ITextElementProps) => (
	<Body_Style style={props.style}>
		{props.children}
	</Body_Style>
);

export const Tag = (props: ITextElementProps) => (
	<Tag_Style style={props.style}>
		{props.children}
	</Tag_Style>
);
