import {css} from "styled-components";
import {getRem} from "../../functions/functions";
import {media} from "../../media/media";

export const h1FontMixin = {
	desktop: css`
     font-weight: 600;
     font-size: ${getRem(50)};
     line-height: ${getRem(61)};
	`,
	tablet: css`
     ${media.max.tablet} {
       font-size: ${getRem(50)};
       line-height: ${getRem(61)};
     }
	`,
	mobile: css`
     ${media.max.mobile} {
       font-size: ${getRem(28)};
       line-height: ${getRem(34)};
     }
	`,
};

export const h2FontMixin = {
	desktop: css`
     font-weight: 600;
     font-size: ${getRem(32)};
     line-height: ${getRem(39)};
	`,
	tablet: css`
     ${media.max.tablet} {
       font-size: ${getRem(32)};
       line-height: ${getRem(39)};
     }
	`,
	mobile: css`
     ${media.max.mobile} {
       font-weight: 400;
       font-size: ${getRem(18)};
       line-height: ${getRem(27)};
     }
	`,
};

export const h3FontMixin = {
	desktop: css`
     font-weight: 400;
     font-size: ${getRem(25)};
     line-height: ${getRem(30)};
	`,
	tablet: css`
     ${media.max.tablet} {
       font-size: ${getRem(25)};
       line-height: ${getRem(30)};
     }
	`,
	mobile: css`
     ${media.max.mobile} {
       font-weight: 400;
       font-size: ${getRem(16)};
       line-height: ${getRem(19)};
     }
	`,
};

export const h4FontMixin = {
	desktop: css`
     font-weight: 600;
     font-size: ${getRem(18)};
     line-height: ${getRem(21)};
	`,
	tablet: css`
     ${media.max.tablet} {
       font-size: ${getRem(18)};
       line-height: ${getRem(21)};
     }
	`,
	mobile: css`
     ${media.max.mobile} {
       font-size: ${getRem(18)};
       line-height: ${getRem(21)};
     }
	`,
};

export const bodyFontMixin = {
	desktop: css`
     font-weight: 400;
     font-size: ${getRem(14)};
     line-height: ${getRem(20)};
	`,
	tablet: css`
     ${media.max.tablet} {
       font-size: ${getRem(14)};
       line-height: ${getRem(20)};
     }
	`,
	mobile: css`
     ${media.max.mobile} {
       font-size: ${getRem(14)};
       line-height: ${getRem(20)};
     }
	`,
};

export const tagFontMixin = {
	desktop: css`
     font-weight: 600;
     font-size: ${getRem(12)};
     line-height: ${getRem(15)};
	`,
	tablet: css`
     ${media.max.tablet} {
       font-size: ${getRem(12)};
       line-height: ${getRem(15)};
     }
	`,
	mobile: css`
     ${media.max.mobile} {
       font-size: ${getRem(12)};
       line-height: ${getRem(15)};
     }
	`,
};
